
import loadable from "@loadable/component";
import PersonalDetailsStep from "./components/personal-details-step/personalDetailsRouteLazyLoader";

const SelectDayStep = loadable(() => import("./components/select-day-step"));
// const PersonalDetailsStep = loadable(() => import("./components/personal-details-step"));
const FinanceDetailsStep = loadable(() => import("./components/finance-details-step"));
const BookingConfirmed = loadable(() => import("./components/booking-confirmed"));
const PreliminaryScreen = loadable(() => import("./components/preliminary-screen"));

const bookInspectionSteps = {
    personalDetails: {
        key: "personalDetails",
        stepNo: 1,
        name: "Personal details",
        route: "personal-details",
        defaultBack: "",
        defaultNext: "finance",
        component: PersonalDetailsStep,
        isDisabled: false,
        eventName: "Basic Details",
        type: "test-drive"
    },
    finance: {
        key: "finance",
        stepNo: 2,
        name: "Finance",
        route: "finance",
        defaultBack: "personal-details",
        defaultNext: "select-day",
        component: FinanceDetailsStep,
        isDisabled: false,
        eventName: "finance",
        type: "test-drive"
    },
    selectDay: {
        key: "selectDay",
        stepNo: 3,
        name: "Book test drive",
        route: "select-day",
        defaultNext: "confirmation",
        defaultBack: "finance",
        component: SelectDayStep,
        isDisabled: false,
        eventName: "book slot",
        type: "test-drive"
    },
    bookingConfirmed: {
        key: "bookingConfirmed",
        stepNo: 4,
        name: "Booking confirmed",
        route: "confirmation",
        component: BookingConfirmed,
        isDisabled: true,
        eventName: "booking confirmation",
        type: "test-drive"
    },
    preliminary: {
        key: "preliminary",
        stepNo: 0,
        name: "Priminary",
        route: "preliminary",
        defaultNext: "personal-details",
        defaultBack: "",
        component: PreliminaryScreen,
        isDisabled: false,
        eventName: "preliminary",
        type: "test-drive"
    }
};

const bookInspectionStepsArray = Object.values(bookInspectionSteps);
const totalSteps = bookInspectionStepsArray.length;

export {
    bookInspectionSteps,
    bookInspectionStepsArray,
    totalSteps
};
