import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_ALERT_FILTERS
    FETCH_ALERT_FILTERS_SUCCESS
    FETCH_ALERT_FILTERS_FAILURE
    UPDATE_SSR_STATUS

    UPDATE_ALERT_FILTER_TIMESTAMP

    UPDATE_APPLIED_ALERT_FILTERS
    UPDATE_SELECTED_ON_SCREEN_ALERT_FILTER
    UPDATE_ALERT_FILTER_OPTION_DATA
    UPDATE_ALL_ALERT_FILTERS_VISIBILITY

    FETCH_LISTING_COUNT
    FETCH_LISTING_COUNT_SUCCESS
    FETCH_LISTING_COUNT_FAILURE

    UPDATE_ALERT_FILTER_TAB_TYPE
    UPDATE_SELECTED_SORT
    CLEAR_ALL_ALERT_FILTERS

    UPDATE_APPLIED_ALERT_FILTERS
    UPDATE_URL_OPTIONS

    UPDATE_APPLIED_SUGGESTIONS
    UPDATE_SELECTED_SUGGESTIONS

    CLEAR_SUGGESTIONS
    UPDATE_ALL_SUB_ALERT_FILTER_OPTIONS
    COPY_ALERT_APPLIED_FROM_MAIN_FILTER

    CREATE_ALERT_FILTERS
    CREATE_ALERT_FILTERS_SUCCESS
    CREATE_ALERT_FILTERS_FAILURE
    UPDATE_MAKE_FILTER_OPTION_DATA
    SET_MAKE_FILTER
`,
    {
        prefix: "alertFilters/"
    }
);
