/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { useHistory } from "react-router-dom";
import CloseButton from "../../shared/icon-cross";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { NUMBER, MOBILE_OS, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { appUrl, cmsURl } from "../../../constants/url-constants";
import ArrowIcon from "../../shared/arrow";
import { LOGIN_TYPE, TEST_DRIVE_BOOKED } from "../../../constants/checkout-constants";
import { SEARCH_ALL_CARS_ROUTE } from "../../../constants/app-constants";
import getMobileOperatingSystem from "../../../utils/helpers/get-operating-system";
import LazyImage from "../../shared/lazy-image";
import { getItemFromSession, setItemInSession } from "../../../utils/helpers/session-storage";
import { Link } from "react-router-dom";
import { SIDE_MENU_SELL_GA_EVENT_NAMES } from "./tracking";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operation";

const DOWNLOAD_APP_URL_HEADER = "https://cars24aus.app.link/DownloadApp";

const SIDEBAR_MENU_TYPE = {
    DEFAULT: "DEFAULT",
    FINANCING: "FINANCING",
    HOW_IT_WORKS: "HOW_IT_WORKS",
    BUY_A_CAR: "BUY_A_CAR",
    SELL_YOUR_CAR: "SELL_YOUR_CAR",
    YOUR_ORDERS: "YOUR_ORDERS",
    SERVICES: "SERVICES"
};

const MenuSidebar = ({
    isLoggedIn,
    email,
    profileName,
    openLoginPopupConnect,
    setShowSidebar,
    nudgeList,
    seoMenu = {},
    logoutConnect,
    resetWishlistConnect,
    showSidebar,
    setLoginTypeConnect,
    preApproved,
    userTradeInData,
    setTradeInToggleSelectedConnect
}) => {
    const LINkS = [
        { route: "findCar", label: "Find a Car", eventLabel: "find_a_car" },
        { route: "/sell-your-car/", label: "Sell your Car", eventLabel: "sell_your_car" },
        { route: "/my-account", label: "My Account", eventLabel: "myAccount", hideMenu: !isLoggedIn },
        { route: "/thecars24way/", label: "The CARS24 Way", eventLabel: "CARS24_way" },
        { route: "/about-us/", label: "About Us", eventLabel: "about_us" },
        { route: `${cmsURl()}/blog/`, label: "Car Advice", eventLabel: "car_advice", externalURL: true },
        { route: "/contact-us/", label: "Contact Us", eventLabel: "contact_us" },
        { route: `${cmsURl()}/blog/faq/`, label: "FAQ", eventLabel: "FAQ", externalURL: true }
    ];
    const iconsRef = useRef({});
    const [iconsLoaded, setIconsLoaded] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isVisible, setIsVisible] = useState(false);
    const [visbilityConfig, setVisibilityConfig] = useState({
        isMakeMenuVisible: false,
        isModelMenuVisible: false,
        isBodyMenuVisible: false,
        isFuelMenuVisible: false,
        isLocationMenuVisible: false
    });
    const [showToolTip, setShowToolTip] = useState(false);
    const history = useHistory();
    const [showMobileIos, setShowMobileIos] = useState();

    const [menuTypeOpen, setMenuTypeOpen] = useState(SIDEBAR_MENU_TYPE.DEFAULT);
    const { customPush }  = useCustomHistoryOperations();

    useEffect(() => {
        if (window && window.navigator && getMobileOperatingSystem() === MOBILE_OS.IOS) {
            setShowMobileIos(true);
        }
    }, []);

    useEffect(() => {
        if (showSidebar) {
            setIsVisible(true);
        }
        if (showSidebar && Object.keys(iconsRef.current).length === 0 && !iconsLoaded) {
            import(/* webpackChunkName: "login-panel-icons" */ "./icons").then(res => {
                iconsRef.current = res.default();
                setIconsLoaded(true);
            });
        }
    }, [iconsLoaded, showSidebar]);

    useEffect(() => {
        if ((preApproved || userTradeInData.tradeIn) && getItemFromSession("showFinanceTradeNudge") === null) {
            setShowToolTip(true);
            setItemInSession("showFinanceTradeNudge", true);
            window.setTimeout(() => {
                setShowToolTip(false);
                setItemInSession("showFinanceTradeNudge", false);
            }, NUMBER.THREE_THOUSAND);
        }
    }, [preApproved, userTradeInData.tradeIn]);

    const hideSidebar = async() => {
        setIsVisible(false);
        setMenuTypeOpen(SIDEBAR_MENU_TYPE.DEFAULT);
        setVisibilityConfig({
            isMakeMenuVisible: false,
            isModelMenuVisible: false,
            isBodyMenuVisible: false,
            isFuelMenuVisible: false,
            isLocationMenuVisible: false
        });
        window.setTimeout(() => setShowSidebar(false), NUMBER.TWO_HUNDRED);
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HAMBURGER_MENU_CLOSE);
    };

    const handlePreApproval = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_APPROVAL_CTA, {
            eventAction: "Get pre approved before buying_HP menu ",
            eventLabel: "{{Get pre approved before buying selected from the menu drop down on HP}}"
        });
    };

    const handleHowToWorks = async(e) => {
        e.preventDefault();
        history.push("/cars24-finance");
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.MENU_FINANCING_CLICKED, {
            eventAction: "Clicked",
            eventLabel: "Yes"
        });

        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HAMBURGER_CAR_FINANCING_HOW_IT_WORKS, {
            eventLabel: "car_financing_how_it_works"
        });
    };

    const handleTradeInYourCar = () => {
        trackMobileCustomEventsAU(SIDE_MENU_SELL_GA_EVENT_NAMES.cta_clicked, {
            ...SIDE_MENU_SELL_GA_EVENT_NAMES.cta_clicked});

    };

    const handleCars24Cover = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS24_COVER_HEADER);
    };

    const onClickLoginSignup = async() => {
        hideSidebar();
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HAMBURGER_MENU_OPTIONS, {
            eventLabel: `option selected login`
        });
        setLoginTypeConnect(LOGIN_TYPE.DEFAULT);
        openLoginPopupConnect(true);
    };

    const onClickMyBooking = (label, path) => {
        if (isLoggedIn) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.MENU_YOUR_ORDERS, {
                eventLabel: label
            });

            if (label === "buying") {
                const currentPath = window.location.pathname;
                if (currentPath === "/" || currentPath.includes("/buy-used")) {
                    trackMobileCustomEventsAU(AU_MOBILE_EVENTS.MY_BOOKINGS_LANDING, {
                        event: "custom_event",
                        eventCategory: "My Bookings opened",
                        eventAction: "location",
                        eventLabel: currentPath === "/" ? "Homepage" : "CLP"
                    });

                }

            }
            history.push(path);
        }
    };

    const onClickLogout = async () => {
        logoutConnect(() => setTradeInToggleSelectedConnect(null));
        resetWishlistConnect();
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HAMBURGER_MENU_OPTIONS, {
            eventLabel: `option selected logout`
        });
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.SIGNOUT, {
            user_id: email,
            user_status: USER_LOGIN_STATUS.LOGGED_OUT
        });

    };

    const handlemakeMenuVisible = () => {
        setVisibilityConfig({
            ...visbilityConfig,
            isMakeMenuVisible: !visbilityConfig.isMakeMenuVisible
        });
    };
    const handlemodelMenuVisible = () => {
        setVisibilityConfig({
            ...visbilityConfig,
            isModelMenuVisible: !visbilityConfig.isModelMenuVisible
        });
    };
    const handlebodyMenuVisible = () => {
        setVisibilityConfig({
            ...visbilityConfig,
            isBodyMenuVisible: !visbilityConfig.isBodyMenuVisible
        });
    };
    const handlefuelMenuVisible = () => {
        setVisibilityConfig({
            ...visbilityConfig,
            isFuelMenuVisible: !visbilityConfig.isFuelMenuVisible
        });
    };
    const handleLocationMenuVisible = () => {
        setVisibilityConfig({
            ...visbilityConfig,
            isLocationMenuVisible: !visbilityConfig.isLocationMenuVisible
        });
    };

    const handleSideBarMenuClick = (type) => {
        setMenuTypeOpen(type === menuTypeOpen ? SIDEBAR_MENU_TYPE.DEFAULT : type);
    };

    const handleAllCars = async (e) => {
        e.preventDefault();
        setIsVisible(false);
        hideSidebar();
        customPush(SEARCH_ALL_CARS_ROUTE);
    };

    const handleIos = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.DOWNLOAD_APP_FOOTER, { eventLabel: "ios_download" });
    };

    const handlePlay = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.DOWNLOAD_APP_FOOTER, { eventLabel: "android_download" });
    };

    const closeToolTip = () => {
        setShowToolTip(false);
    };

    const { makeUrl = [], makeModelUrl = [], fuelUrl = [], bodyUrl = [], cityTypeUrl = [] } = seoMenu || {};

    const handleRedirection = async(e, path) => {
        e.preventDefault();
        await yieldToMain();
        window.open(path, "_blank");
    };

    const renderArrow = (angle) => {
        return (
            <LazyLoadComponent>
                <ArrowIcon
                    white={"White"}
                    rotateBy={angle}
                    alt={"arrow"}
                />
            </LazyLoadComponent>
        );
    };

    const menuData = [
        {
            title: "Browse popular makes",
            handleClick: handlemakeMenuVisible,
            isVisibleKey: visbilityConfig.isMakeMenuVisible,
            menuItems: (makeUrl || [])
        },
        {
            title: "Browse popular models",
            handleClick: handlemodelMenuVisible,
            isVisibleKey: visbilityConfig.isModelMenuVisible,
            menuItems: (makeModelUrl || [])
        },
        {
            title: "Browse by body type",
            handleClick: handlebodyMenuVisible,
            isVisibleKey: visbilityConfig.isBodyMenuVisible,
            menuItems: (bodyUrl || [])
        },
        {
            title: "Browse by fuel type",
            handleClick: handlefuelMenuVisible,
            isVisibleKey: visbilityConfig.isFuelMenuVisible,
            menuItems: (fuelUrl || [])
        },
        {
            title: "Browse by Location",
            handleClick: handleLocationMenuVisible,
            isVisibleKey: visbilityConfig.isLocationMenuVisible,
            menuItems: (cityTypeUrl || [])
        }
    ];

    const isTestDriveCarAvailable = (nudgeList || []).filter(item => item.sourceAlertType === TEST_DRIVE_BOOKED);

    const sellYourCarByLocation = `/sell-your-car/`;

    return (
        <div styleName={`styles.outer`}>
            {showToolTip &&  <div styleName={"styles.blurryBg"}>&nbsp;</div>}
            {/* close button */}
            <span styleName={"styles.closeMenu"}>
                <CloseButton type="white" onClickHandler={hideSidebar} />
            </span>
            {isLoggedIn && <div> <div styleName={"styles.usericon"}> <LazyImage width="29" height="29" src={iconsRef.current.UserIcon} alt={"Usericon"} />
                <p styleName={"styles.profile"}>Hi {profileName}</p></div>
            <div styleName={"styles.speration"}> </div>
            <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.YOUR_ORDERS || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.visible styles.workwrap" : "styles.hidden"}`}>
                <span styleName={"styles.ordersWrap"} onClick={() => handleSideBarMenuClick(SIDEBAR_MENU_TYPE.YOUR_ORDERS)}>
                    {menuTypeOpen === SIDEBAR_MENU_TYPE.YOUR_ORDERS && renderArrow(90)}
                    <div styleName={"styles.orderIcon"}><img src={iconsRef.current.CarIcon} alt="Your orders" /></div>
                    <h4>Your orders
                    </h4>
                    {menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT && renderArrow(270)}
                </span>
                <div styleName={`${menuTypeOpen === SIDEBAR_MENU_TYPE.YOUR_ORDERS ? "styles.visible" : "styles.hidden"}`}>
                    <ul>
                        <li onClick={() => onClickMyBooking("buying", "/my-bookings/")} styleName={"styles.buyingCar"}>
                            <span>
                                <span>Buying</span>
                                {renderArrow(270)}
                            </span>
                            { !!isTestDriveCarAvailable.length  && <p styleName={"styles.activeBooking"}>Test drive reserved </p>}
                        </li>
                        <li onClick={() => onClickMyBooking("selling", "/my-sell-orders/")} styleName={"styles.buyingCar"}>
                            <span>
                                <span>Selling</span>
                                {renderArrow(270)}
                            </span>
                        </li>
                    </ul>
                    <div  styleName={"styles.paddingContainer"}>
                        <div styleName={"styles.seperationth"} />
                    </div>
                    <div>
                        <ul  styleName={"styles.listWrapper"}>
                            <li>
                                <a href={`${appUrl()}/contact-us/`}>Contact us</a>
                            </li>
                            <li>
                                <a href={`${appUrl()}/blog/`}>Car advice</a>
                            </li>
                            <li>
                                <a href={`${appUrl()}/privacy-policy/`}>Privacy Policy</a>
                            </li>
                            <li>
                                <a styleName={"styles.textmargin"} href={`${appUrl()}/terms-of-use/`}>Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <div styleName={`${menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT ? "styles.speration" : "styles.hidden"}`}> </div>
            </div>}
            {!isLoggedIn && (
                <div>
                    <div styleName={"styles.toplogin"}>
                        <h4 onClick={onClickLoginSignup} styleName={"styles.signup"} >
                            <LazyImage width="29" height="29" src={iconsRef.current.UserIcon} />
                            <strong>Login / Sign Up</strong>
                        </h4>
                    </div>
                    <div styleName={"styles.speration"} />
                </div>
            )}
            <ul  styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.YOUR_ORDERS) ? "styles.hidden" : "styles.listWrapper" }` } >
                <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.BUY_A_CAR || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.CarFindWrap styles.visible" : "styles.hidden"}`}>
                    <span styleName={"styles.CarFind"} onClick={() => handleSideBarMenuClick(SIDEBAR_MENU_TYPE.BUY_A_CAR)}>
                        {menuTypeOpen === SIDEBAR_MENU_TYPE.BUY_A_CAR && renderArrow(90)}
                        <a>Buy a car</a>
                        {menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT && renderArrow(270)}
                    </span>

                    <div styleName={`${menuTypeOpen === SIDEBAR_MENU_TYPE.BUY_A_CAR ? "styles.buycar styles.visible" : "styles.hidden"}`}>
                        <ul>
                            <li key={"search_cars"} styleName={"styles.searchBuyCar"}>
                                <Link onClick={handleAllCars} to={`${SEARCH_ALL_CARS_ROUTE}/`}>Search all cars</Link>
                            </li>
                            <div styleName={"styles.seperationth"} />
                            {
                                menuData.map(data =>
                                    (<li key={data.title}>
                                        <span styleName={"styles.CarFind styles.MenuTitle"} onClick={data.handleClick}>
                                            {data.title}
                                            <LazyImage src={data.isVisibleKey ? iconsRef.current.RemoveIcon : iconsRef.current.AddIcon} width="24" height="24" />
                                        </span>
                                        <ul styleName={`${(data.isVisibleKey) ? "styles.visible" : "styles.hidden"}`}>
                                            {(data.menuItems || []).map(item =>
                                                <p key={item.label}><a href={`${appUrl()}/${item.spath}`}>{item.label}</a></p>
                                            )}
                                        </ul>
                                    </li>
                                    ))
                            }
                        </ul>
                    </div>
                </li>
                <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.SELL_YOUR_CAR || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.visible styles.workwrap" : "styles.hidden"}`}>
                    <span styleName={"styles.work"}>
                        <a onClick={handleTradeInYourCar} href={`${appUrl()}${sellYourCarByLocation}`}>Sell or trade in</a>
                    </span>
                </li>
                <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.FINANCING || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.visible styles.listItemsWrap" : "styles.hidden"}`}>
                    <span styleName={"styles.listItems"} onClick={() => handleSideBarMenuClick(SIDEBAR_MENU_TYPE.FINANCING)}>
                        {(menuTypeOpen === SIDEBAR_MENU_TYPE.FINANCING) && renderArrow(90)}
                        <a>Car Financing</a>
                        {(menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) && renderArrow(270)}
                    </span>
                    <div styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.FINANCING) ? "styles.visible" : "styles.hidden"}`}>
                        <ul>
                            <li>
                                <a onClick={handleHowToWorks} href={`${appUrl()}/cars24-finance`}>How it works</a>
                            </li>
                            <li>
                                <a href={isLoggedIn ? `${appUrl()}/my-account/financing/` : `${appUrl()}/financing/get-pre-approval/`} onClick={handlePreApproval}>Get pre-approved before buying</a>
                            </li>
                            <div styleName={"styles.seperationth"} />
                            <div styleName={"styles.faqs"}>
                                <h6>FAQs</h6>
                                <li>
                                    <a href={`${cmsURl()}/blog/faq/`}><LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />How long does it take to get approved?</a>
                                </li>
                                <li>
                                    <a href={`${cmsURl()}/blog/faq/`}><LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />What do I need to get financing?</a>
                                </li>
                                <li styleName={"styles.textmarginone"}>
                                    <a href={`${cmsURl()}/blog/faq/`}><LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />Does CARS24 do commerical loans?</a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </li>
                <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.SERVICES || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.visible styles.listItemsWrap" : "styles.hidden"}`}>
                    <span styleName={"styles.listItems"} onClick={() => handleSideBarMenuClick(SIDEBAR_MENU_TYPE.SERVICES)}>
                        {(menuTypeOpen === SIDEBAR_MENU_TYPE.SERVICES) && renderArrow(90)}
                        <a>Services</a>
                        {(menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) && renderArrow(270)}
                    </span>
                    <div styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.SERVICES) ? "styles.visible styles.listServicesItem" : "styles.hidden"}`}>
                        <ul>
                            <li>
                                <a href={`${appUrl()}/roadworthy-certificate-melbourne`}>Road worthy certificate</a>
                            </li>
                            <div styleName={"styles.seperationth"} />
                        </ul>
                    </div>
                </li>
                <li styleName={`${(menuTypeOpen === SIDEBAR_MENU_TYPE.HOW_IT_WORKS || menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.visible styles.workwrap" : "styles.hidden"}`}>
                    <span styleName={"styles.work"} onClick={() => handleSideBarMenuClick(SIDEBAR_MENU_TYPE.HOW_IT_WORKS)}>
                        {menuTypeOpen === SIDEBAR_MENU_TYPE.HOW_IT_WORKS && renderArrow(90)}
                        <a>How it works</a>
                        {menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT && renderArrow(270)}
                    </span>
                    <div styleName={`${menuTypeOpen === SIDEBAR_MENU_TYPE.HOW_IT_WORKS ? "styles.visible" : "styles.hidden"}`}>
                        <ul>
                            <li>
                                <a href={`${appUrl()}/thecars24way/`}>The CARS24 way</a>
                            </li>
                            <li>
                                <a href={`${appUrl()}/cars24cover/`} onClick={handleCars24Cover}>CARS24 Platinum Cover™</a>
                            </li>
                            <li>
                                <a href={`${appUrl()}/warranty/`}>3 month complimentary warranty</a>
                            </li>
                            <div styleName={"styles.seperationth"} />
                            <div styleName={"styles.faqs"}>
                                <h6>FAQs</h6>
                                <li>
                                    <a
                                        onClick={(e) => handleRedirection(e, "/blog/faq/")}
                                        href={`${cmsURl()}/blog/faq/`}
                                    ><LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />
                                        Can I test drive before buying?
                                    </a>
                                </li>
                                <li>
                                    <a href={`${cmsURl()}/blog/faq/`}
                                        onClick={(e) => handleRedirection(e, "/blog/faq/")}><LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />Where do you deliver?</a>
                                </li>
                                <li styleName={"styles.textmarginone"}>
                                    <a href={`${cmsURl()}/blog/faq/`}>
                                        <LazyImage width="24" height="24" src={iconsRef.current.MessageIcon} />Can I negotiate on price?</a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </li>

                {LINkS.map(({ hideMenu }, idx) => (
                    <React.Fragment key={idx}>
                        {!hideMenu && <li key={`link_${idx}`} />}
                    </React.Fragment>
                ))}
                {(menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) && <div styleName={"styles.seperationsec"} />}

                <ul
                    styleName={(menuTypeOpen === SIDEBAR_MENU_TYPE.DEFAULT) ? "styles.bottomListWrapper" : "styles.hidden"}>

                    {isLoggedIn && <li styleName={"styles.removeUnderline"}> <li styleName={"styles.myaccount styles.removeUnderline"}>
                        <LazyImage width="29" height="29" src={iconsRef.current.UserIcon} /> <a href={`${appUrl()}/my-account/`}>My account</a>

                        {
                            showToolTip &&
                    <React.Fragment>
                        <span styleName={"styles.toolTipWrapper"}>
                            <p styleName={"styles.tooltipTextOne"}>My account</p>
                            <p styleName={"styles.tooltipTextTwo"}>View and modify all details related to  <span>finance</span> and <span>trade-in</span> </p>
                            <p styleName={"styles.tooltipGetIt"} onClick={closeToolTip}>Got it</p>
                        </span>
                    </React.Fragment>
                        }
                    </li>
                    <li><a onClick={onClickLogout}>Logout</a></li> <hr styleName={"styles.border"} /></li>}
                    <li>
                        <a href={`${appUrl()}/contact-us/`}>Contact us</a>
                    </li>
                    <li>
                        <a href={`${appUrl()}/blog/`}>Car advice</a>
                    </li>
                    <li>
                        <a href={`${appUrl()}/privacy-policy/`}>Privacy Policy</a>
                    </li>
                    <li>
                        <a styleName={"styles.textmargin"} href={`${appUrl()}/terms-of-use/`}>Terms of Use</a>
                    </li>
                </ul>
            </ul>
            <div styleName="styles.paddingContainer">
                <div styleName={"styles.seperationsec"} />
                <h3 styleName={"styles.headings"}>Download the app</h3>
                <div styleName={"styles.playstoreWraps"}>
                    { showMobileIos ?
                        <a href={DOWNLOAD_APP_URL_HEADER} onClick={handleIos}>
                            <LazyImage styleName={"styles.playstoreWrapsImage"} src={iconsRef.current.ApplePlay} />
                        </a> :
                        <a href={DOWNLOAD_APP_URL_HEADER} onClick={handlePlay}>
                            <LazyImage styleName={"styles.playstoreWrapsImage"} src={iconsRef.current.GooglePlay} />
                        </a>
                    }
                </div>
            </div>
        </div>
    );
};

MenuSidebar.propTypes = {
    history: PropTypes.any,
    setShowSidebar: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    profileName: PropTypes.string,
    showSidebar: PropTypes.bool,
    logoutConnect: PropTypes.func,
    setLoginTypeConnect: PropTypes.func,
    resetWishlistConnect: PropTypes.func,
    openLoginPopupConnect: PropTypes.func,
    selectedCityCode: PropTypes.number,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    nudgeList: PropTypes.array,
    getSeoListConnect: PropTypes.func,
    seoMenu: PropTypes.object,
    metaContent: PropTypes.object,
    preApproved: PropTypes.bool,
    userTradeInData: PropTypes.object,
    email: PropTypes.string,
    setTradeInToggleSelectedConnect: PropTypes.func
};

export default React.memo(MenuSidebar);
