import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PillNavigation from "./component";

const mapStateToProps = ({
    carDetails: { content, eligibleForExtendedWarranty
    },
    checkout: { checkoutEditFormData, financeData, checkoutEditMode, checkoutEditCompletedStages, maxStep,  extendedWarranty: {
        warrantyPlanInfo
    } = {} },
    abExperiment: {showWarrantyPageShift, msiteBi2ph},
    addOns: {servicingPriceConfig: {data: servicingConfig} = {}}
}) => ({
    content,
    checkoutEditMode,
    checkoutEditCompletedStages,
    checkoutEditFormData,
    financeData,
    maxStep,
    eligibleForExtendedWarranty,
    showWarrantyPageShift,
    warrantyPlanInfo,
    servicingConfig,
    msiteBi2ph
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PillNavigation);
