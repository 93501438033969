/* eslint-disable no-nested-ternary */
import React from "react";
import styles from "./styles.css";
import CloseIcon from "./images/close-icon.svg";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import { useHistory, useParams } from "react-router-dom";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { FIXED_HEADER_SCREENS } from "../../../constants/checkout-constants";
import EditFlowExitModal from "../edit-flow-exit-modal/component";
import withEditCheckout from "../checkout-edit-flow-hoc";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import getCarInfo from "../../../utils/helpers/get-car-info";
import { getTestDriveUrlFromRelativePath } from "../../../utils/helpers/get-test-drive-url";
import { bookInspectionSteps } from "../test-drive-v2/test-drive-steps";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";

const CheckoutTopNav = ({
    heading,
    imageCta,
    imageSrc,
    checkoutEditFormExitModal = false,
    checkoutEditMode,
    type,
    onClickLeftIcon,
    completedPercentage,
    rightComponent = null,
    rightComponentProps = {},
    isDrivaScreen,
    onFormExit = () => {},
    resetEditMode = () => {},
    setCheckoutEditFormExitModalConnect = () => {},
    content,
    preApporvedOrigin,
    testDriveStatus
}) => {
    const history = useHistory();
    const { stepid = "" } = useParams();
    const ComponentRight = rightComponent;
    const {location: {search}} = history;
    const queryParams = parseLocationSearchParams(search);
    const handleLeftIconClick = () => {
        if (preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE) {
            const {carRelativeURL} = getCarInfo(content);
            if (testDriveStatus === "BOOKED") {
                const { relativeURL } = getTestDriveUrlFromRelativePath(carRelativeURL, bookInspectionSteps.bookingConfirmed.route, queryParams);
                history.push(relativeURL);
            } else {
                const { relativeURL } = getTestDriveUrlFromRelativePath(carRelativeURL, bookInspectionSteps.finance.route, queryParams);
                history.push(relativeURL);
            }
            return;
        }
        if (onClickLeftIcon) {
            onClickLeftIcon();
            return;
        }
        if (isDrivaScreen) {
            const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.paymentOptions.route);
            history.push(relativeURL);
        }
    };

    const isPositionFixed = FIXED_HEADER_SCREENS.includes(stepid);

    return (
        <div>
            <div className="header" styleName={`styles.headerWrap ${isPositionFixed ? "styles.fixedHeader" : ""}`}>
                <div styleName={"styles.headingWrap"}>
                    {checkoutEditMode ?
                        <img className={styles.close} src={CloseIcon} onClick={onFormExit} />
                        :
                        <div onClick={handleLeftIconClick}>
                            {type === "modal" ?
                                <img src={CloseIcon} /> :
                                <ArrowIcon rotateBy={90} black={true} />}
                        </div>
                    }
                    <p styleName={"styles.heading"}>{heading}</p>
                </div>
                {rightComponent ? <ComponentRight {...rightComponentProps} /> : <div className="carImageWrp" styleName={"styles.carImageWrap"}>
                    <img src={imageSrc} />
                    <p styleName={"styles.carPrice"}>{imageCta}</p>
                </div>}
                {completedPercentage > 0 &&
                    <div styleName={"styles.progressBarOuter"}>
                        <div styleName={"styles.progressBarPercent"}
                            style={{ width: `${checkoutEditMode ? 0 : completedPercentage}%` }}>&nbsp;</div>
                    </div>
                }
            </div>

            {checkoutEditFormExitModal && <EditFlowExitModal open={checkoutEditFormExitModal} onClose={() => setCheckoutEditFormExitModalConnect(false)} onExit={resetEditMode} />}

        </div>
    );
};

CheckoutTopNav.propTypes = {
    checkoutEditFormExitModal: PropTypes.bool,
    checkoutEditMode: PropTypes.bool,
    heading: PropTypes.string,
    imageCta: PropTypes.string,
    isDrivaScreen: PropTypes.bool,
    content: PropTypes.bool,
    imageSrc: PropTypes.string,
    type: PropTypes.string,
    completedPercentage: PropTypes.number,
    greyColor: PropTypes.bool,
    rightComponent: PropTypes.object,
    rightComponentProps: PropTypes.object,
    onFormExit: PropTypes.func,
    resetEditMode: PropTypes.func,
    setCheckoutEditFormData: PropTypes.func,
    setCheckoutEditFormExitModalConnect: PropTypes.func,
    onClickLeftIcon: PropTypes.func,
    preApporvedOrigin: PropTypes.string,
    testDriveStatus: PropTypes.string
};

export default withEditCheckout(CheckoutTopNav);
