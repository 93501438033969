import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_FINANCE_META_DATA_INIT
    FETCH_FINANCE_META_DATA_SUCCESS
    FETCH_FINANCE_META_DATA_FAILURE

    SET_FINANCE_TAB_VISITED
`,
    {
        prefix: "checkout/"
    }
);
