import { createReducer } from "reduxsauce";
import Types from "./types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import { FILTER_TAB_TYPES } from "../../../utils/filters-v2/constants";
// import getUpdatedFilters from "../../../utils/filters-v2/get-updated-filters";
// import getAppliedFilters from "../../../utils/filters-v2/get-applied-filters";
import { getSortData } from "../../../utils/filters-v2/get-sort-data";
import getUpdatedAppliedFilters, {
    getUpdatedSubFilterOptions
} from "../../../utils/filters-v2/get-updated-applied-filters";
import { LISTING_TYPE } from "../../../constants/app-constants";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: null,
    sorts: [],
    allFilters: {},
    onScreenFilters: [],
    offScreenFilter: [],
    selectedOnScreenFilter: "",
    showAllFilters: false,
    isListingCountFetched: false,
    isListingCountLoading: false,
    listingCount: null,
    listingType: LISTING_TYPE.ALL_CARS,
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    selectedSort: {
        key: "releavance",
        displayText: "By Match"
    },
    appliedFilters: [], // Filters which are applied in the listing.
    selectedFilters: [], // Filters which are selected but not yet applied in the listing
    selectedSuggestions: [],
    appliedSuggestions: [],
    topFilters: [],
    urlOptions: {},
    categories: [],
    loadingCategory: true,
    categoryError: false,
    searchPageResult: {},
    loadingSearch: false,
    recentAppliedFilters: [], // store the most recent applied filters
    marketCategoryEvent: false,
    currentSelectedFilter: "",
    totalListedCar: 0,
    fbEventType: "",
    contolledTopFilters: [],
    isCustomBudgetSelected: false
};

const persistConfig = {
    key: "filtersMobileAU",
    storage,
    whitelist: ["recentAppliedFilters"]
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { data }) => {
    const filtersData = getFiltersData(data.filters);
    const sortsData = getSortData(data.sorts);
    const topFilters = data.topFilters || [];
    return {
        ...state,
        isFetched: true,
        isLoading: false,
        sorts: { ...sortsData },
        ...filtersData,
        // topFilters,
        config: data.config || {},
        totalListedCar: data.total,
        contolledTopFilters: topFilters
    };
};

export const fetchFiltersFailure = () => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: null,
        isLoading: false,
        lastUpdated: new Date().getTime()
    };
};

// export const fetchSSRFiltersSuccess = (state = INITIAL_STATE, {data}) => {
//     const filtersData = getFiltersData(filterMock.filters);
//     return {
//         ...state,
//         isFetched: true,
//         isLoading: false,
//         ...filtersData
//     };
// };

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateFilterStamp = (state = INITIAL_STATE, { lastUpdated }) => {
    return { ...state, lastUpdated };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, { selectedOnScreenFilter, activeTab }) => {
    return {
        ...state,
        selectedOnScreenFilter,
        ...activeTab && {activeTab}
    };
};

// export const updateFilterOptionData = (state = INITIAL_STATE, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData = {}, filterOptionData = {}, subFilterOptionData = {}}) => {
//     return {
//         ...state,
//         allFilters: getUpdatedFilters(state.allFilters, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData, filterOptionData, subFilterOptionData})
//     };
// };

export const updateSelectedFiltersList = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: getUpdatedAppliedFilters(data, state.selectedFilters)
    };
};

export const updateSelectedFilterSuggestionToDefault = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: [...state.appliedFilters],
        selectedSuggestions: [...state.appliedSuggestions]
    };
};

export const updateSelectedSuggestions = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedSuggestions: getUpdatedAppliedFilters(data, state.selectedSuggestions)
    };
};

export const updateAllFiltersVisibility = (state = INITIAL_STATE, { showAllFilters }) => {
    return {
        ...state,
        showAllFilters
    };
};

export const removeFilterBatch = (state = INITIAL_STATE, { data }) => {
    const updatedFilters = getUpdatedAppliedFilters(data, state.selectedFilters);
    return {
        ...state,
        selectedFilters: updatedFilters,
        selectedSuggestions: updatedFilters,
        appliedFilters: updatedFilters,
        recentAppliedFilters: updatedFilters
    };
};

export const fetchListingCountInit = (state = INITIAL_STATE) => {
    return { ...state, isListingCountLoading: true };
};

export const fetchListingCountSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false,
        listingCount: data.total
    };
};

export const fetchListingCountFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, { activeFilterTab }) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const updateSelectedSort = (state = INITIAL_STATE, { selectedSort }) => {
    return {
        ...state,
        selectedSort
    };
};

export const clearAllFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: [],
        appliedFilters: [],
        selectedSuggestions: [],
        appliedSuggestions: [],
        selectedSort: INITIAL_STATE.selectedSort,
        listingCount: INITIAL_STATE.listingCount,
        listingType: LISTING_TYPE.ALL_CARS,
        isCustomBudgetSelected: false
    };
};

export const updateAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedFilters: [...state.selectedFilters],
        recentAppliedFilters: [...state.selectedFilters]
    };
};

export const updateAppliedSuggestions = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedSuggestions: [...state.selectedSuggestions]
    };
};

export const updateUrlOptions = (state = INITIAL_STATE, { urlOptions }) => {
    return {
        ...state,
        urlOptions
    };
};

export const clearSuggestions = (state = INITIAL_STATE, { filterKey }) => {
    const updatedSuggestions = state.selectedSuggestions.filter((suggestion) => suggestion.filterKey !== filterKey);
    return {
        ...state,
        selectedSuggestions: updatedSuggestions
    };
};

export const updateAllSubFilterOptions = (state = INITIAL_STATE, { filterKey, optionKey, isSelected }) => {
    return {
        ...state,
        selectedFilters: getUpdatedSubFilterOptions({
            filterKey,
            optionKey,
            isSelected,
            selectedFilters: state.selectedFilters,
            allFilters: state.allFilters
        })
    };
};

const fetchCategoryFilterInit = (state = INITIAL_STATE, {loading}) => {
    return {
        ...state,
        loadingCategory: loading
    };
};

const fetchCategoryFiltersSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        categories: data,
        loadingCategory: false
    };
};

const fetchCategoryFiltersFailure = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        categoryError: data,
        loadingCategory: false
    };
};

const updateAllSelectedFilterOptions = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedFilters: [...state.selectedFilters, ...data],
        appliedFilters: [...state.appliedFilters, ...data]
    };
};

const updateSelectedCategories = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedCategories: [...state.selectedCategories, ...data]
    };
};

const fetchSearchResultInit = (state = INITIAL_STATE, {loadingSearch}) => {
    return {
        ...state,
        loadingSearch
    };
};

export const fetchSearchResultsSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        searchPageResult: data,
        loadingSearch: false
    };
};

const fetchSearchResultsFailure = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        categoryError: data,
        loadingSearch: false
    };
};

export const updateSSRFiltersList = (state = INITIAL_STATE, { data: {appliedFilters, ...data} }) => {
    return {
        ...state,
        ...data,
        appliedFilters,
        recentAppliedFilters: appliedFilters
    };
};

export const setMarketingCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        marketCategoryEvent: data
    };
};

export const updateListingType = (state = INITIAL_STATE, { listingType }) => ({
    ...state,
    listingType
});

export const setCurrentSelectedFilter = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        currentSelectedFilter: data
    };
};

const setListingFBEvents = (state = INITIAL_STATE, { fbEventType }) => ({
    ...state,
    fbEventType
});

export const fetchTopFilterSucess = (state = INITIAL_STATE, { data, isLoading }) => {
    return {
        ...state,
        topFilters: data || [],
        isTopFilterLoading: isLoading

    };
};

export const setTopFilter = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        topFilters: data || []
    };
};

export const setTopFilterLoadingStatus = (state = INITIAL_STATE, { isLoading }) => {
    return {
        ...state,
        isTopFilterLoading: isLoading
    };
};

export const replaceSelectedFilters = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedFilters: [...data]
    };
};
export const updateCustomBudgetDropdownOption = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isCustomBudgetSelected: data
    };
};
export const HANDLERS = {
    [Types.FETCH_FILTERS]: fetchFiltersInit,
    [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_FILTER_TIMESTAMP]: updateFilterStamp,
    [Types.UPDATE_SELECTED_ON_SCREEN_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_FILTER_OPTION_DATA]: updateSelectedFiltersList,
    [Types.UPDATE_SELECTED_FILTER_SUGGESTION_TO_DEFAULT]: updateSelectedFilterSuggestionToDefault,
    [Types.UPDATE_ALL_FILTERS_VISIBILITY]: updateAllFiltersVisibility,
    [Types.FETCH_LISTING_COUNT]: fetchListingCountInit,
    [Types.FETCH_LISTING_COUNT_SUCCESS]: fetchListingCountSuccess,
    [Types.FETCH_LISTING_COUNT_FAILURE]: fetchListingCountFailure,
    [Types.UPDATE_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.UPDATE_SELECTED_SORT]: updateSelectedSort,
    [Types.CLEAR_ALL_FILTERS]: clearAllFilters,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.UPDATE_URL_OPTIONS]: updateUrlOptions,
    [Types.UPDATE_SELECTED_SUGGESTIONS]: updateSelectedSuggestions,
    [Types.UPDATE_APPLIED_SUGGESTIONS]: updateAppliedSuggestions,
    [Types.CLEAR_SUGGESTIONS]: clearSuggestions,
    [Types.UPDATE_ALL_SUB_FILTER_OPTIONS]: updateAllSubFilterOptions,
    [Types.FETCH_CATEGORY_FILTER_INIT]: fetchCategoryFilterInit,
    [Types.FETCH_CATEGORY_FILTER_SUCCESS]: fetchCategoryFiltersSuccess,
    [Types.FETCH_CATEGORY_FILTER_FAILURE]: fetchCategoryFiltersFailure,
    [Types.UPDATE_ALL_SELECTED_FILTER_OPTIONS]: updateAllSelectedFilterOptions,
    [Types.UPDATE_SELECTED_CATEGORIES]: updateSelectedCategories,
    [Types.FETCH_SEARCH_PAGE_INIT]: fetchSearchResultInit,
    [Types.FETCH_SEARCH_PAGE_SUCCESS]: fetchSearchResultsSuccess,
    [Types.FETCH_SEARCH_PAGE_FAILURE]: fetchSearchResultsFailure,
    [Types.SET_MARKETING_CATEGORY]: setMarketingCategory,
    [Types.UPDATE_LISTING_TYPE]: updateListingType,
    [Types.SET_CURRENT_SELECTED_FILTER]: setCurrentSelectedFilter,
    [Types.SET_LISTING_FB_EVENTS]: setListingFBEvents,
    [Types.FETCH_TOP_FILTERS_SUCCESS]: fetchTopFilterSucess,
    [Types.SET_TOP_FILTERS]: setTopFilter,
    [Types.FETCH_TOP_FILTERS_INIT]: setTopFilterLoadingStatus,
    [Types.REPLACE_SELECTED_FILTERS]: replaceSelectedFilters,
    [Types.UPDATE_CUSTOM_BUDGET_DROPDOWN_OPTION]: updateCustomBudgetDropdownOption,
    [Types. REMOVE_FILTER_BATCH]: removeFilterBatch
};

export default persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
