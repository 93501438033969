import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_SHOW_HEADER
    SET_FIXED_HEADER
`,
    {
        prefix: "header/"
    }
);
