/* eslint-disable complexity */
import { useSelector } from "react-redux";
import isEstimatedTradeIn from "../utils/helpers/is-estimated-trade-in";

const useShowTradeInCheckoutStep = () => {
    const { tradeIn = {}, checkout = {}, user = {} } = useSelector(state => state);
    const { tradeInData = {} } = checkout || {};
    const { userTradeInData = {} } = tradeIn || {};
    const { isGuestLogin = false, isLoggedIn = false } = user || {};
    const showGuestLoginVariant = isGuestLogin && tradeInData?.offeredPrice && !isLoggedIn && !tradeInData?.expired;
    const isExistingEstimatedValuation = isEstimatedTradeIn(tradeInData || {});
    const isExistingValuation = userTradeInData?.tradeIn;
    const showTradeInCheckoutStep = isExistingEstimatedValuation || isExistingValuation || showGuestLoginVariant;
    const showTradeinInterestedPopup = !(isExistingEstimatedValuation || isExistingValuation || showGuestLoginVariant);
    return { showTradeInCheckoutStep, isExistingEstimatedValuation, isExistingValuation, showTradeinInterestedPopup };
};

export default useShowTradeInCheckoutStep;
