import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { setCheckoutEditCompletedStages, setCheckoutEditFormData, setCheckoutEditFormExitModal, setCheckoutEditMode, setCheckoutEditQuestionsUpdateModal, setLoanOfferScreen, setZeroDpSelectOfferCoachMark } from "../checkout-info/actions";
import { isEqual } from "lodash";
import { LOAN_OFFER_SCREENS } from "../../../constants/loan-offers";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import { bookInspectionSteps } from "../test-drive-v2/test-drive-steps";
import { getTestDriveUrlFromRelativePath } from "../../../utils/helpers/get-test-drive-url";
import getCarInfo from "../../../utils/helpers/get-car-info";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";

export default function withEditCheckout(Component) {
    const CheckoutEditMode = (props) => {
        const {
            checkoutEditFormData,
            content,
            history,
            readOnlyFinanceData,
            financeErrorResponse,
            setCheckoutEditFormDataConnect,
            setCheckoutEditCompletedStagesConnect,
            setCheckoutEditQuestionsUpdateModalConnect,
            setCheckoutEditFormExitModalConnect,
            setCheckoutEditModeConnect,
            setLoanOfferScreenConnect,
            setZeroDpSelectOfferCoachMarkConnect,
            preApporvedOrigin
        } = props || {};

        const {location: {search}} = history;
        const queryParams = parseLocationSearchParams(search);

        const resetCheckoutEditData = () => {
            setCheckoutEditFormDataConnect(readOnlyFinanceData);
            setCheckoutEditModeConnect(false);
            setCheckoutEditQuestionsUpdateModalConnect(false, false);
            setCheckoutEditFormExitModalConnect(false);
            setCheckoutEditCompletedStagesConnect([]);
            if (financeErrorResponse && financeErrorResponse.errorCode) {
                setLoanOfferScreenConnect(LOAN_OFFER_SCREENS.ERROR_SCREEN);
                setZeroDpSelectOfferCoachMarkConnect(false);
            } else setLoanOfferScreenConnect(LOAN_OFFER_SCREENS.LOAN_OFFERS);
        };

        const resetEditMode = () => {
            resetCheckoutEditData();
            let backUrl = getCheckoutPageURL(content, checkoutRoutes.selectOffer.route);
            if (preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE) {
                const {carRelativeURL} = getCarInfo(content);
                backUrl = getTestDriveUrlFromRelativePath(carRelativeURL, bookInspectionSteps.finance.route, queryParams);
            }
            history.push(backUrl?.relativeURL);
        };

        const onFormExit = () => {
            if (isEqual(readOnlyFinanceData, checkoutEditFormData)) {
                resetEditMode();
            } else {
                setCheckoutEditFormExitModalConnect(true);
            }
        };

        const onEditStageComplete = (stages = []) => {
            setCheckoutEditCompletedStagesConnect(stages);
        };

        return <Component {...props} resetCheckoutEditData={resetCheckoutEditData} resetEditMode={resetEditMode} onFormExit={onFormExit} onEditStageComplete={onEditStageComplete} />;
    };

    const mapStateToProps = ({
        carDetails: {
            content
        },
        checkout: {
            checkoutEditCompletedStages,
            readOnlyFinanceData,
            checkoutEditFormData,
            financeErrorResponse
        },
        preApprovalLoan: {
            preApporvedOrigin
        }
    }) => ({
        content,
        checkoutEditCompletedStages,
        checkoutEditFormData,
        readOnlyFinanceData,
        financeErrorResponse,
        preApporvedOrigin
    });

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                setCheckoutEditFormDataConnect: setCheckoutEditFormData,
                setCheckoutEditFormExitModalConnect: setCheckoutEditFormExitModal,
                setCheckoutEditQuestionsUpdateModalConnect: setCheckoutEditQuestionsUpdateModal,
                setCheckoutEditModeConnect: setCheckoutEditMode,
                setCheckoutEditCompletedStagesConnect: setCheckoutEditCompletedStages,
                setLoanOfferScreenConnect: setLoanOfferScreen,
                setZeroDpSelectOfferCoachMarkConnect: setZeroDpSelectOfferCoachMark
            },
            dispatch
        );

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutEditMode));
}
