import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    brands: [],
    brandModelMap: [],
    modelYearMap: [],
    regoFlowData: {
        stepId: "make"
    },
    mmvyUserInputs: {
        stepId: "make"
    },
    tradeInDataGuest: {},
    isSSR: false,
    openRegoflowModal: false,
    openMMVYFlowModal: false
};

export const openRegoflowModalRequest = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    return {
        ...state,
        openRegoflowModal: payload,
        openMMVYFlowModal: false
    };
};

export const openMMVYFlowModalRequest = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    return {
        ...state,
        openRegoflowModal: false,
        openMMVYFlowModal: payload
    };
};

export const fetchMakeModelRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        brands: []
    };
};

export const fetchMakeModelSuccess = (state = INITIAL_STATE, action) => {
    const {payload} = action;
    return {
        ...state,
        brands: payload?.brands,
        brandModelMap: payload?.brandModelMap,
        modelYearMap: payload?.modelYearMap
    };
};

export const fetchMakeModelFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        brands: []
    };
};

export const updateMMVYFlowDataRequest = (state = INITIAL_STATE, action) => {
    const {payload} = action;
    return {
        ...state,
        mmvyUserInputs: {
            ...payload?.mmvyUserInputs
        }
    };
};

export const createPublicTradeInOrderRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInDataGuest: {}
    };
};

export const createPublicTradeInOrderSuccess = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    return {
        ...state,
        tradeInDataGuest: payload
    };
};

export const createPublicTradeInOrderFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInDataGuest: {}
    };
};
// Variants
export const fetchVariantsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInCarVariants: {
            variantList: [],
            loading: true,
            error: false
        }
    };
};

export const fetchVariantsSuccess = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    return {
        ...state,
        tradeInCarVariants: {
            variantList: payload,
            loading: false,
            error: false
        }
    };
};

export const fetchVariantsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInCarVariants: {
            variantList: [],
            loading: false,
            error: true
        }
    };
};

export const updateRegoFlowDataRequest = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    return {
        ...state,
        regoFlowData: {
            ...payload?.regoFlowData
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_MAKE_MODEL_LIST_REQUEST]: fetchMakeModelRequest,
    [Types.FETCH_MAKE_MODEL_LIST_SUCCESS]: fetchMakeModelSuccess,
    [Types.FETCH_MAKE_MODEL_LIST_FAILURE]: fetchMakeModelFailure,
    [Types.UPDATE_MMVY_FLOW_DATA_REQUEST]: updateMMVYFlowDataRequest,
    [Types.CREATE_PUBLIC_TRADE_IN_ORDER_REQUEST]: createPublicTradeInOrderRequest,
    [Types.CREATE_PUBLIC_TRADE_IN_ORDER_SUCCESS]: createPublicTradeInOrderSuccess,
    [Types.CREATE_PUBLIC_TRADE_IN_ORDER_FAILURE]: createPublicTradeInOrderFailure,
    [Types.FETCH_VARIANTS_REQUEST]: fetchVariantsRequest,
    [Types.FETCH_VARIANTS_SUCCESS]: fetchVariantsSuccess,
    [Types.FETCH_VARIANTS_FAILURE]: fetchVariantsFailure,
    [Types.UPDATE_REGO_FLOW_DATA_REQUEST]: updateRegoFlowDataRequest,
    [Types.OPEN_REGO_FLOW_MODAL]: openRegoflowModalRequest,
    [Types.OPEN_MMVY_FLOW_MODAL]: openMMVYFlowModalRequest
};

export default createReducer(INITIAL_STATE, HANDLERS);
