import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Tick from "./../checkout-pill/images/check.svg";

const FinancePills = ({ image, text, color, isImage, hideCircle, onClick = () => {} }) => {
    const toggleColor = () => {
        switch (color) {
        case "blue":
            return "styles.pill styles.currentPill";
        case "green":
            return "styles.pill";
        default:
            return "styles.pill styles.inactivePill";
        }
    };
    return (
        <div styleName={toggleColor()} onClick={onClick}>
            {!hideCircle && <div styleName={`styles.circle ${color !== "blue" && color !== "green" ? "styles.inactiveCirlce" : ""}`}>
                {isImage ? <img src={Tick} /> : image}
            </div>}
            {text}
        </div>
    );
};

FinancePills.propTypes = {
    image: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    isImage: PropTypes.bool,
    hideCircle: PropTypes.bool
};

export default FinancePills;
