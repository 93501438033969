/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./styles.css";
import { getCheckoutPageURL, getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import Tick from "./images/check.svg";

const Pill = ({
    pillData,
    current,
    active,
    content,
    activeRef,
    onCustomPillClick = () => {},
    variant,
    index,
    showGreenTick = false,
    showNumber = true,
    isLink = true,
    backActive = false
}) => {
    const getUrl = () => {

        if (pillData.type === "test-drive") return "";
        if (variant) {
            const {relativeURL} = getMyBookingCarDetailsURL(content.appointmentId, pillData.route, null, variant);
            return relativeURL;
        } else {
            const {relativeURL} = getCheckoutPageURL(content, pillData.route);
            return relativeURL;
        }
    };
    if (current) {
        return (
            <div ref={activeRef} styleName={`styles.pill styles.currentPill ${showNumber ? "" : "styles.noNumberPadding"}`} className="pill currentPll" onClick={(e) => onCustomPillClick(e, pillData)}>
                {showNumber &&
                    <div styleName={"styles.circle"}>
                        {index}
                    </div>
                }
                {pillData.name}
            </div>
        );
    }
    if (!active) {
        return (
            <div styleName="styles.pill styles.inactivePill" className="pill inactivePill">
                {showNumber &&
                    <div styleName={"styles.inactiveCirlce"}>
                        {index}
                    </div>
                }
                {pillData.name}
            </div>
        );
    }

    const trackClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_CHIP_NAVIGATION, {
            eventLabel: `options ${pillData.gtmPillClickLabel}`
        });
    };
    if (!isLink) {
        return (<div to={pillData.type === "pre-approval-financing" ? pillData.route : getUrl()} onClick={(e) => onCustomPillClick(e, pillData) || trackClick}>
            <div styleName={`styles.pill ${showGreenTick ? "styles.visited" : ""} ${showNumber ? "" : "styles.noNumberPadding"}`} className="pill">
                {showNumber &&
                // eslint-disable-next-line no-nested-ternary
                    <div styleName={current ? "styles.circle" : backActive ? `styles.backActive ${showGreenTick ? "styles.bg-white" : ""}` : "styles.inactiveCirlce"}>
                        {showGreenTick ? <img src={Tick} alt="" /> :  index}
                    </div>
                }
                {pillData.name}
            </div>
        </div>);
    }

    return (
        <Link to={pillData.type === "pre-approval-financing" ? pillData.route : getUrl()} onClick={(e) => onCustomPillClick(e, pillData) || trackClick}>
            <div styleName={`styles.pill ${showGreenTick ? "styles.visited" : ""}`} className="pill">
                {showNumber &&
                    // eslint-disable-next-line no-nested-ternary
                    <div styleName={current ? "styles.circle" : backActive ? `styles.backActive ${showGreenTick ? "styles.bg-white" : ""}` : "styles.inactiveCirlce"}>
                        {showGreenTick ? <img src={Tick} alt="" /> :  index}
                    </div>
                }

                {/* {showNumber &&
                    <div styleName={current ? "styles.circle" : backActive ? `styles.backActive ${showGreenTick ? "styles.bg-white" : ""}` : "styles.inactiveCirlce"}>
                        {showGreenTick ? <img src={Tick} alt="" /> :  index}
                    </div>
                } */}
                {pillData.name}
            </div>
        </Link>
    );
};

Pill.propTypes = {
    pillData: PropTypes.object,
    onCustomPillClick: PropTypes.func,
    current: PropTypes.bool,
    active: PropTypes.bool,
    content: PropTypes.object,
    activeRef: PropTypes.object,
    variant: PropTypes.string,
    index: PropTypes.number,
    showNumber: PropTypes.bool,
    showGreenTick: PropTypes.bool,
    isLink: PropTypes.bool,
    backActive: PropTypes.bool
};

export default Pill;
