import { createReducer } from "reduxsauce";
import Types from "./types";
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import { FILTER_TAB_TYPES } from "../../../utils/filters-v2/constants";
// import getUpdatedFilters from "../../../utils/filters-v2/get-updated-filters";
// import getAppliedFilters from "../../../utils/filters-v2/get-applied-filters";
import { getSortData } from "../../../utils/filters-v2/get-sort-data";
import getUpdatedAppliedFilters, {
    getUpdatedSubFilterOptions
} from "../../../utils/filters-v2/get-updated-applied-filters";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: null,
    sorts: [],
    allAlertFilters: {},
    onScreenAlertFilters: [],
    offScreenAlertFilters: [],
    selectedOnScreenAlertFilter: "",
    showAllAlertFilters: false,
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    appliedAlertFilters: [], // Filters which are applied in the listing.
    selectedAlertFilters: [], // Filters which are selected but not yet applied in the listing
    selectedSuggestions: [],
    appliedSuggestions: [],
    urlOptions: {},
    selectedMakeFilters: []
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { data }) => {
    const {allFilters, offScreenFilters, onScreenFilters} = getFiltersData(data.filters);
    const sortsData = getSortData(data.sorts);
    return {
        ...state,
        isFetched: true,
        isLoading: false,
        sorts: { ...sortsData },
        allAlertFilters: allFilters,
        onScreenAlertFilters: offScreenFilters,
        offScreenAlertFilters: onScreenFilters
    };
};

export const fetchFiltersFailure = () => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: null,
        isLoading: false,
        lastUpdated: new Date().getTime()
    };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateFilterStamp = (state = INITIAL_STATE, { lastUpdated }) => {
    return { ...state, lastUpdated };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, { selectedOnScreenAlertFilter }) => {
    return {
        ...state,
        selectedOnScreenAlertFilter
    };
};

export const updateSelectedFiltersList = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedAlertFilters: getUpdatedAppliedFilters(data, state.selectedAlertFilters)
    };
};

export const updateMakeSelectedFiltersList = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedMakeFilters: getUpdatedAppliedFilters(data, state.selectedMakeFilters)
    };
};

export const updateSelectedSuggestions = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedSuggestions: getUpdatedAppliedFilters(data, state.selectedSuggestions)
    };
};

export const updateAllFiltersVisibility = (state = INITIAL_STATE, { showAllAlertFilters }) => {
    return {
        ...state,
        showAllAlertFilters
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, { activeFilterTab }) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const clearAllFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedAlertFilters: [],
        appliedAlertFilters: [],
        selectedSuggestions: [],
        appliedSuggestions: []
    };
};

export const updateAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedAlertFilters: [...state.selectedAlertFilters]
    };
};

export const updateAppliedSuggestions = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedSuggestions: [...state.selectedSuggestions]
    };
};

export const updateUrlOptions = (state = INITIAL_STATE, { urlOptions }) => {
    return {
        ...state,
        urlOptions
    };
};

export const clearSuggestions = (state = INITIAL_STATE, { filterKey }) => {
    const updatedSuggestions = state.selectedSuggestions.filter((suggestion) => suggestion.filterKey !== filterKey);
    return {
        ...state,
        selectedSuggestions: updatedSuggestions
    };
};

export const updateAllSubFilterOptions = (state = INITIAL_STATE, { filterKey, optionKey, isSelected }) => {
    return {
        ...state,
        selectedMakeFilters: getUpdatedSubFilterOptions({
            filterKey,
            optionKey,
            isSelected,
            selectedFilters: state.selectedMakeFilters,
            allFilters: state.allAlertFilters
        })
    };
};

const copyAlertAppliedFromFilter = (state = INITIAL_STATE, {data}) => {
    const {selectedAlertFilters, selectedMakeFilters } = data;
    return {
        ...state,
        selectedAlertFilters,
        selectedMakeFilters
    };
};

const setMakeFilterData = (state = INITIAL_STATE, {data}) => ({...state, selectedMakeFilters: data});

export const HANDLERS = {
    [Types.FETCH_ALERT_FILTERS]: fetchFiltersInit,
    [Types.FETCH_ALERT_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_ALERT_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_ALERT_FILTER_TIMESTAMP]: updateFilterStamp,
    [Types.UPDATE_SELECTED_ON_SCREEN_ALERT_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_ALERT_FILTER_OPTION_DATA]: updateSelectedFiltersList,
    [Types.UPDATE_ALL_ALERT_FILTERS_VISIBILITY]: updateAllFiltersVisibility,
    [Types.UPDATE_ALERT_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.CLEAR_ALL_ALERT_FILTERS]: clearAllFilters,
    [Types.UPDATE_APPLIED_ALERT_FILTERS]: updateAppliedFilters,
    [Types.UPDATE_URL_OPTIONS]: updateUrlOptions,
    [Types.UPDATE_SELECTED_SUGGESTIONS]: updateSelectedSuggestions,
    [Types.UPDATE_APPLIED_SUGGESTIONS]: updateAppliedSuggestions,
    [Types.CLEAR_SUGGESTIONS]: clearSuggestions,
    [Types.UPDATE_ALL_SUB_ALERT_FILTER_OPTIONS]: updateAllSubFilterOptions,
    [Types.COPY_ALERT_APPLIED_FROM_MAIN_FILTER]: copyAlertAppliedFromFilter,
    [Types.UPDATE_MAKE_FILTER_OPTION_DATA]: updateMakeSelectedFiltersList,
    [Types.SET_MAKE_FILTER]: setMakeFilterData
};

export default createReducer(INITIAL_STATE, HANDLERS);
