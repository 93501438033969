import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    carLoanCalculator: {
        emailEstimateFlowStarted: false,
        emailEstimatesSentSuccess: false,
        emailEstimatesTriggeredEndpoint: false,
        repayments: {
            metaContent: {}
        },
        borrowing: {
            metaContent: {}
        },
        loading: false,
        error: null,
        interestRates: {}
    }
};

export const setClpCarLoanCalculatorMetaData = (state = INITIAL_STATE, {data, key = "REPAYMENT"}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            loading: false,
            [key]: {
                metaContent: data
            }
        }
    };
};

export const setClpCarLoanCalculatorLoading = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            loading: data,
            error: false
        }
    };
};

export const setClpCarLoanCalculatorError = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            error: data,
            loading: false
        }
    };
};

export const setClpCarLoanInterestRates = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            interestRates: data
        }
    };
};

export const setEmailEstimateFlow = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            emailEstimateFlowStarted: data
        }
    };
};

export const setEmailEstimateSentSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            emailEstimatesSentSuccess: data
        }
    };
};

export const setEmailEstimatesTriggeredEndpoint = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        carLoanCalculator: {
            ...state.carLoanCalculator,
            emailEstimatesTriggeredEndpoint: data
        }
    };
};

export const HANDLERS = {
    [Types.SET_CAR_LOAN_CALCULATOR_META_DATA]: setClpCarLoanCalculatorMetaData,
    [Types.SET_CAR_LOAN_CALCULATOR_LOADING]: setClpCarLoanCalculatorLoading,
    [Types.SET_CAR_LOAN_CALCULATOR_ERROR]: setClpCarLoanCalculatorError,
    [Types.SET_CAR_LOAN_INTEREST_RATES]: setClpCarLoanInterestRates,
    [Types.SET_EMAIL_ESTIMATE_FLOW]: setEmailEstimateFlow,
    [Types.SET_EMAIL_ESTIMATE_SENT_SUCCESS]: setEmailEstimateSentSuccess,
    [Types.SET_ESTIMATE_TRIGGER_ENDPOINT]: setEmailEstimatesTriggeredEndpoint
};

export default createReducer(INITIAL_STATE, HANDLERS);
