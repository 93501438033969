export const CLP_CRM_VARIANTS = {
    NEW_ARRIVAL: "NEW_ARRIVALS",
    PRICE_DROP: "PRICE_DROP"
};

export const getIsCrmVariant = (queryParam) => {
    const checkParams = queryParam?.toUpperCase();
    if (checkParams === CLP_CRM_VARIANTS.PRICE_DROP || checkParams === CLP_CRM_VARIANTS.NEW_ARRIVAL) {return true;}
    return false;
};

export const getCurrentCrmVariant = (queryParam) => {
    const showClpCrmVariant = queryParam?.showClpCrmVariant || "";
    const checkParams = showClpCrmVariant.toUpperCase();

    if (checkParams === CLP_CRM_VARIANTS.PRICE_DROP) return CLP_CRM_VARIANTS.PRICE_DROP;
    else if (checkParams === CLP_CRM_VARIANTS.NEW_ARRIVAL) return CLP_CRM_VARIANTS.NEW_ARRIVAL;
    else return "";
};
