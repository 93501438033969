import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import Close from "./images/close.png";
import TransparentButton from "../../shared/button/transparent-button";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

const EditFlowExitModal = ({ open, onClose, onExit }) => {
    useEffect(() => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.EDIT_FLOW, {
            eventAction: "Flow closure pop up",
            eventLabel: "Pop up seen"
        });
    }, []);

    const handleExit = (e) => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.EDIT_FLOW, {
            eventAction: "Flow closure pop up",
            eventLabel: "Yes exit clicked"
        });
        onExit(e);
    };
    return (
        <Modal isOpen={open} close={onClose}>
            <div styleName={"styles.modalWrapper"}>
                <div styleName={"styles.headerWrapper"}>
                    <p styleName={"styles.heading"}>Are you sure you want to exit?</p>
                    <img src={Close} onClick={onClose} />
                </div>
                <p styleName={"styles.content"}>Exiting this will lose your progress. Use <span>'Next'</span> button to navigate till the end of the form and press <span>'Submit'</span> to reflect your changes.</p>
                <TransparentButton text="Yes, exit" onClick={handleExit} />
            </div>
        </Modal>
    );
};

EditFlowExitModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onExit: PropTypes.func
};

export default EditFlowExitModal;
