import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    loading: false,
    error: null,
    cmsContent: {},
    isSSR: false,
    serviceOrderCreationLoading: false,
    serviceOrderCreationError: null,
    checkout: {
        userCarDetails: {
            make: "",
            model: "",
            bodyType: ""
        }
    }
};

const fetchCmsDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        loading: true
    };
};
const fetchCmsDataSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        loading: false,
        cmsContent: data,
        error: null
    };
};
const fetchCmsDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        loading: false,
        error
    };
};

const setUserCarDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkout: { ...state.checkout, userCarDetails: data }
    };
};
const setCreateServiceOrderInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        serviceOrderCreationLoading: true,
        serviceOrderCreationError: null
    };
};
const setCreateServiceOrderSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        serviceOrderCreationLoading: false,
        serviceOrder: data
    };
};
const setCreateServiceOrderFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        serviceOrderCreationLoading: false,
        serviceOrderCreationError: error
    };
};
export const HANDLERS = {
    [Types.FETCH_CMS_CONTENT_INIT]: fetchCmsDataInit,
    [Types.FETCH_CMS_CONTENT_SUCCESS]: fetchCmsDataSuccess,
    [Types.FETCH_CMS_CONTENT_FAILURE]: fetchCmsDataFailure,
    [Types.SET_USER_CAR_DETAILS]: setUserCarDetails,
    [Types.SET_CREATE_SERVICE_ORDER_INIT]: setCreateServiceOrderInit,
    [Types.SET_CREATE_SERVICE_ORDER_SUCCESS]: setCreateServiceOrderSuccess,
    [Types.SET_CREATE_SERVICE_ORDER_FAILURE]: setCreateServiceOrderFailure
};
export default createReducer(INITIAL_STATE, HANDLERS);
