import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CMS_CONTENT_INIT
    FETCH_CMS_CONTENT_SUCCESS
    FETCH_CMS_CONTENT_FAILURE

    SET_USER_CAR_DETAILS

    SET_CREATE_SERVICE_ORDER_INIT
    SET_CREATE_SERVICE_ORDER_SUCCESS
    SET_CREATE_SERVICE_ORDER_FAILURE
`,
    {
        prefix: "roadworthyCertificate/"
    }
);
