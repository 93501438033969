import React, { useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import PageLoader from "../../shared/page-loader";
const Login = loadable(() => import("../login"), { fallback: <PageLoader/> });

const MobileRoot = ({
    children,
    onLoginSuccessCallback,
    setOnLoginSuccessCallback,
    onLoginCloseCallback,
    setOnLoginCloseCallback,
    isLoginPopupVisible,
    isSSR,
    reloadCarListConnect,
    isLoggedIn,
    secureToken,
    fetchWishlistedCarsListConnect
}) => {

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isSSR) {
            reloadCarListConnect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            { children }
            {isLoginPopupVisible && <Login
                onLoginSuccessCallback={onLoginSuccessCallback}
                setOnLoginSuccessCallback={setOnLoginSuccessCallback}
                onLoginCloseCallback={onLoginCloseCallback}
                setOnLoginCloseCallback={setOnLoginCloseCallback}
            />}
        </React.Fragment>
    );
};

MobileRoot.propTypes = {
    children: PropTypes.array,
    onLoginSuccessCallback: PropTypes.func,
    setOnLoginSuccessCallback: PropTypes.func,
    onLoginCloseCallback: PropTypes.func,
    setOnLoginCloseCallback: PropTypes.func,
    isLoginPopupVisible: PropTypes.bool,
    isSSR: PropTypes.bool,
    reloadCarListConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    secureToken: PropTypes.string,
    fetchWishlistedCarsListConnect: PropTypes.func
};

export default withLogin(withWishlist(MobileRoot));
