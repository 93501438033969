import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "querystring";
import styles from "./styles.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import loadable from "@loadable/component";
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";

// CSS
import "../../../styles/reset.mobile.css";
import "../../../styles/slick-reset-mobile.css";
import "../../../styles/slick-theme.css";
import "../../../styles/slick.css";
import "../../../fonts/fonts.css";
import "../../../styles/reset.au.mobile.css";

// utils
import { updateWebviewStatus } from "../../au.configuration/actions";
import saveCookie from "../../../utils/helpers/save-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import deleteCookie from "../../../utils/helpers/delete-cookie";

// Overrides specific to AU
import ErrorHandler from "../../shared/with-error-handler";
import ToastMessage from "../../shared/toast-message";

import Header from "../header";
import Footer from "../footer";
import Error from "../../shared/error";
import MenuSidebar from "../menu-sidebar";
const BottomNavigation  = loadable(() => import("../bottom-navigation"));

// constants
import { NUMBER } from "../../../constants/app-constants";

// eslint-disable-next-line max-params
export default function withAULayout(WrappedComponent, header = true, footer = true, options = {}) {
    const { headerProps, showBottomNavigation = false, hideBottomNavOnScroll = true, allowBack  } = options;
    // eslint-disable-next-line complexity
    const Layout = (props) => {
        const [showSidebar, setShowSidebar] = useState(false);
        const { route = {} } = props;
        useEffect(() => {
            const params = qs.parse(window.location.search.substring(1));
            const isWebView = params.utm_source === "aus_app";
            if (isWebView) {
                if (!parseCookie("isWebView")) {
                    saveCookie("isWebView", true, NUMBER.THIRTY);
                }
                props.updateWebviewStatusConnect(true);
            } else if (!isWebView && parseCookie("isWebView")) {
                deleteCookie("isWebView");
                props.updateWebviewStatusConnect(false);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.isWebview]);

        useEffect(() => {
            smoothscroll.polyfill();
        }, []);

        const {hideLayoutViaUTM = true} = props.route || {};
        return props.isSSRError ? (
            <Error />
        ) : (
            <Fragment>
                <div styleName={"styles.bodyWidth"}>
                    <ErrorHandler>{header && (hideLayoutViaUTM || !props.isWebview)  && (
                        <Header
                            showSidebar = {showSidebar }
                            setShowSidebar={setShowSidebar}
                            allowBack={allowBack}
                            {...props}
                            {...headerProps}
                        />
                    )}
                    {
                        showSidebar && (
                            <div styleName={`${showSidebar ? "styles.visible" : "styles.hidden"}`}>
                                <MenuSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
                            </div>
                        )
                    }
                    </ErrorHandler>
                    <ErrorHandler>
                        <WrappedComponent {...props} />
                    </ErrorHandler>
                    <ErrorHandler>{footer && (hideLayoutViaUTM || !props.isWebview)  && <Footer />}</ErrorHandler>
                    <ErrorHandler>
                        { showBottomNavigation &&
                            <BottomNavigation
                                setShowSidebar={setShowSidebar}
                                routeName={route?.name}
                                isActive= {true}
                                hideBottomNavOnScroll={hideBottomNavOnScroll}
                            />
                        }
                    </ErrorHandler>
                    <ErrorHandler>
                        <ToastMessage />
                    </ErrorHandler>
                </div>
            </Fragment>
        );
    };

    Layout.propTypes = {
        isWebview: PropTypes.bool,
        isSSRError: PropTypes.bool,
        updateWebviewStatusConnect: PropTypes.func,
        route: PropTypes.object,
        isUtmsourced: PropTypes.bool
    };

    Layout.defaultProps = {};

    const mapStateToProps = ({user: {isWebview}}) => {

        return {
            isWebview,
            isSSRError: false
        };
    };

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                updateWebviewStatusConnect: updateWebviewStatus
            },
            dispatch
        );

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
}
