import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    showHeader: true,
    fixedHeader: false
};

export const setShowHeader = (state = INITIAL_STATE, { data }) => ({
    ...state,
    showHeader: data
});

export const setFixedHeader = (state = INITIAL_STATE, { data }) => ({
    ...state,
    fixedHeader: data
});

export const HANDLERS = {
    [Types.SET_SHOW_HEADER]: setShowHeader,
    [Types.SET_FIXED_HEADER]: setFixedHeader
};

export default createReducer(INITIAL_STATE, HANDLERS);
