/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useMemo } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import CallIcon from "./images/calling.svg";
import CallIconWhite from "./images/calling-white.svg";
import EmailIcon from "./images/email.svg";
import Hamburger from "./images/hamburger-blue.svg";
import LogoImage from "./images/cars24-logo.svg";
import WishlistBlue from "./images/wishlist.svg";
import WishlistWhite from "./images/wishlist-white.svg";
import BackIcon from "./images/arrow_back.svg";

import Arrow from "./images/arrow.svg";
import Cars24Newlogo from "./images/cars24-new-logo.svg";
import withLogin from "../with-login";
import { SESSION_KEYS, WEB_2_APP_BANNER } from "../../../constants/app-constants";

import { HELPLINE_NUMBER_AU, SUPPORT_EMAIL } from "../../../constants/app-constants";
import LazyImage from "../../shared/lazy-image";

import { LOGIN_SOURCE } from "../../../au-c2b/constants/app-constants";
import { LOGIN_TYPE } from "../../../constants/checkout-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import RoutesConstant from "../../../constants/routes-constant";
import currentPathObject from "../../../utils/helpers/get-current-page";
import { getItemFromSession } from "../../../utils/helpers/session-storage";
import useQueryParams from "../../../hooks/use-queryPrams";
import { CLP_CRM_VARIANTS } from "../car-listing-header-container/helper";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";

const DOWNLOAD_APP_URL_CTA_HEADER = {
    "VARIANT_A": "https://cars24aus.app.link/DJrKFVxUbDb",
    "VARIANT_B": "https://cars24aus.app.link/i14ItMCUbDb",
    "VARIANT_C": "https://cars24aus.app.link/sd41LtNUbDb",
    "WEB_2_APP_BANNER": "https://cars24aus.app.link/RPyRp5O8iKb"
};
const Header = ({
    // showSidebar,
    setShowSidebar,
    showHeader,
    showCallIcon,
    showWhiteMenu,
    showWhiteCall,
    showBlueMenu,
    showEmailIcon,
    showWhiteWishlist,
    showBlueWishlist,
    showBoxShadow = false,
    isLoggedIn,
    history,
    openLoginPopupConnect,
    setOnLoginSuccessCallback,
    wishlistedCarsList = [],
    setLoginTypeConnect,
    allowBack,
    webPriceDropMsite
}) => {
    const totalWishlistedCars = wishlistedCarsList.length;
    // const financePath = `/${ROUTE_CONSTANTS.FINANCEYOURCAR}`;
    const  [closedTdStrip, setCloseTdStrip] = useState(true);
    const {isListingPage} = currentPathObject() || {};
    const showClpHeaderVariant = isListingPage && (true || (closedTdStrip));
    const showClpRevampVariant =  false;
    const webClpPriceDropVariant = getAbExpirementVariant(webPriceDropMsite, EXPERIMENT_TYPE.VARIANT_B);
    const queryParams = useQueryParams();

    useEffect(() => {
        const closedTestDriveNudge = getItemFromSession(SESSION_KEYS.CLOSED_TEST_DRIVE_STRIP_NUDGE);
        if (closedTestDriveNudge) {
            setCloseTdStrip(true);
        } else {
            setCloseTdStrip(false);
        }
    }, []);

    const onLogoClick = async() => {
        await yieldToMain();
        history.push(RoutesConstant.HOME[0]);
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HEADER_LOGO);
    };
    const onClickMenuIcon = async (e) => {
        e.preventDefault();
        setShowSidebar(true);
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HAMBURGER_MENU_OPEN);
    };

    const onEmailClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HEADER_EMAIL_SELECTED);
    };

    const onPhoneClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HEADER_PHONE_SELECTED);
    };

    const navigateToWishlist = async () => {
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HEADER_WISHLIST_OPEN);
        history.push("/wishlist/");
    };

    const onWishlistClick = async (e) => {
        e.preventDefault();
        await yieldToMain();
        if (isLoggedIn) {
            navigateToWishlist();
        } else {
            setLoginTypeConnect(LOGIN_TYPE.WISHLIST);
            openLoginPopupConnect();
            setOnLoginSuccessCallback(navigateToWishlist);

            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LOGIN_INITIATED, {eventLabel: LOGIN_SOURCE.WISHLIST});

        }
        await yieldToMain();

    };

    const showMenuBar = () => {
        if (isListingPage && !showClpRevampVariant && (showBlueMenu || showWhiteMenu)) { return true; } else if (!isListingPage && (showBlueMenu || showWhiteMenu)) { return true; } else return false;
    };

    const handleBack = async () => {
        history.goBack();
    };

    const getClpCrmVariantStyle = () => {
        if (webClpPriceDropVariant && Object.keys(queryParams).length) {
            if (queryParams?.showClpCrmVariant?.toUpperCase() === CLP_CRM_VARIANTS.PRICE_DROP) {
                return "styles.priceDropVariantBgHeader";
            } else if (queryParams?.showClpCrmVariant?.toUpperCase() === CLP_CRM_VARIANTS.NEW_ARRIVAL) {
                return "styles.newArrivalsVariantBgHeader";
            }
            return "";
        }
        return "";
    };

    const currentClpCrmVariantBgClass = useMemo(
        () => getClpCrmVariantStyle(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryParams]
    );

    return showHeader ? (
        <React.Fragment>
            <header
                styleName={`styles.header ${
                    isListingPage && showClpHeaderVariant ? "styles.clpHeader" : ""
                } ${currentClpCrmVariantBgClass}`}
            >
                <nav
                    className="nav"
                    styleName={showBoxShadow ? "styles.navCustomShadow" : "styles.navCustom"}
                    role="navigation"
                >
                    <div styleName={"styles.logoWrapper"}>
                        {allowBack &&
                        <div onClick={handleBack}>
                            <img src={BackIcon} alt="back" width="20" height="20" />
                        </div>
                        }
                        <div styleName={isListingPage && showClpRevampVariant ? "styles.logoImg_clpRevamp" : "styles.logoImg"}>
                            <img src={isListingPage && showClpRevampVariant ? Cars24Newlogo : LogoImage} alt="CARS24" width="66" height="28" onClick={onLogoClick} />
                        </div>
                    </div>
                    <div styleName={"styles.navItems"}>
                        <div styleName={"styles.useAppWrapper"}>
                            <a  href={DOWNLOAD_APP_URL_CTA_HEADER[WEB_2_APP_BANNER]} styleName={"styles.useApp"}>
                                USE APP <span>
                                    <div class="bg">
                                        <div styleName="styles.wrapper">
                                            <div styleName="styles.arrow">
                                                <img src={Arrow} />
                                            </div>
                                            <div styleName="styles.bottom" />
                                        </div>
                                    </div>
                                </span>
                            </a>
                        </div>
                        {showWhiteCall && (
                            <a href={`tel:${HELPLINE_NUMBER_AU.value}`} onClick={onPhoneClick}>
                                <LazyImage src={CallIconWhite} alt="Call" />
                            </a>
                        )}
                        {showCallIcon && (
                            <a href={`tel:${HELPLINE_NUMBER_AU.value}`} onClick={onPhoneClick}>
                                <LazyImage src={CallIcon} alt="Call" />
                            </a>
                        )}
                        {showEmailIcon && (
                            <a onClick={onEmailClick} href={`mailto:${SUPPORT_EMAIL}`}>
                                <LazyImage src={EmailIcon} alt="Email" />
                            </a>
                        )}

                        {showWhiteWishlist && (
                            <a onClick={onWishlistClick} href="/wishlist/">
                                <img src={WishlistWhite} alt="Wishlist" width="40" height="40" />
                            </a>
                        )}
                        {showBlueWishlist && (
                            <a onClick={onWishlistClick} href="/wishlist/">
                                <img src={WishlistBlue} alt="Wishlist" width="40" height="40" />
                                <p styleName={"styles.totalWishListedCars"}>{totalWishlistedCars}</p>
                            </a>
                        )}
                        {showMenuBar() && (
                            <Link styleName={"styles.hamburger"} onClick={onClickMenuIcon} to="#">
                                <img src={Hamburger} alt="Menu" />
                            </Link>
                        )}
                    </div>
                </nav>
            </header>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

Header.propTypes = {
    showHeader: PropTypes.bool,
    fixedHeader: PropTypes.bool,
    showCallIcon: PropTypes.bool,
    showWhiteMenu: PropTypes.bool,
    showBlueMenu: PropTypes.bool,
    showEmailIcon: PropTypes.bool,
    showWhiteWishlist: PropTypes.bool,
    showBlueWishlist: PropTypes.bool,
    openLoginPopupConnect: PropTypes.func,
    setOnLoginSuccessCallback: PropTypes.func,
    history: PropTypes.any,
    isLoggedIn: PropTypes.bool,
    showBoxShadow: PropTypes.bool,
    showWhiteCall: PropTypes.bool,
    logoUrl: PropTypes.string,
    logoURL: PropTypes.string,
    showSidebar: PropTypes.bool,
    setShowSidebar: PropTypes.func,
    wishlistedCarsList: PropTypes.array,
    setLoginTypeConnect: PropTypes.func,
    allowBack: PropTypes.bool,
    webPriceDropMsite: PropTypes.object
};

export default withLogin(withRouter(Header));
