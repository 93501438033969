import { MOBILE_OS } from "../../constants/app-constants";

const getMobileOperatingSystem = () => {
    if (window && window.navigator) {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return null;
        }

        if (/android/i.test(userAgent)) {
            return MOBILE_OS.ANDROID;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return MOBILE_OS.IOS;
        }

        return null;
    }
    return null;
};
export default getMobileOperatingSystem;
