
import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    currentStep: {},
    maxStep: {},
    bookingSlots: [],
    isFetchingSlots: false,
    selectedSlotData: {
        date: "",
        key: ""
    },
    rejectionReasonInfo: {
        show: false,
        type: null
    },
    rescheduleFlag: false,
    formData: {},
    inspectionDetails: {}
};

const setMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {...state, maxStep};
};

const setFormData = (state = INITIAL_STATE, {formData}) => {
    return {
        ...state,
        formData
    };
};

const setCurrentStep = (state = INITIAL_STATE, {currentStep}) => {
    return {...state, currentStep};
};

const setSelectedSlot = (state = INITIAL_STATE, {selectedSlotData}) => {
    return {...state, selectedSlotData};
};

const setHardRejection = (state = INITIAL_STATE, {rejectionReasonInfo}) => {
    return {...state, rejectionReasonInfo};
};

const setRecheduleflag = (state = INITIAL_STATE, {rescheduleFlag}) => {
    return {...state, rescheduleFlag};
};

const  fetchSlotsRequest = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isFetchingSlots: isLoading,
        bookingSlots: []
    };
};

const  fetchSlotsSuccess = (state = INITIAL_STATE, {bookingSlots}) => {
    return {
        ...state,
        isFetchingSlots: false,
        bookingSlots
    };
};

const  fetchSlotsError = (state = INITIAL_STATE, {isLoading = false}) => {
    return {
        ...state,
        isFetchingSlots: isLoading,
        bookingSlots: []
    };
};

const setInspectionDetails = (state = INITIAL_STATE, {inspectionDetails}) => {
    return {
        ...state,
        inspectionDetails
    };
};

export const HANDLERS = {
    [Types.SET_MAX_STEP]: setMaxStep,
    [Types.SET_CURRENT_STEP]: setCurrentStep,
    [Types.FETCH_SLOTS_REQUEST]: fetchSlotsRequest,
    [Types.FETCH_SLOTS_SUCCESS]: fetchSlotsSuccess,
    [Types.FETCH_SLOTS_FAILURE]: fetchSlotsError,
    [Types.SET_SELECTED_SLOT]: setSelectedSlot,
    [Types.SET_INSPECTION_DETAILS]: setInspectionDetails,
    [Types.SET_HARD_REJECTION]: setHardRejection,
    [Types.SET_FORM_DATA]: setFormData,
    [Types.SET_RECHEDULE_FLAG]: setRecheduleflag
};

export default createReducer(INITIAL_STATE, HANDLERS);
