/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable new-cap */
import styles from "./styles.css";
//import "@adyen/adyen-web/dist/adyen.css";
import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
// import AdyenCheckout from "@adyen/adyen-web";
import { useHistory } from "react-router";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { Helmet } from "react-helmet";
import formQueryParams from "../../../utils/helpers/form-query-params";
import { adyenStyles } from "./adyen-styles";
// import { appUrl } from "../../../constants/url-constants";
import isWebView from "../../../utils/helpers/is-web-view";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { NUMBER } from "../../../constants/app-constants";
import CarAlreadyReservedModal from "../car-already-reserved-modal";
import { PAYMENT_MODES } from "../../../constants/checkout-constants";
const AdyenCheckout = loadable.lib(() => import("@adyen/adyen-web"));

const configuration = {
    environment: process.env.ADYEN_ENV,
    clientKey: process.env.ADYEN_CLIENT_KEY,
    analytics: {
        enabled: true // Set to false to not send analytics data to Adyen.
    },
    paymentMethodsConfiguration: {
        card: {
            name: "Debit/Credit Card",
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: false
        }
    }
};

const instantPaymentTypes = ["applepay", "googlepay"];

const GET_PAYMENT_STATUS = {
    Authorised: "SUCCESS",
    Pending: "FAILED",
    Received: "FAILED",
    Refused: "FAILED"
};

// eslint-disable-next-line max-statements
const AdyenComponent = ({
    paymentConfig,
    sessionData,
    verifyReservedStatusConnect
}) => {
    const paymentContainer = useRef(null);
    const type = "dropin";
    const history = useHistory();
    const { location } = history;
    const queryParams = parseLocationSearchParams(location.search);
    const { id, sessionData: sessionDataUrl, cardType} = queryParams;

    const { successUrl, failureUrl } = paymentConfig || {};
    const cardTypeNames = {
        DEBIT: "DEBIT CARD",
        CREDIT: "CREDIT CARD"
    };

    if (cardType in cardTypeNames) {
        configuration.paymentMethodsConfiguration.card.name = cardTypeNames[cardType];
    }

    const getRedirectUrl = useCallback((resultCode, params) => {
        const query = formQueryParams(params);
        if (isWebView()) {
            const customParams = {status: GET_PAYMENT_STATUS[resultCode]};
            return `${location.pathname}${formQueryParams({...queryParams, ...customParams})}`;
            // return `${appUrl()}/my-bookings/${appointmentId}/verify/user-id${formQueryParams(customParams)}`;
        } else {
            switch (resultCode) {
            case "Authorised":
                return `${successUrl}${query}`;
            case "Pending":
            case "Received":
                return `${failureUrl}${query}`;
            case "Refused":
                return `${failureUrl}${query}`;
            default:
                return `${failureUrl}${query}`;
            }
        }
    }, [failureUrl, location.pathname, queryParams, successUrl]);

    const createCheckout = async (adyenInstance) => {
        if (!id || !adyenInstance || typeof window === "undefined" || !window?.document) return;
        const checkout = await adyenInstance({
            ...configuration,
            session: { id, sessionData: sessionData || sessionDataUrl || paymentConfig?.sessionData, countryCode: "AU" },
            onPaymentCompleted: async (response) => {
                const absoluteUrl = getRedirectUrl(response.resultCode);
                try {
                    const reservedStatusResponse = await verifyReservedStatusConnect({ prePayment: false, paymentMethod: PAYMENT_MODES.CARD });
                    if (reservedStatusResponse?.data?.code === NUMBER.TWO_HUNDRED) {
                        window.location.href = absoluteUrl;
                    }
                } catch (error) {
                    // window.location.href = absoluteUrl;
                }

            },
            onError: async (error) => {
                const absoluteUrl = getRedirectUrl("Refused", { message: error.message });
                window.location.href = absoluteUrl;
                // try {
                //     await verifyReservedStatusConnect({ prePayment: false });
                // } catch (err) {
                //     if (err?.status === NUMBER.FOUR_HUNDRED_FOUR) {
                //         window.location.href = absoluteUrl;
                //     }
                // }
            }
        });
        if (paymentContainer.current) {
            checkout.create(type, {instantPaymentTypes}).mount(paymentContainer.current);
        }
    };
    const adyenFallback = () => {
        return (<React.Fragment/>);
    };

    return (
        <React.Fragment>
            <AdyenCheckout fallback={adyenFallback} >
                { (data) =>  {
                    const adyenInstance = data?.default ? data.default : data;
                    createCheckout(adyenInstance);
                    return <React.Fragment/>;
                }}
            </AdyenCheckout>
            <Helmet>
                <style>
                    {`${adyenStyles}`}
                </style>
            </Helmet>
            <div styleName={"styles.paymentContainers"}>
                <div ref={paymentContainer} className="payment" />
            </div>
            <CarAlreadyReservedModal />
        </React.Fragment>
    );
};

AdyenComponent.propTypes = {
    paymentConfig: PropTypes.object,
    sessionData: PropTypes.string,
    verifyReservedStatusConnect: PropTypes.func
};

const mapStateToProps = ({checkout: {sessionData} }) => {
    return {
        sessionData
    };
};

export default connect(mapStateToProps, null)(AdyenComponent);

