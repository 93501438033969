/* eslint-disable no-console */
import { PAYMENT_STATUS } from "../../constants/checkout-constants";
import { checkoutRoutes } from "../../constants/checkout-routes";
import { getCheckoutPageURL } from "./get-detail-page-url";
import isWebView from "./is-web-view";

export const paymentRedirectUrls = (content, defaultParams, appParams) => {
    const  { absoluteURL: successUrl} = getCheckoutPageURL(content, checkoutRoutes.summary.route, {status: PAYMENT_STATUS.SUCCESS, ...appParams});
    const  { absoluteURL: failureUrl} = getCheckoutPageURL(content, checkoutRoutes.summary.route, {status: PAYMENT_STATUS.FAILED, ...appParams});
    const  { absoluteURL: cancelUrl} = getCheckoutPageURL(content, checkoutRoutes.summary.route, {status: PAYMENT_STATUS.CANCEL, ...appParams});
    window.console.log(
        successUrl,
        failureUrl,
        cancelUrl,
        defaultParams);
    if (isWebView()) {
        return ({
            successUrl,
            failureUrl,
            cancelUrl
        });
    }
    return defaultParams;
};

