import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    content: [],
    quotedPriceContent: [],
    bodyTypeContent: [],
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null
    },
    totalCars: 0,
    totalPages: null,
    isSSR: false,
    page: 0,
    isLoading: false,
    error: null,
    config: {},
    shouldReloadList: false,
    saleConfig: {
        isSSR: false,
        isLoading: false,
        error: false,
        data: {}

    },
    buyWithConfidence: [],
    personalised: false,
    logoURL: "/",
    totalAvailableCars: 0,
    showFinanceCentricAnimation: false,
    cmsConfig: null,
    isFetchingCmsConfig: false,
    cmsConfigError: null
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data, params, contentKey }) => {
    return {
        ...state,
        [contentKey ? contentKey : "content"]: [...data.results],
        metaContent: { ...data.metaContent },
        totalCars: data.total,
        totalPages: data.totalPages,
        isLoading: false,
        personalised: data.personalised,
        isSSR: false,
        error: null,
        buyWithConfidence: data.buyWithConfidence,
        ...((params && params.page >= 0) && { page: params.page }),
        config: data.config
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isLoading: false, isSSR: false };
};

export const fetchConfigInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        saleConfig: { ...state.saleConfig, isLoading: false, data, error: false}
    };
};

export const fetchConfigFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        saleConfig: { ...state.saleConfig, isLoading: false, error: true }
    };
};

export const setLogoUrl = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        logoURL: data
    };
};

const setTotalAvailableCarsSuccess = (state = INITIAL_STATE, {data = {}}) => {
    return {
        ...state,
        totalAvailableCars: data.totalCount
    };
};

const setTotalAvailableCarsFailed = (state = INITIAL_STATE) => {
    return {
        ...state,
        totalAvailableCars: 0
    };
};

const updateFinanceCentricAnimation = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        showFinanceCentricAnimation: data
    };
};

const fetchCmsConfigInit = (state = INITIAL_STATE) => {
    return { ...state, isFetchingCmsConfig: true };
};

const fetchCmsConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, cmsConfig: data, isFetchingCmsConfig: false };
};
const fetchCmsConfigFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetchingCmsConfig: false };
};
export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.FETCH_CONFIG]: fetchConfigInit,
    [Types.FETCH_CONFIG_SUCCESS]: fetchConfigSuccess,
    [Types.FETCH_CONFIG_FAILURE]: fetchConfigFailure,
    [Types.SET_LOGO_URL]: setLogoUrl,
    [Types.FETCH_TOTAL_CAR_SUCCESS]: setTotalAvailableCarsSuccess,
    [Types.FETCH_TOTAL_CAR_FAILURE]: setTotalAvailableCarsFailed,
    [Types.UPDATE_FINANCE_CENTRIC_ANIMATION]: updateFinanceCentricAnimation,
    [Types.FETCH_CMS_CONFIG_INIT]: fetchCmsConfigInit,
    [Types.FETCH_CMS_CONFIG_SUCCESS]: fetchCmsConfigSuccess,
    [Types.FETCH_CMS_CONFIG_FAILURE]: fetchCmsConfigFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
