import React from "react";
import { useHistory } from "react-router-dom";
import parseLocationSearchParams from "../utils/helpers/parse-location-search-params";

const useQueryParams = () => {
    const history = useHistory();
    const { location: { search } } = history;
    return React.useMemo(() => {
        return parseLocationSearchParams(search);
    }, [search]);
};

export default useQueryParams;
