import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCarDetails } from "../car-details/actions";

import { getOrderDetail, verifyReservedStatus } from "../checkout-info/actions";
import AdyenPay from "./component";

const mapStateToProps = ({
    paymentConfig,
    carDetails: { content,
        config },
    checkout: { currentDeliveryStep, checkoutCallbackRequestLoading,
        callbackDetails, order, sessionData },
    user: {
        secureToken
    }
}) => ({
    paymentConfig,
    content,
    imageConfig: config,
    currentDeliveryStep,
    checkoutCallbackRequestLoading,
    callbackDetails,
    order,
    secureToken,
    sessionData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrderDetailConnect: getOrderDetail,
    fetchCarDetailsConnect: fetchCarDetails,
    verifyReservedStatusConnect: verifyReservedStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdyenPay);
