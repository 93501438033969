/* eslint-disable max-len */
import { combineReducers } from "redux";
import { resettableReducer } from "reduxsauce";
import { resetReducerKeys } from "../constants/app-constants";
import userReducer from "../components/au.configuration/reducers";
import carListReducer from "../components/au.mobile/car-listing/reducers";
import filterReducer from "../components/au.mobile/filters/reducers";
import carDetailsReducer from "../components/au.mobile/car-details/reducers";
import citiesReducer from "../components/au.mobile/location-picker/reducers";
import myWishlist from "../components/au.mobile/my-wishlist/reducers";
import config from "../components/au.mobile/config-ssr/reducers";
import toastMessageReducer from "../components/shared/toast-message/reducers";
import myBookingsReducer from "../components/au.mobile/my-bookings/reducers";
import checkoutReducer from "../components/au.mobile/checkout-info/reducers";
import deliveryInfoReducer from "../components/au.mobile/delivery-info/reducers";
import alertFiltersReducer from "../components/au.mobile/alert-filters/reducers";
import homeCarList from "../components/au.mobile/home/reducers";
import sitemapDataList from "../components/au.mobile/sitemap-info/reducers";
import headerReducers from "../components/au.mobile/header/reducers";
import nudgeAlertList from "../components/au.mobile/nudge-cards/reducers";
import preApprovalLoan from "../components/au.mobile/pre-approval-financing-flow/pre-approval-loan-wrapper/reducers";
import auc2bTradein from "../au-c2b/au.mobile/components/trade-in-wrapper/reducers";
import bookInspection from "../au-c2b/au.mobile/components/book-inspection-wrapper/reducers";
import abExperimentReducers from "../components/shared/ab-experiment/reducers";
import tradeInReducer from "../components/au.mobile/trade-in-your-car-wrapper/reducers";
import userRegoVinReducer from "../au-c2b/au.mobile/components/trade-in-carmake/reducers";
import financePostPaymentReducer from "../components/au.mobile/finance-post-payment-loan-application/reducer";
import bookedTaskListReducer from "../components/au.mobile/booked-tasks/reducer";
import supportWidgetReducer from "../components/au.mobile/support-widget/reducer";
import guestLoginReducer from "../components/au.mobile/guest-login/reducer";
import paymentConfigurationReducer from "../components/au.mobile/adyen-payment/reducer";
import addOnsReducer from "../components/au.mobile/add-ons/reducers";
import clpDataReducer from "../components/au.mobile/car-list-faq/reducers";
import financeLandingReducer from "../components/au.mobile/finance-landing-page/reducers";
import testDriveReducer from "../components/au.mobile/test-drive-v2/reducers";
import carValuaionCalculatorReducer from "../au-c2b/au.mobile/container/get-valuation/reducer";
import carLoanCalculatorClpReducer from "../components/au.mobile/car-loan-calculator-clp/reducer";
import priceCommunicationReducer from "../au-c2b/au.mobile/container/price-communication-to-customer/reducer";

import roadWorthyCertificateReducer from "../components/au.mobile/rwc-landing-page/reducers";
// listen for the action type of 'RESET', you can change this.
const resettableUserReducer = resettableReducer(resetReducerKeys.RESET_USER);
const resettableCarList = resettableReducer(resetReducerKeys.RESET_CAR_LIST);
const resettableCarDetailsReducer = resettableReducer(resetReducerKeys.RESET_CAR_DETAILS);
const resettableFiltersReducer = resettableReducer(resetReducerKeys.RESET_FILTERS);
const resettableCities = resettableReducer(resetReducerKeys.RESET_CITIES);
const resettableMyWishlist = resettableReducer(resetReducerKeys.RESET_WISHLISTED_CARS);
const resettableConfig = resettableReducer(resetReducerKeys.RESET_CONFIG);
const resettableToastMessageReducer = resettableReducer(resetReducerKeys.RESET_TOAST_MESSAGE);
const resettableMyBookingsReducer = resettableReducer(resetReducerKeys.RESET_MY_BOOKINGS);
const resettableCheckoutReducer = resettableReducer(resetReducerKeys.RESET_CHECKOUT);
const resettableDeliveryInfoReducer = resettableReducer(resetReducerKeys.RESET_DELIVERY_INFO);
const resettableAlertFiltersReducer = resettableReducer(resetReducerKeys.RESET_FILTERS);
const resettableHomeCarList = resettableReducer(resetReducerKeys.RESET_HOME_LIST);
const resettableSitemapDataList = resettableReducer(resetReducerKeys.RESET_SITEMAP_LIST);
const resettableNudgeList = resettableReducer(resetReducerKeys.RESET_NUDGE_LIST);
const resettableHeaderReducer = resettableReducer(resetReducerKeys.RESET_HEADER);
const resettableTradein = resettableReducer(resetReducerKeys.RESET_AUC2B_TRADE_IN);
const resettableBookInspection = resettableReducer(resetReducerKeys.RESET_BOOK_INSPECTION);
const resettablePreApprovalLoan = resettableReducer(resetReducerKeys.PRE_APPROVAL_LOAN);
const resettableAbExperimentsReducer = resettableReducer(resetReducerKeys.RESET_AB_EXPERIMENTS);
const resettableTradeInReducer = resettableReducer(resetReducerKeys.RESET_TRADE_IN);
const resettableFinancePostPaymentReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_POST_PAYMENT_REDUCER);
const resettableBookedTasksReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_POST_PAYMENT_REDUCER);
const resettableUserRegoVinReducer = resettableReducer(resetReducerKeys.RESET_NO_KBB_TRADE_IN);
const resettableSupportWidgetReducer = resettableReducer(resetReducerKeys.RESET_SUPPORT_WIDGET);
const resettableGuestLoginReducer = resettableReducer(resetReducerKeys.RESET_GUEST_LOGIN);
const resettablePaymentConfigReducer = resettableReducer(resetReducerKeys.RESET_PAYMENT_CONFIG);
const resettableAddOns = resettableReducer(resetReducerKeys.RESET_ADDONS);
const resettableClpData = resettableReducer(resetReducerKeys.RESET_CLP_DATA);
const resettablefinanceLandingReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_LANDING_PAGE);
const resettableTestDriveReducer = resettableReducer(resetReducerKeys.RESET_TEST_DRIVE);
const resettableCarValuationCalculator = resettableReducer(resetReducerKeys.RESET_CAR_VALUATION_CALCULATOR);
const resettableCarLoanCalculatorCLP = resettableReducer(resetReducerKeys.RESET_CAR_LOAN_CALCULATOR_CLP);
const resettablePriceCommunicationReducer = resettableReducer(resetReducerKeys.RESET_PRICE_COMMUNICATION_PAGE);
const resettableroadWorthyCertificate = resettableReducer(resetReducerKeys.RESET_ROADWORHTY_CERTIFICATE);

export default combineReducers({
    config: resettableConfig(config),
    cities: resettableCities(citiesReducer),
    user: resettableUserReducer(userReducer),
    carListing: resettableCarList(carListReducer),
    carLoanCalculatorClp: resettableCarLoanCalculatorCLP(carLoanCalculatorClpReducer),
    carDetails: resettableCarDetailsReducer(carDetailsReducer),
    filters: resettableFiltersReducer(filterReducer),
    myWishlist: resettableMyWishlist(myWishlist),
    toast: resettableToastMessageReducer(toastMessageReducer),
    myBookings: resettableMyBookingsReducer(myBookingsReducer),
    header: resettableHeaderReducer(headerReducers),
    checkout: resettableCheckoutReducer(checkoutReducer),
    deliveryInfo: resettableDeliveryInfoReducer(deliveryInfoReducer),
    alertFilters: resettableAlertFiltersReducer(alertFiltersReducer),
    homeCarList: resettableHomeCarList(homeCarList),
    sitemapDataList: resettableSitemapDataList(sitemapDataList),
    nudgeAlertList: resettableNudgeList(nudgeAlertList),
    auc2bTradein: resettableTradein(auc2bTradein),
    bookInspection: resettableBookInspection(bookInspection),
    preApprovalLoan: resettablePreApprovalLoan(preApprovalLoan),
    abExperiment: resettableAbExperimentsReducer(abExperimentReducers),
    tradeIn: resettableTradeInReducer(tradeInReducer),
    userRegoVin: resettableUserRegoVinReducer(userRegoVinReducer),
    financePostPayment: resettableFinancePostPaymentReducer(financePostPaymentReducer),
    bookedTasksList: resettableBookedTasksReducer(bookedTaskListReducer),
    supportWidget: resettableSupportWidgetReducer(supportWidgetReducer),
    guestLogin: resettableGuestLoginReducer(guestLoginReducer),
    paymentConfig: resettablePaymentConfigReducer(paymentConfigurationReducer),
    addOns: resettableAddOns(addOnsReducer),
    clpData: resettableClpData(clpDataReducer),
    financeLandingData: resettablefinanceLandingReducer(financeLandingReducer),
    testDrive: resettableTestDriveReducer(testDriveReducer),
    carValuationCalculator: resettableCarValuationCalculator(carValuaionCalculatorReducer),
    priceCommunication: resettablePriceCommunicationReducer(priceCommunicationReducer),
    roadWorthyCertificate: resettableroadWorthyCertificate(roadWorthyCertificateReducer)
});
