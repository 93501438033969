import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuSidebar from "./component";
import { resetWishlist } from "../my-wishlist/actions";
import { logout, openLoginPopup, setAffiliateSource, setLoginType } from "../../au.configuration/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import { clearAllFilters } from "../filters/actions";
import { setTradeInToggleSelected } from "../../au.mobile/trade-in-your-car-wrapper/actions";

const mapStateToProps = ({
    user: { profileName, seoMenu, email, gaId, isLoggedIn},
    cities: { selectedCity: { code: selectedCityCode, name: selectedCityName } = {} },
    nudgeAlertList: {nudgeList},
    carListing: {
        metaContent
    },
    preApprovalLoan: {preApproved},
    tradeIn: {userTradeInData}

}) => ({
    profileName,
    selectedCityCode,
    selectedCityName,
    seoMenu,
    nudgeList,
    metaContent,
    preApproved,
    userTradeInData,
    email,
    isLoggedIn,
    gaId
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetWishlistConnect: resetWishlist,
            logoutConnect: logout,
            reloadCarListConnect: reloadCarList,
            setLoginTypeConnect: setLoginType,
            resetCarListConnect: resetCarList,
            clearAllFiltersConnect: clearAllFilters,
            openLoginPopupConnect: openLoginPopup,
            setAffiliateSourceConnect: setAffiliateSource,
            setTradeInToggleSelectedConnect: setTradeInToggleSelected
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MenuSidebar);
