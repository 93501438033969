import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import Footer from "./component";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        seoMenu
    }
}) => ({
    isLoggedIn,
    seoMenu
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    resetCarListConnect: resetCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
