import { createReducer } from "reduxsauce";
import Types from "./types";

const DEFAULT_PAGE_SIZE = 20;

export const INITIAL_STATE = {
    wishlistedCars: {
        cars: [],
        // pageable: {},
        totalElements: null,
        totalPages: null,
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: 1
    },
    myWishlistError: null,
    isFetching: false,
    isAdding: false,
    selectedCarId: "",
    showMyWishlist: false,
    wishlistedCarsList: [],
    config: {}
};

export const fetchWishlistedCarsInitiated = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true};
};

export const fetchWishlistedCarsSuccess = (state = INITIAL_STATE, { data, params }) => {
    const newCars = data.shortlistedVehicles || [];
    return {
        ...state,
        wishlistedCars: {
            cars: params.page === 1 ? newCars : [...state.wishlistedCars.cars, ...newCars],
            // pageable: data.pageable,
            totalElements: data.total,
            totalPages: Math.ceil(data.total / DEFAULT_PAGE_SIZE),
            currentPage: params.page,
            pageSize: DEFAULT_PAGE_SIZE
        },
        isFetching: false,
        myWishlistError: null,
        config: data.config
    };
};

export const fetchWishlistedCarsFailure = (state = INITIAL_STATE, {error}) => {
    return { ...state, myWishlistError: error, isFetching: false };
};

export const updateSelectedCar = (state = INITIAL_STATE, {carId}) => {
    return { ...state, selectedCarId: carId};
};

export const showWishlistPopup = (state = INITIAL_STATE, { showMyWishlist }) => {
    return {
        ...state,
        showMyWishlist,
        wishlistedCars: { ...INITIAL_STATE.wishlistedCars },
        selectedCarId: INITIAL_STATE.selectedCarId
    };
};

export const fetchWishlistedCarsListInitiated = (state = INITIAL_STATE) => {
    return { ...state };
};

export const fetchWishlistedCarsListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        wishlistedCarsList: data
    };
};

export const fetchWishlistedCarsListFailure = (state = INITIAL_STATE) => {
    return { ...state };
};

export const addOrRemoveFromWishlistInit = (state = INITIAL_STATE, { vehicleId }) => {
    return {
        ...state,
        isAdding: true,
        selectedCarId: vehicleId
    };
};
export const addOrRemoveFromWishlistSuccess = (state = INITIAL_STATE, { data: { shortListed, vehicleId } }) => {
    const updatedCarList = shortListed ? [...state.wishlistedCars.cars] : [...state.wishlistedCars.cars].filter(c => c.appointmentId !== vehicleId);
    return {
        ...state,
        wishlistedCarsList: shortListed ?
            [...state.wishlistedCarsList, vehicleId] :
            state.wishlistedCarsList.filter(c => c !== vehicleId),
        wishlistedCars: {
            ...state.wishlistedCars,
            cars: updatedCarList,
            totalElements: updatedCarList.length
        },
        selectedCarId: INITIAL_STATE.selectedCarId,
        isAdding: false
    };
};

export const resetWishlist = () => {
    return { ...INITIAL_STATE };
};

export const HANDLERS = {
    [Types.FETCH_WISHLISTED_CARS_INIT]: fetchWishlistedCarsInitiated,
    [Types.FETCH_WISHLISTED_CARS_SUCCESS]: fetchWishlistedCarsSuccess,
    [Types.FETCH_WISHLISTED_CARS_FAILURE]: fetchWishlistedCarsFailure,
    [Types.UPDATE_SELECTED_CAR]: updateSelectedCar,
    [Types.SHOW_WISHLIST_POPUP]: showWishlistPopup,
    [Types.FETCH_WISHLISTED_CARS_LIST_INIT]: fetchWishlistedCarsListInitiated,
    [Types.FETCH_WISHLISTED_CARS_LIST_SUCCESS]: fetchWishlistedCarsListSuccess,
    [Types.FETCH_WISHLISTED_CARS_LIST_FAILURE]: fetchWishlistedCarsListFailure,
    [Types.ADD_OR_REMOVE_FROM_WISHLIST_INIT]: addOrRemoveFromWishlistInit,
    [Types.ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS]: addOrRemoveFromWishlistSuccess,
    [Types.RESET_WISHLIST]: resetWishlist
};

export default createReducer(INITIAL_STATE, HANDLERS);
