import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import AUConfiguration from "./components/au.configuration";
import MobileRoot from "./components/au.mobile/root";
import PageLoader from "./components/shared/page-loader";
// import CarListing from "./pages/au.mobile/car-listing";
// import CarDetail from "./pages/au.mobile/car-detail";
// import Checkout from "./pages/au.mobile/checkout";

import loadable from "@loadable/component";
const Home  = loadable(() => import("./pages/au.mobile/home"), { ssr: true, fallback: <PageLoader/> });

const ExternalLinkPage = loadable(() => import("./pages/au.mobile/external-link-page"));
// import C2bLanding from "./pages/au.mobile/c2b-landing";
const AppleOAuthCallback = loadable(() => import("./pages/au.mobile/apple-oauth-callback"));
const MobileAppPayment = loadable(() => import(/* webpackPreload: true */ "./pages/au.mobile/mobile-app-payment"));
const CancellationRequest = loadable(() => import("./pages/au.mobile/cancellation-request"));

const CarListing = loadable(() => import("./pages/au.mobile/car-listing"), { ssr: true, fallback: <PageLoader/> });
const CarDetail = loadable(() => import("./pages/au.mobile/car-detail"), { fallback: <PageLoader/>});
const Checkout = loadable(() => import("./pages/au.mobile/checkout"),  { fallback: <PageLoader/> });
const TradeInMakeModelFlow = loadable(() => import("./pages/au.mobile/trade-in-make-model-flow"), { fallback: <PageLoader/> });
const WishlistPage = loadable(() => import("./pages/au.mobile/wishlist"), { fallback: <PageLoader/> });
const MyBookings = loadable(() => import("./pages/au.mobile/my-bookings"), { fallback: <PageLoader/> });
const MySellOrders = loadable(() => import("./pages/au.mobile/my-sell-orders"));
const ThreeHundredPointInspection = loadable(() => import("./pages/au.mobile/three-hundred-point-inspection"), { fallback: <PageLoader/> });
const OurCarPhotos = loadable(() => import("./pages/au.mobile/our-car-photos"), { fallback: <PageLoader/> });
const CarQuality = loadable(() => import("./pages/au.mobile/car-quality"), { fallback: <PageLoader/> });
const TermsOfUse = loadable(() => import("./pages/au.mobile/terms-of-use"), { fallback: <PageLoader/> });
const GetPreApproval = loadable(() => import("./pages/au.mobile/get-pre-approval"), { fallback: <PageLoader/> });
const TermsOfPurchase = loadable(() => import("./pages/au.mobile/terms-of-purchase"), { fallback: <PageLoader/> });
const TermsOfSale = loadable(() => import("./pages/au.mobile/terms-of-sale"), { fallback: <PageLoader/> });
const PrivacyPolicy = loadable(() => import("./pages/au.mobile/privacy-policy"), { fallback: <PageLoader/> });
const PrivacyPolicyDisclosureStatement = loadable(() => import("./pages/au.mobile/privacy-policy-statement"), { fallback: <PageLoader/> });
const Finance = loadable(() => import("./pages/au.mobile/finance"), { fallback: <PageLoader/> });
//const TradeIn = loadable(() => import("./pages/au.mobile/trade-in"));
const TradeInYourCarPage = loadable(() => import("./pages/au.mobile/trade-in-your-car"), { fallback: <PageLoader/> });
// const C2bLanding = loadable(() => import("./pages/au.mobile/c2b-landing"));
const AboutUs = loadable(() => import("./pages/au.mobile/about-us"), { fallback: <PageLoader/> });
const CareerRevamp = loadable(() => import("./pages/au.mobile/career-revamp"), { fallback: <PageLoader/> });
const ContactUs = loadable(() => import("./pages/au.mobile/contact-us"), { fallback: <PageLoader/> });
const NotFound = loadable(() => import("./pages/au.mobile/not-found"), { fallback: <PageLoader/> });
const CreateAlert = loadable(() => import("./pages/au.mobile/create-alert"), { fallback: <PageLoader/> });
const SiteMap = loadable(() => import("./pages/au.mobile/sitemap"));
const OrderTasks = loadable(() => import("./pages/au.mobile/order-tasks-wrapper"), { fallback: <PageLoader/> });
const WarrantySixMonth = loadable(() => import("./pages/au.mobile/warranty-page-six-month"), { fallback: <PageLoader/> });
const Warranty = loadable(() => import("./pages/au.mobile/warranty-page"), { fallback: <PageLoader/> });

const MyAccount = loadable(() => import("./pages/au.mobile/my-account"), { fallback: <PageLoader/> });
const MyAccountSellYourCar = loadable(() => import("./pages/au.mobile/my-account-sell-your-car"), { fallback: <PageLoader/> });

const MyAccountFinancing = loadable(() => import("./pages/au.mobile/my-account-financing"), { fallback: <PageLoader/> });
const MyAccountTradeIn = loadable(() => import("./pages/au.mobile/my-account-trade-in"), { fallback: <PageLoader/> });
const C2bTradein = loadable(() => import("./au-c2b/au.mobile/container/checkout"), { fallback: <PageLoader/> });
const GetValuationPage = loadable(() => import("./au-c2b/au.mobile/container/get-valuation"), { fallback: <PageLoader/> });
const CarValuationPage = loadable(() => import("./au-c2b/au.mobile/container/car-valuation"), { fallback: <PageLoader/> });
const BookInspection = loadable(() => import("./au-c2b/au.mobile/container/book-inspection"), { fallback: <PageLoader/> });
const TestDrive = loadable(() => import("./components/au.mobile/test-drive-v2"), { fallback: <PageLoader/> });
const BookInspectionConfirmation = loadable(() => import("./au-c2b/au.mobile/container/book-inspection-confirmation"), { fallback: <PageLoader/> });
const MakeModelFlow = loadable(() => import("./au-c2b/au.mobile/components/make-model-flow"), { fallback: <PageLoader/> });
const MakeModelFlowForMarketPlace = loadable(() => import("./au-c2b/au.mobile/components/make-model-flow-for-marketplace"), { fallback: <PageLoader/> });
const SellCarHome = loadable(() => import("./au-c2b/au.mobile/container/home"), { fallback: <PageLoader/> });
const ReviewRevamp = loadable(() => import("./pages/au.mobile/review-revamp/component"), { fallback: <PageLoader/> });
const TestDriveCheckout = loadable(() => import("./pages/au.mobile/test-drive"), { fallback: <PageLoader/> });
const BankStatementAppWrapper = loadable(() => import("./components/shared/bank-statements-app-wrapper"));
import TestDriveSummary from "../client/components/au.mobile/test-drive-booking-summary";
import GreenId from "./components/shared/green-id";
const SearchModal = loadable(() => import(/* webpackPreload: true */ "./components/au.mobile/search-modal"), { fallback: <PageLoader/>});
const PostPaymentJourney = loadable(() => import("./pages/au.mobile/post-payment-journey"), { fallback: <PageLoader/> });
const GuestLoginVerifyEmailRevamp = loadable(() => import("./components/au.mobile/guest-login-verify-email-revamp"));
const CarGalleryRevamp = loadable(() => import("./pages/au.mobile/car-detail-gallery"), { fallback: <PageLoader/> });
import AdyenPayment from "./pages/au.mobile/adyen-payment";
import SimilarCarPage from "./components/au.mobile/similar-car-page";
const unsubscribeEmail = loadable(() => import("./components/shared/unsubscribe-email-info"), { ssr: false });
const PriceCommunicationToCustomerPage = loadable(() => import("./au-c2b/au.mobile/container/price-communication-to-customer"), { fallback: <PageLoader/> });
const RoadWorthyCertificate = loadable(() => import("./pages/au.mobile/roadworthy-certificate"), { ssr: true, fallback: <PageLoader /> });
const RoadWorthyCertificateAppointment = loadable(() => import("./pages/au.mobile/roadworthy-certificate-appointment/component"), { ssr: false, fallback: <PageLoader /> });

const Root = ({ route }) => {
    return (
        <AUConfiguration type="mobile">
            <MobileRoot>
                {renderRoutes(route.routes)}
            </MobileRoot>
        </AUConfiguration>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const routes = [
    {
        path: "/",
        component: withRouter(Root),
        init: "./init-top.au.mobile",
        routes: [
            // SSR pages

            {
                path: ["/"],
                exact: true,
                name: "au.home",
                component: Home,
                init: "./au/home.mobile"
            },
            {
                path: "/search",
                exact: true,
                name: "au.search",
                component: SearchModal,
                init: "./au/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)",
                exact: true,
                name: "au.car-details",
                component: CarDetail,
                init: "./au/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/similar-cars",
                exact: true,
                name: "au.car-details",
                component: SimilarCarPage,
                init: "./au/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/gallery",
                exact: true,
                name: "au.car-details",
                component: CarGalleryRevamp,
                init: "./au/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/checkout/trade-in-car-:stepId/",
                exact: true,
                name: "au.car-details",
                component: TradeInMakeModelFlow
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/checkout/:stepid/",
                exact: true,
                name: "au.car-details",
                //checkout current
                component: Checkout,
                init: "./au/checkout.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/test-drive/checkout/:stepid",
                exact: true,
                name: "au.car-details",
                component: TestDriveCheckout,
                init: "./au/checkout.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/book-test-drive/:stepId",
                exact: true,
                name: "au.test-drive",
                component: TestDrive
            },
            {
                path: "/buy-used-:filterQuery",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.mobile"
            },
            {
                path: "/used-cars-in-:filterQuery",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.mobile"
            },
            {
                path: "/australia-used-cars",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.mobile.static"
            },
            {
                path: "/create-alert",
                exact: true,
                component: CreateAlert,
                name: "au.create-alert"
            },
            // CSR pages

            {
                path: "/wishlist",
                exact: true,
                component: WishlistPage,
                name: "au.wishlist"
            },
            {
                path: "/300-point-inspection",
                exact: true,
                // name: "300-point-inspection",
                component: ThreeHundredPointInspection,
                hideLayoutViaUTM: false
            },
            {
                path: "/our-car-photos",
                exact: true,
                // name: "our-car-photos",
                component: OurCarPhotos
            },
            {
                path: "/thecars24way",
                exact: true,
                // name: "car-quality",
                component: CarQuality,
                hideLayoutViaUTM: false
            },
            {
                path: "/terms-of-use",
                exact: true,
                // name: "terms-of-use",
                component: TermsOfUse,
                hideLayoutViaUTM: false
            },
            {
                path: "/terms-of-purchase",
                exact: true,
                // name: "terms-of-purchase",
                component: TermsOfPurchase
            },
            {
                path: "/cars24-finance",
                exact: true,
                name: "finance",
                component: Finance,
                init: "./au/finance-landing.mobile"
            },
            {
                path: "/terms-of-sale",
                exact: true,
                component: TermsOfSale,
                hideLayoutViaUTM: false
            },
            // {
            //     path: "/au/c2b-landing",
            //     exact: true
            //     // component: C2bLanding
            // },
            {
                path: "/privacy-policy",
                exact: true,
                // name: "privacy-policy",
                component: PrivacyPolicy,
                hideLayoutViaUTM: false
            },
            {
                path: "/privacy-policy-statement",
                exact: true,
                component: PrivacyPolicyDisclosureStatement,
                hideLayoutViaUTM: false
            },
            {
                path: "/Cancellation-request",
                exact: true,
                // name: "privacy-policy",
                component: CancellationRequest
            },
            {
                path: "/about-us",
                exact: true,
                name: "about-us",
                component: AboutUs,
                hideLayoutViaUTM: false
            },
            {
                path: "/trade-in",
                exact: true,
                name: "trade-in",
                component: () => <Redirect to="/sell-your-car/" />,
                hideLayoutViaUTM: false
            },
            {
                path: "/trade-in-your-car/:stepid",
                exact: true,
                name: "trade-in-your-car",
                component: TradeInYourCarPage,
                hideLayoutViaUTM: false
            },
            {
                path: "/career",
                exact: true,
                name: "career",
                component: CareerRevamp,
                hideLayoutViaUTM: false
            },
            {
                path: "/contact-us",
                exact: true,
                name: "contact-us",
                component: ContactUs,
                hideLayoutViaUTM: false
            },
            {
                path: "/unsubscribe-email",
                exact: true,
                name: "unsubscribe-email",
                component: unsubscribeEmail
            },

            {
                path: "/my-bookings",
                exact: true,
                component: MyBookings,
                name: "au.my-bookings"
            },
            {
                path: "/reviews",
                exact: true,
                component: ReviewRevamp,
                name: "au.review-revamp"
            },
            {
                path: "/my-sell-orders",
                exact: true,
                component: MySellOrders,
                name: "au.my-sell-orders"
            },
            {
                path: "/my-bookings-:carId/:stepid",
                exact: true,
                component: OrderTasks,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/:carId/guest/verify-user",
                exact: true,
                component: GuestLoginVerifyEmailRevamp,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/:carId/verify/:stepid",
                exact: true,
                component: PostPaymentJourney,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/bank-statements/:orderId",
                exact: true,
                component: BankStatementAppWrapper,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings-:carId/test-drive/summary",
                exact: true,
                component: TestDriveSummary,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/checkout/:paymentGateway",
                exact: true,
                component: MobileAppPayment,
                name: "mobile-app-payment"
            },
            {
                path: "/sitemap",
                exact: true,
                name: "sitemap",
                component: SiteMap,
                init: "./au/sitemap.mobile"
            },
            {
                path: "/warranty",
                exact: true,
                name: "warranty",
                component: WarrantySixMonth
            },
            {
                path: "/cars24cover",
                exact: true,
                name: "warranty",
                component: Warranty
            },
            {
                path: "/oauth/apple/callback",
                exact: true,
                component: AppleOAuthCallback,
                name: "apple-login-callback"
            },
            {
                path: "/external",
                exact: true,
                component: ExternalLinkPage,
                name: "au.external-link"
            },
            {
                path: "/sell-your-car",
                exact: true,
                name: "c2b-trade",
                component: SellCarHome,
                init: "./au/sell-your-car.mobile"
            },
            {
                path: "/sell-your-car/car-valuation/:orderId",
                exact: true,
                name: "c2b-trade",
                component: CarValuationPage
            },
            {
                path: "/sell-your-car-:city",
                exact: true,
                name: "c2b-trade",
                component: SellCarHome,
                init: "./au/sell-your-car.mobile"
            },
            {
                path: "/sell-your-car/trade-in-car-make/:stepId/:orderId",
                exact: true,
                name: "c2b-trade",
                component: MakeModelFlow
            },
            {
                path: "/sell-your-car/sell-car-make/:stepId/:orderId",
                exact: true,
                name: "c2b-trade",
                component: MakeModelFlowForMarketPlace
            },
            {
                path: "/sell-your-car/car-valuation",
                exact: true,
                name: "c2b-trade",
                component: GetValuationPage,
                init: "./au/car-valuation-calculator.mobile"
            },
            {
                path: "/sell-your-car/:stepid/:orderId?",
                exact: true,
                name: "c2b-trade",
                component: C2bTradein
            },
            {
                path: "/book-inspection/:orderId/confirmation",
                exact: true,
                name: "c2b-trade",
                component: BookInspectionConfirmation
            },
            {
                path: "/book-inspection/:orderId/:stepId",
                exact: true,
                name: "c2b-trade",
                component: BookInspection
            },
            {
                path: "/my-account",
                exact: true,
                name: "au.my-account",
                component: MyAccount
            },
            {
                path: "/my-account/financing/:stepId",
                exact: true,
                name: "my-account",
                component: MyAccount
            },
            {
                path: "/my-account/financing",
                exact: true,
                name: "au.my-account",
                component: MyAccountFinancing
            },
            {
                path: "/my-account/trade-in",
                exact: true,
                name: "au.my-account",
                component: MyAccountTradeIn
            },
            {
                path: "/my-account/sell-your-car",
                exact: true,
                name: "au.my-account",
                component: MyAccountSellYourCar
            },
            {
                path: "/financing",
                exact: true,
                // name: "get-pre-approval",
                component: GetPreApproval,
                hideLayoutViaUTM: false
            },
            {
                path: "/financing/:stepid",
                exact: true,
                // name: "get-pre-approval",
                component: GetPreApproval,
                hideLayoutViaUTM: false
            },
            {
                path: "/licence-verification",
                exact: true,
                // name: "get-pre-approval",
                component: GreenId,
                hideLayoutViaUTM: false
            },
            {
                path: "/payment",
                exact: true,
                name: "payment",
                component: AdyenPayment
            },
            {
                path: "/price/communication/:orderId/:stepId?",
                exact: true,
                name: "priceCommunication",
                component: PriceCommunicationToCustomerPage,
                init: "./au/price-communication.mobile"
            },
            {
                path: "/roadworthy-certificate-:city",
                exact: true,
                name: "au.roadworthy-certificate",
                component: RoadWorthyCertificate,
                init: "./au/roadworthy-certificate.mobile"
            },
            {
                path: "/roadworthy-certificate-:city/:stepId",
                exact: true,
                name: "au.roadworthy-certificate",
                component: RoadWorthyCertificateAppointment
            },
            {
                path: "*",
                exact: true,
                name: "au.not-found",
                component: NotFound,
                init: "./au/notfound.mobile"
                // component: () => <div>Mobile - 404</div>
            }
        ]
    }
];

export { routes };
