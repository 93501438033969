import React, {useEffect, useState} from "react";
import styles from "./styles.css";
import FooterLogo from "./images/footer-logo.svg";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import LazyImage from "../../shared/lazy-image";
import { appUrl, cmsURl } from "../../../constants/url-constants";
// import Filters from "../../../utils/filters-v2";
import ApplePlay from "./images/apple.png";
import GooglePlay from "./images/google.png";
import getMobileOperatingSystem from "../../../utils/helpers/get-operating-system";
import { MOBILE_OS, COUNTRY, DOWNLOAD_APP_URL_FOOTER, SELL_CAR_SEO_MENU, FOOTER_GEOS, SOCIAL_MEDIA_DATA} from "../../../constants/app-constants";

const Footer = ({
    isLoggedIn,
    seoMenu
}) => {

    const [showMobileIos, setShowMobileIos] = useState();

    const trackEvent = (e, type = "social", label) => {
        const events = { action: "", params: ""};
        switch (type) {
        case "social":
            events.action = AU_MOBILE_EVENTS.SOCIAL_MEDIA;
            events.params = `options ${e ? e.target.innerText : label}`;
            break;
        case "links":
            events.action = AU_MOBILE_EVENTS.FOOTER_LINKS;
            events.params = `Link selected ${e ? e.target.innerText : label}`;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            break;
        default: events.action = "";
        }
        trackMobileCustomEventsAU(events.action, {eventLabel: events.params});
    };

    const trackEventC2b = (e) => {
        const events = { action: "", params: ""};

        events.action = AU_MOBILE_EVENTS.SELL_CAR_LINKS;
        events.params = e.target.innerText;

        trackMobileCustomEventsAU(events.action, {eventLabel: events.params});
    };

    useEffect(() => {
        if (window && window.navigator &&  getMobileOperatingSystem() === MOBILE_OS.IOS) {
            setShowMobileIos(true);
        }
    }, []);

    const handleIos = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.DOWNLOAD_APP_FOOTER, {eventLabel: "ios_download"});
    };

    const handlePlay = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.DOWNLOAD_APP_FOOTER, {eventLabel: "android_download"});
    };
    const {cityTypeUrl = []} = seoMenu || {};
    return (
        <React.Fragment>
            <footer styleName={"styles.footer"}>
                {/* <h3 styleName={"styles.heading"}>COUNTRY</h3>
                <div styleName={"styles.selectCountry"}>
                    <Search
                        data={countryDropdownData}
                        onChange={onChangeCityList}
                        value={selectedCountry}
                        isSearchable={false}
                    />
                    <ArrowIcon white={"WhiteArrow"} />
                </div> */}
                <h3 styleName={"styles.heading"}>DOWNLOAD THE APP</h3>
                <div styleName={"styles.playstoreWrap"}>
                    { showMobileIos ?
                        <a href={DOWNLOAD_APP_URL_FOOTER} styleName="styles.storeIcon" onClick={handleIos}>
                            <LazyImage src={ApplePlay} />
                        </a> :
                        <a href={DOWNLOAD_APP_URL_FOOTER} styleName="styles.storeIcon" onClick={handlePlay}>
                            <LazyImage src={GooglePlay} />
                        </a>
                    }
                </div>
                <h3 styleName={"styles.heading"}>BUY CARS BY LOCATION</h3>
                <ul styleName={"styles.socialMedia"} onClick={trackEvent}>
                    {(cityTypeUrl || []).map(item =>
                        (<li key={item.label}>
                            <a href={`${appUrl()}/${item.spath}`}>{item.label}</a>
                        </li>)
                    )}
                    {/* <li>
                        <a href="https://www.facebook.com/CARS24Australia/">
                            <img src={youTube} alt="Youtube" />
                        </a>
                    </li> */}

                </ul>
                <h3 styleName={"styles.heading"}>SELL YOUR CAR</h3>
                <ul styleName={"styles.socialMedia"} onClick={trackEventC2b}>
                    {(SELL_CAR_SEO_MENU || []).map(item =>
                        (<li key={item.label}>
                            <a href={`${appUrl()}/${item.spath}`}>{item.label}</a>
                        </li>)
                    )}
                    {/* <li>
                        <a href="https://www.facebook.com/CARS24Australia/">
                            <img src={youTube} alt="Youtube" />
                        </a>
                    </li> */}

                </ul>
                <h3 styleName={"styles.heading"}>USEFUL LINKS</h3>
                <ul styleName={"styles.footerLink"}>
                    <li>
                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                        <Link onClick={() => trackEvent(null, "links", "career")} to={`/buy-used-cars-${COUNTRY.slug}/`}>
                        Find a Car
                        </Link>
                    </li>
                    <li>
                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                        <Link onClick={() => trackEvent(null, "links", "financing")} to={isLoggedIn ? "/my-account/financing/" :  "/financing/get-pre-approval/"}>
                        Apply for pre-approval
                        </Link>
                    </li>
                    <li>
                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                        <Link onClick={() => trackEvent(null, "links", "cars24_finance")} to="/cars24-finance/">
                        CARS24 Finance
                        </Link>
                    </li>
                    <li>
                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                        {/* <Link onClick={() => trackEvent(null, "links", "career")} to="/career">
                            Careers
                        </Link> */}
                        <a onClick={() => trackEvent(null, "links", "career")} href="https://apply.workable.com/cars24/?lng=en">Careers</a>
                    </li>
                    <li>
                        <Link onClick={() => trackEvent(null, "links", "CARS24_way")} to="/thecars24way/">
                            The CARS24 Way
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => trackEvent(null, "links", "about_us")} to="/about-us/">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => trackEvent(null, "links", "contact_us")} to="/contact-us/">
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/sitemap/" onClick={() => trackEvent(null, "links", "sitemap")}>
                        Sitemap
                        </Link>
                    </li>
                    <li>
                        <Link to="/cars24cover/" onClick={() => trackEvent(null, "links", "warranty")}>
                        Warranty
                        </Link>
                    </li>
                    <li>
                        <Link to="/reviews/" onClick={() => trackEvent(null, "links", "reviews")}>
                            User Reviews
                        </Link>
                    </li>
                    <li>
                        <a href={`${cmsURl()}/blog/`} onClick={() => trackEvent(null, "links", "blog")} target="_blank">
                            Blog
                        </a>
                    </li>
                    {/* <li>
                        <Link
                            onClick={() => onClickLink("300_point_inspection_report")}
                            to="/300-point-inspection"
                        >
                            300 Point Inspection Report
                        </Link>
                    </li>
                    <li>
                        <a onClick={() => onClickLink("CARS24_india")} href={appUrl()}>
                            CARS24 India
                        </a>
                    </li> */}
                </ul>
                <h3 styleName={"styles.heading"}>INFORMATION</h3>
                <ul styleName={"styles.footerLink"} onClick={(e) => trackEvent(e, "links")}>
                    <li>
                        <Link  to="/privacy-policy/">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link  to="/terms-of-use/">
                            Terms & Conditions
                        </Link>
                    </li>
                    <li>
                        <Link  to="/terms-of-purchase/">
                            Terms of Purchase
                        </Link>
                    </li>
                    <li>
                        <Link  to="/terms-of-sale/">
                            Terms of Sale
                        </Link>
                    </li>
                    <li>
                        <a href={`${cmsURl()}/blog/faq/`}>
                            FAQ
                        </a>
                    </li>
                </ul>
                <h3 styleName={"styles.heading"}>KEEP IN TOUCH</h3>
                <ul styleName={"styles.socialMedia"} onClick={trackEvent}>
                    {SOCIAL_MEDIA_DATA.map((item) => (
                        <li key={item.title}>
                            <a href={item.link} target="_blank">
                                {item.title}
                            </a>
                        </li>
                    ))}
                    {/* <li>
                        <a href="https://www.facebook.com/CARS24Australia/">
                            <img src={youTube} alt="Youtube" />
                        </a>
                    </li> */}

                </ul>
                {/* <h3 styleName={"styles.heading"}>EXPERIENCE CARS24 AUSTRALIA<br/> APP ON MOBILE</h3>
                <ul styleName={"styles.footerLink styles.downloadAppLink"}>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.cars24.aususedcars">
                            <img
                                className={"img-fluid"}
                                src="https://fastly-production.24c.in/cars24/link_category_items/play-store.png"
                                alt="android_app"
                            />
                        </a>
                    </li>
                </ul> */}
                <h3 styleName={"styles.heading"}>OTHER GEOGRAPHIES</h3>
                <ul styleName={"styles.socialMedia"}>
                    {FOOTER_GEOS.map((item) => (
                        <li key={item.title}>
                            <a href={item.link} target="_blank">
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>

                <Link onClick={() => trackEvent(null, "links", "logo")} to="/">
                    <LazyImage src={FooterLogo} styleName={"styles.footerLogo"} alt="CARS24" />
                </Link>
                <p styleName={"styles.reserved"}>
                    <span styleName={"styles.registerSign"}>
                        &copy;CARS24 2023
                    </span>
                    {/* <span>All rights reserved</span> */}
                </p>
            </footer>
        </React.Fragment>
    );
};

Footer.propTypes = {
    history: PropTypes.any,
    selectedCityCode: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    seoMenu: PropTypes.object,
    isSellYourCarPage: PropTypes.bool
};

export default Footer;
