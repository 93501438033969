import React from "react";
import withAULayout from "../../../components/au.mobile/layout";
import AdyenPayment from "../../../components/au.mobile/adyen-payment";
// import loadable from "@loadable/component";
// import PageLoader from "../../../components/shared/page-loader";
// const Checkout = loadable(() => import("./component"), {fallback:
//     <PageLoader />
// });

export default withAULayout(AdyenPayment, false, false);

