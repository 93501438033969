import { appUrl } from "../../constants/url-constants";
import qs from "querystring";

// eslint-disable-next-line max-params
export const getTestDriveURL = (carMakeModel, appointmentId, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/buy-used-${carMakeModel}-car-${appointmentId}/book-test-drive/${route}${queryStr}`;

    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};
export const getTestDriveUrlFromRelativePath = (relativePath, route, queryParams) => {
    const queryStr = queryParams ? `?${qs.stringify(queryParams)}` : "";
    const path = `${relativePath}book-test-drive/${route}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

// eslint-disable-next-line max-params
export const getcheckoutURL = (carMakeModel, appointmentId, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/buy-used-${carMakeModel}-car-${appointmentId}/checkout/${route}${queryStr}`;

    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getDetailURL = (carMakeModel, appointmentId) => {
    const path = `/buy-used-${carMakeModel}-car-${appointmentId}/`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

