import { createReducer } from "reduxsauce";
import { TRADE_STATE } from "../../../constants/trade-in-your-car-constants";
import Types from "./types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { LOCAL_STORAGE_KEYS } from "../../../constants/app-constants";

const USER_TRADE_IN_DATA_INITIAL_STATE = {
    tradeIn: false,
    tradeInAmount: 0
};

export const INITIAL_STATE = {
    tradeInData: {},
    cancelationReasons: [],
    tradeInStates: TRADE_STATE,
    tradeVehicle: {},
    userTradeInData: USER_TRADE_IN_DATA_INITIAL_STATE,
    carTradeInCarMakeModelId: {},
    tradeInCarVariants: {},
    tradeInCarDetails: {},
    carTradeInRegoVin: {},
    isCarVariantLoading: false,
    isCarDataLoading: false,
    pillsMaxStep: 0,
    questionnairePageNo: "",

    isGetTradeInDataLoading: false,
    isPostTradeInDataLoading: false,
    isUpdateTradeInDataLoading: false,
    isGetTradeInStatesLoading: false,
    isGetTradeVehicleLoading: false,
    isUserTradeInDataLoading: false,

    isGetTradeInDataError: false,
    isPostTradeInDataError: false,
    isUpdateTradeInDataError: false,
    isGetTradeInStatesError: false,
    isGetTradeVehicleError: false,
    isUserTradeInDataError: false,

    isTradeInAnotherCarTriggered: false,
    isEditCarDetailsTriggered: false,
    tradeInToggleSelected: null,
    isViewCarsButtonTriggered: false
};

export const getTradeInDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeInDataLoading: true,
        isGetTradeInDataError: false
    };
};

export const getTradeInDetailsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isGetTradeInDataLoading: false,
        isGetTradeInDataError: false,
        tradeInData: data
    };
};

export const getTradeInDetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeInDataLoading: false,
        isGetTradeInDataError: true
    };
};

export const createTradeInRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isPostTradeInDataLoading: true,
        isPostTradeInDataError: false
    };
};

export const createTradeInSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isPostTradeInDataLoading: false,
        isPostTradeInDataError: false,
        tradeInData: data
    };
};

export const createTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isPostTradeInDataLoading: false,
        isPostTradeInDataError: true
    };
};

export const updateTradeInRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdateTradeInDataLoading: true,
        isUpdateTradeInDataError: false
    };
};

export const updateTradeInSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isUpdateTradeInDataLoading: false,
        isUpdateTradeInDataError: false,
        tradeInData: data
    };
};

export const updateTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdateTradeInDataLoading: false,
        isUpdateTradeInDataError: true
    };
};

export const getTradeInStatesRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeInStatesLoading: true,
        isGetTradeInStatesError: false
    };
};

export const getTradeInStatesSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isGetTradeInStatesLoading: false,
        isGetTradeInStatesError: false,
        tradeInStates: data
    };
};

export const getTradeInStatesFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeInStatesLoading: false,
        isGetTradeInStatesError: true
    };
};

export const getTradeInVehicleRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeVehicleLoading: true,
        isGetTradeVehicleError: false
    };
};

export const getTradeInVehicleSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isGetTradeVehicleLoading: false,
        isGetTradeVehicleError: false,
        tradeVehicle: data
    };
};

export const getTradeInVehicleFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetTradeVehicleLoading: false,
        isGetTradeVehicleError: true
    };
};

export const getTradeInToggleVisibleRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUserTradeInDataLoading: true,
        isUserTradeInDataError: false
    };
};

export const getTradeInToggleVisibleSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isUserTradeInDataLoading: false,
        isUserTradeInDataError: false,
        isGetTradeInDataLoading: false,
        userTradeInData: data
    };
};

export const getTradeInToggleVisibleFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUserTradeInDataLoading: false,
        isUserTradeInDataError: true,
        userTradeInData: USER_TRADE_IN_DATA_INITIAL_STATE
    };
};

export const setTradeInAnotherCarTriggered = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isTradeInAnotherCarTriggered: data
    };
};

export const setEditCarDetailsTriggered = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isEditCarDetailsTriggered: data
    };
};

export const setTradeInToggleSelected = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInToggleSelected: data
    };
};

export const fetchTradeInCarVariantRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarVariantLoading: true
    };
};

export const fetchTradeInCarVariantSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        tradeInCarVariants: data,
        isCarVariantLoading: false
    };
};

export const fetchTradeInCarVariantFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInCarVariants: {},
        isCarVariantLoading: false
    };
};

export const fetchTradeInCarMakeModelRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarDataLoading: true
    };
};

export const fetchTradeInCarMakeModelSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        tradeInCarDetails: data,
        isCarDataLoading: false
    };
};

export const fetchTradeInCarMakeModelFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInCarDetails: {},
        isCarDataLoading: false
    };
};

export const saveTradeInCarMakeModelId = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carTradeInCarMakeModelId: data
    };
};

export const saveTradeInRegoVin = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carTradeInRegoVin: data
    };
};

export const setPillsMaxStep = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        pillsMaxStep: data
    };
};

export const setQuestionnairePageNo = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        questionnairePageNo: data
    };
};

export const resetTradeInDetails = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInData: {}
    };
};

export const setViewCarsButtonTriggered = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isViewCarsButtonTriggered: data
    };
};

export const fetchCancellationReasonSuccess = (state = INITIAL_STATE, {cancelationReasons}) => {
    return {
        ...state,
        cancelationReasons
    };
};

export const fetchCancellationReasonFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        cancelationReasons: [],
        cancelationReasonsError: error
    };
};

export const HANDLERS = {
    [Types.GET_TRADE_IN_REQUEST]: getTradeInDetailsRequest,
    [Types.GET_TRADE_IN_SUCCESS]: getTradeInDetailsSuccess,
    [Types.GET_TRADE_IN_FAILURE]: getTradeInDetailsFailure,

    [Types.CREATE_TRADE_IN_REQUEST]: createTradeInRequest,
    [Types.CREATE_TRADE_IN_SUCCESS]: createTradeInSuccess,
    [Types.CREATE_TRADE_IN_FAILURE]: createTradeInFailure,

    [Types.UPDATE_TRADE_IN_REQUEST]: updateTradeInRequest,
    [Types.UPDATE_TRADE_IN_SUCCESS]: updateTradeInSuccess,
    [Types.UPDATE_TRADE_IN_FAILURE]: updateTradeInFailure,

    [Types.GET_TRADE_IN_STATE_REQUEST]: getTradeInStatesRequest,
    [Types.GET_TRADE_IN_STATE_SUCCESS]: getTradeInStatesSuccess,
    [Types.GET_TRADE_IN_STATE_FAILURE]: getTradeInStatesFailure,

    [Types.GET_TRADE_IN_VEHICLE_REQUEST]: getTradeInVehicleRequest,
    [Types.GET_TRADE_IN_VEHICLE_SUCCESS]: getTradeInVehicleSuccess,
    [Types.GET_TRADE_IN_VEHICLE_FAILURE]: getTradeInVehicleFailure,

    [Types.GET_TRADE_IN_TOGGLE_VISIBLE_REQUEST]: getTradeInToggleVisibleRequest,
    [Types.GET_TRADE_IN_TOGGLE_VISIBLE_SUCCESS]: getTradeInToggleVisibleSuccess,
    [Types.GET_TRADE_IN_TOGGLE_VISIBLE_FAILURE]: getTradeInToggleVisibleFailure,

    [Types.SET_TRADE_IN_ANOTHER_CAR_TRIGGERED]: setTradeInAnotherCarTriggered,
    [Types.SET_EDIT_CAR_DETAILS_TRIGGERED]: setEditCarDetailsTriggered,
    [Types.SET_TRADE_IN_TOGGLE_SELECTED]: setTradeInToggleSelected,

    [Types.GET_TRADE_IN_CAR_VARIANT_REQUEST]: fetchTradeInCarVariantRequest,
    [Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS]: fetchTradeInCarVariantSuccess,
    [Types.GET_TRADE_IN_CAR_VARIANT_FAILURE]: fetchTradeInCarVariantFailure,

    [Types.GET_TRADE_IN_CAR_DETAILS_REQUEST]: fetchTradeInCarMakeModelRequest,
    [Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS]: fetchTradeInCarMakeModelSuccess,
    [Types.GET_TRADE_IN_CAR_DETAILS_FAILURE]: fetchTradeInCarMakeModelFailure,

    [Types.SAVE_TRADE_IN_ID]: saveTradeInCarMakeModelId,
    [Types.SAVE_CAR_REGNO_VIN]: saveTradeInRegoVin,
    [Types.SET_PILLS_MAX_STEP]: setPillsMaxStep,
    [Types.SET_QUESTIONNAIRE_PAGE_NO]: setQuestionnairePageNo,
    [Types.RESET_CAR_TRADE_IN_DETAILS]: resetTradeInDetails,
    [Types.SET_VIEW_CARS_BUTTON_TRIGGERED]: setViewCarsButtonTriggered,

    [Types.FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS]: fetchCancellationReasonSuccess,
    [Types.FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE]: fetchCancellationReasonFailure
};
const tradinPersistConfig = {
    key: LOCAL_STORAGE_KEYS.tradeInMobileAU,
    storage,
    version: 1,
    whitelist: ["tradeInToggleSelected"]
};

export default persistReducer(tradinPersistConfig, createReducer(INITIAL_STATE, HANDLERS));
