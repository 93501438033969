import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./component";
import { setLoginType } from "../../au.configuration/actions";

const mapStateToProps = ({
    user: { logoURL },
    header: { showHeader, fixedHeader },
    abExperiment: { webPriceDropMsite },
    myWishlist: {wishlistedCarsList}
}) => ({
    logoURL,
    showHeader,
    fixedHeader,
    wishlistedCarsList,
    webPriceDropMsite
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLoginTypeConnect: setLoginType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
