import React, { useEffect } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import Button from "../../shared/button";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { GA_EVENTS } from "../checkout-info/tracking";
import { useHistory } from "react-router-dom";
import isWebView from "../../../utils/helpers/is-web-view";
const CarAlreadyReservedModal = ({
    carReservedModal,
    verifyReservedStatusConnect,
    orderId
}) => {
    const {
        show = false,
        heading = "",
        bodyText = "",
        redirectUrl = ""
    } = carReservedModal || {};
    const isWebViewMode = isWebView();
    useEffect(() => {
        const data = {message: "reservedPopUpOpen"};
        if (isWebViewMode && show) {
            if (typeof window !== "undefined" && window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
            }
        }
    }, [isWebViewMode, show]);
    const history = useHistory();
    const handleViewSimilarCars = () => {
        verifyReservedStatusConnect({hideModal: true});
        const data = {message: "viewSimilarCarClicked"};
        trackMobileCustomEventsAU(GA_EVENTS.CHECKOUT_POP_UP_CTA_CLICKED, {
            ...GA_EVENTS.CHECKOUT_POP_UP_CTA_CLICKED,
            eventLabel: orderId
        });
        if (isWebViewMode) {
            if (typeof window !== "undefined" && window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
            }
        } else if (redirectUrl) {
            history.replace(redirectUrl);
        }

    };
    return (
        <Modal isOpen={show}>
            <div styleName={"styles.outer"}>
                <p styleName={"styles.heading"}>{heading || "This car got booked by another user!"}</p>
                <p styleName={"styles.subHeading"}>{bodyText || "Don't worry, we have other listings similar to this car. Check them out now."}</p>
                <Button text="View similar cars" onClick={handleViewSimilarCars} />
            </div>
        </Modal>
    );
};

CarAlreadyReservedModal.propTypes = {
    carReservedModal: PropTypes.object,
    verifyReservedStatusConnect: PropTypes.func,
    orderId: PropTypes.string
};

export default CarAlreadyReservedModal;
