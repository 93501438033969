//
// This is the client side entry point for the React app.
//

import React from "react";
import { render, hydrate } from "react-dom";
import { routes } from "./routes.au.mobile";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index.au.mobile";
import { renderRoutes } from "react-router-config";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { loadableReady } from "@loadable/component";
import { initializeSentry,  sentryReduxEnhancer } from "../client/utils/sentry/initialize-sentry";

const persistConfig = {
    key: "rootMobileAU",
    storage,
    version: 1,
    whitelist: ["userRegoVin"]
};
initializeSentry();

const persistedReducer = persistReducer(persistConfig, rootReducer);
//
const middlewares = [thunk];
const DEBUG = process.env.NODE_ENV === "development";
const composeEnhancers =
    typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        DEBUG ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name
            // actionsBlacklist, actionsCreators, serialize...
            trace: true
        }) : compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer);

//
// Redux configure store with Hot Module Reload
//
const configureStore = initialState => {
    const store = createStore(persistedReducer, initialState, enhancer);
    const persistor = persistStore(store);

    if (module.hot) {
        module.hot.accept("./reducers/index.au.mobile", () => {
            const nextRootReducer = require("./reducers/index.au.mobile").default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return { store, persistor };
};

const { store, persistor } = configureStore(window.__PRELOADED_STATE__);

const start = App => {
    const jsContent = document.querySelector(".js-content");
    const reactStart = window.__PRELOADED_STATE__ && jsContent.innerHTML ? hydrate : render;

    loadableReady(() =>
        reactStart(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>,
            jsContent
        ));
};

// window.webappStart = () => start(() => renderRoutes(routes));
start(() => renderRoutes(routes));

//
// Hot Module Reload setup
//
if (module.hot) {
    module.hot.accept("./routes.au.mobile", () => {
        const r = require("./routes.au.mobile");
        start(() => renderRoutes(r.routes));
    });
}

export { store };
