import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import InfiniteScroll from "react-infinite-scroller";

import CloseButton from "../../shared/icon-cross";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InventoryCarCard from "../inventory-car-card";
import { SOURCE } from "../inventory-car-card/constant";
import { CAR_LIST_EVENT_NAMES, HISTORY_SOURCE_CONSTANTS, NUMBER, WISHLIST_SOURCE_TYPES } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import SkeletonRevamp from "../inventory-car-card/skeleton";
import { SORT_MAPPING } from "../../../utils/filters-v2/constants";
import { ListingServiceAU } from "../../../service";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import getCarInfo from "../../../utils/helpers/get-car-info";
import OurLocationModal from "../our-location-modal";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const LISTING_SPATH = "buy-used-cars-australia";

// eslint-disable-next-line max-params
const getSimilarCarFilter = ({make, model, range, type}, isSelected = true) => {
    if (type === "budget") {
        return ([{filterKey: "quotedPrice", isRangeFilter: true, ...range, isSelected}]);
    }
    return ([{filterKey: "make", optionKey: make, subOptionKey: model, isSelected}]);

};

let page = 0;

// eslint-disable-next-line max-statements
const SimilarCarPage = ({
    carContent,
    config,
    allFilters,
    similarCars,
    fetchFiltersConnect,
    fetchSimilarCarsConnect,
    totalSimilarVehicles,
    isSimilarCarsFetching,
    similarVehiclePage = 0,
    clearSimilarCarListConnect,
    updateFilterOptionDataConnect,
    isUserZeroDpVariant
}) => {
    const history = useHistory();
    const {type} = parseLocationSearchParams(history.location.search);

    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [listingData, setListingContent] = useState({listContent: []});
    const [isLoading, setIsLoading] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);

    const {make, model, egc, appointmentId} = carContent;

    const isDefaultView = type === "default" || type === "similar" || type === "checkout";

    const SCREEN_TYPE = {
        budget: "More cars in your budget",
        default: "Similar cars for you",
        makeModel: `More ${make} ${model} cars for you`,
        similar: "Similar cars for you",
        checkout: "Similar cars for you"
    };

    const CAR_CLICKED_SOURCE = {
        budget: "similar budget",
        default: "hero image",
        makeModel: `more models`,
        similar: "similar car",
        checkout: "checkout"
    };

    const totalPages = Math.ceil((isDefaultView ? totalSimilarVehicles : listingData.totalCars) / NUMBER.TWENTY);

    const priceUpperLimit = ((Math.floor((egc / NUMBER.FIVE_THOUSAND)) + 1) * NUMBER.FIVE_THOUSAND);
    const priceLowerLimit = priceUpperLimit - NUMBER.FIVE_THOUSAND + 1;
    const selectedFilterPayload =  {
        make, model, type, range: {
            min: priceLowerLimit,
            max: priceUpperLimit
        }};

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchListing = useCallback((nextPage = 0) => {
        const filtes = new Filters({cityCode: ""});
        const { payload } = filtes.getListingPayload({
            selectedFilters: getSimilarCarFilter(selectedFilterPayload),
            filters: allFilters,
            options: {
                searchSimilarVariant: true,
                ...(type === "budget" && {
                    sort: SORT_MAPPING.CUSTOM_BUDGET_SORT
                }),
                excludedAppointmentId: appointmentId
            }
        });

        let params =  {
            size: 20,
            page: nextPage,
            spath: LISTING_SPATH
        };
        let queryString = payload;

        if (queryString && queryString.indexOf("userFinanceType") < 0) {
            const userFinanceType = FINANCE_TYPE.CARS24;
            queryString = `${queryString}&userFinanceType=${userFinanceType}`;
        }
        params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
        ListingServiceAU.fetchListing(params, queryString).then((response) => {
            const {results = [], total} = (response || {}).data || {};
            setListingContent({ listContent: [...listingData.listContent, ...results], totalCars: total, totalPages: totalPages || 0 });
            setHasMoreItems(true);
            setIsLoading(false);
            page = nextPage;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFilters, appointmentId, isUserZeroDpVariant, type, listingData.listContent]);

    const fetchSimilarCarList = (nextPage) => {
        fetchSimilarCarsConnect(appointmentId, { size: 20, page: nextPage }).then(() => {
            setHasMoreItems(true);
        });
    };

    const handleClose = async () => {
        await yieldToMain();
        if (type === "checkout") {
            // resetCarListConnect();
            const {carRelativeURL} = getCarInfo(carContent);
            clearSimilarCarListConnect();
            history.push(carRelativeURL, { from: HISTORY_SOURCE_CONSTANTS.SIMILAR_CARS });
            return;
        } else if (isDefaultView) {
            clearSimilarCarListConnect();
        } else {
            updateFilterOptionDataConnect(getSimilarCarFilter(selectedFilterPayload, false));
        }
        history.goBack();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFiltersConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isDefaultView && (allFilters.make || {}).key && listingData.listContent.length === 0 && !isLoading) {
            setIsLoading(true);
            fetchListing();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFilters, fetchListing, isDefaultView]);

    const loadMore = () => {
        setHasMoreItems(false);
        if (isDefaultView && (similarVehiclePage + 1 < totalPages && !isLoading) || (totalPages === null && !isLoading)) {
            fetchSimilarCarList(similarVehiclePage + 1);
        } else if (!isDefaultView && (page + 1 < totalPages && !isLoading) || (totalPages === null && !isLoading)) {
            fetchListing(page + 1);
        }
    };

    const similarCarData = isDefaultView ? similarCars : listingData.listContent;
    const isSkeletionLoading = isDefaultView ? (isSimilarCarsFetching || isSimilarCarsFetching === null) : ((isLoading || isLoading === null));

    return (
        <div styleName={"styles.modalWrappers"} className="container">
            <div styleName={"styles.headerWrap"}>
                <h2>{SCREEN_TYPE[type]}</h2>
                <CloseButton type="grey" onClickHandler={() => handleClose()} />

            </div>
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMoreItems}
                initialLoad={false}
            >
                {similarCarData.map((item, index) => (
                    <React.Fragment key={index}>
                        <InventoryCarCard
                            {...({...item, emiDetails: item.emiDetails || {}}) }
                            config={config}
                            source={SOURCE.SIMILAR_CARS}
                            // onClickCallback={onClickCallback}
                            gtmFrom="listing_page"
                            item={item}
                            bodyType={item.body || item.bodyType}
                            index={index}
                            egcRenderType={"details"}
                            impressionSource={CAR_LIST_EVENT_NAMES.LISTING}
                            showCarCompare
                            pageSource={WISHLIST_SOURCE_TYPES.CDP}
                            carClickedSource={CAR_CLICKED_SOURCE[type]}
                            showLocationCallback={() => setShowLocationModal(true)}
                        />
                    </React.Fragment>
                )
                )}
                {isSkeletionLoading &&
                        Array(...new Array(NUMBER.FIVE)).map((i, index) =>  <SkeletonRevamp key={index} />)
                }
                {
                    showLocationModal && <OurLocationModal handleModalClose={() => setShowLocationModal(false)}/>
                }
            </InfiniteScroll>

        </div>

    );
};

SimilarCarPage.propTypes = {
    similarCars: PropTypes.array,
    config: PropTypes.object,
    isLoading: PropTypes.bool,
    totalCars: PropTypes.number,
    page: PropTypes.number,
    allFilters: PropTypes.object,
    fetchCarListConnect: PropTypes.func,
    content: PropTypes.object,
    listContent: PropTypes.array,
    carContent: PropTypes.array,
    resetCarListConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    shouldReloadList: PropTypes.bool,
    reloadCarListConnect: PropTypes.func,
    fetchSimilarCarsConnect: PropTypes.func,
    totalSimilarVehicles: PropTypes.number,
    isSimilarCarsFetching: PropTypes.bool,
    similarVehiclePage: PropTypes.bool,
    clearSimilarCarListConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    isUserZeroDpVariant: PropTypes.bool
};

export default SimilarCarPage;
