/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import Types from "./types";
import { TRADE_STATE } from "../../../constants/app-constants";

const TRADE_IN_INITIAL_STATE = {};

export const INITIAL_STATE = {
    personalDetails: {
        firstName: "",
        middleName: "",
        lastName: "",
        phone: ""
        // ...userReducer.address
    },
    previousStep: "", // route
    currentStep: "",  // route
    nextStep: "",     // route
    maxStep: "",      // route
    prevTabIndex: 0,

    deliveryMode: "", // HOME or SELF_PICKUP
    updateDeliveryError: null,
    checkoutInitialStep: "",
    createOrderError: null,
    orderDetailError: null,
    isOrderDetailLoading: true,
    isGetOrderDetailLoading: true,

    isFinanceDataLoading: true,
    isGetFinanceDataLoading: true,
    financeDataError: null, // also used to show generic error screen with no lenders
    isFinanceDataPosted: false,
    isGetFinanceDataError: null,
    financeErrorResponse: null, // in case of 200 from driva api
    isCreateFinanceDataError: null,
    isUpdateFinanceDataError: null,
    orderAvailable: null,
    quotesAvailable: null,

    recalculateTriggered: false,
    egcData: [],
    tradeinOpted: false,
    tradeInData: TRADE_IN_INITIAL_STATE,
    evaluatedTradeInData: {},
    isTradeInDataLoading: true,
    isGetTradeInDataLoading: true,
    isGetTradeInDataError: null,
    isCreateTradeInDataError: null,
    isUpdateTradeInDataError: null,
    tradeInStates: TRADE_STATE,
    checkoutSourcePathname: "",
    splitAmountDetails: {
        selectedSplitAmount: 0,
        totalAmountPaid: 0,
        remainingAmount: 0
    },
    splitPaymentUserState: {
        selectePaymentMode: "",
        selectedRange: {min: 0, max: 0},
        selectedSliderValue: 0
    },
    isPaymentHistoryFetched: false,
    paymentHistoryData: [],
    paymentHistoryError: null,
    tradeinLocalState: {},
    deliveryAddress: {
        isDeliveryAddressFetching: null,
        deliveryAddressError: false
    },
    isUpdating: false,
    rejectModal: {
        isRejectModalVisible: false,
        modalType: ""
    },
    carTradeInRegoVin: {},
    selectedCityCarSell: "",
    tradeVehicle: TRADE_IN_INITIAL_STATE,
    isFetchingConfig: false,
    seoData: {},
    shazamCampaignEnabled: false,
    seoSectionsData: {},
    carTradeInCarMakeModelId: {},
    tradeInCarDetails: {},
    tradeInCarVariants: {},
    isCarVariantLoading: false,
    isCarDataLoading: false,
    isError: false,
    cancellationReasons: [],
    inspectionCancellationReasons: [],
    makeModelFlow: {
        isFetchingOrderDetails: false,
        currentStep: {},
        maxStep: {},
        mmvyUserInputs: {
            screenStep: null,
            makeId: null,
            modelId: null,
            year: null,
            fuelType: null,
            bodyType: null,
            transmissionType: null,
            driveType: null,
            variant: null
        },
        rejectionReasonInfo: {
            show: false,
            type: null
        }
    },
    isGuestLoginVisible: false,
    isMobileMandatory: null,
    guestPopupType: null,
    inputRegoNumber: "",
    testimonials: {},
    redirectionData: {},
    cmsConfig: {},
    isFetchingCmsConfig: false,
    cmsConfigError: null
};

export const populateCheckoutPersonalData = (state = INITIAL_STATE, { userData }) => {
    const {firstName, middleName, lastName, profileMobile} = userData;
    const personalDetails = {
        firstName,
        middleName,
        lastName,
        phone: profileMobile
    };

    return {
        ...state,
        personalDetails
    };
};

export const setUserPersonalDetails = (state = INITIAL_STATE, { personalDetails }) => {
    return {
        ...state,
        personalDetails
    };
};

export const setDeliveryMode = (state = INITIAL_STATE, { deliveryMode }) => {
    return {
        ...state,
        deliveryMode
    };
};

export const updateDeliveryModeSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: data,
        isOrderDetailLoading: false,
        orderDetailError: null
    };
};

export const updateDeliveryModeFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        updateDeliveryError: error
    };
};

// modify this if you need to persist some data
export const softResetCheckout = (state = INITIAL_STATE) => {
    return {
        ...INITIAL_STATE,
        personalDetails: state.personalDetails
        // order: state.order
    };
};

export const setMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {...state, maxStep};
};

export const setRecalculateTriggered = (state = INITIAL_STATE, {recalculateTriggered}) => {
    return {...state, recalculateTriggered};
};

export const fetchEgcPriceSuccess = (state = INITIAL_STATE, {data}) => {
    return {...state, egcData: [...data]};
};

export const fetchEgcPriceFailure = (state = INITIAL_STATE, {error}) => {
    return {...state, egcData: [...error]};
};
//trade-in

export const getTradeInDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        makeModelFlow: {
            isFetchingOrderDetails: true
        }
    };
};

export const getTradeInDetailsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        makeModelFlow: {
            ...state.makeModelFlow,
            mmvyUserInputs: data.mmvyUserInputs  || {},
            isFetchingOrderDetails: false
        },
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const getTradeInDetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        tradeInData: TRADE_IN_INITIAL_STATE,
        isGetTradeInDataError: true,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        makeModelFlow: {
            isFetchingOrderDetails: false
        }
    };
};

export const getOrderDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        makeModelFlow: {
            isFetchingOrderDetails: true
        }
    };
};

export const getOrderDetailsSuccess = (state = INITIAL_STATE, { data = {}, evaluatedData = {} }) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        evaluatedTradeInData: evaluatedData,
        makeModelFlow: {
            ...state.makeModelFlow,
            mmvyUserInputs: data.mmvyUserInputs || {},
            isFetchingOrderDetails: false
        },
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const getOrderDetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        isGetTradeInDataError: true,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        makeModelFlow: {
            isFetchingOrderDetails: false
        }
    };
};

export const getColdLeadRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const  getColdLeadSuccess = (state = INITIAL_STATE, { data = {}, evaluatedData = {} }) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        tradeInData: {
            ...data
        },
        evaluatedTradeInData: evaluatedData,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const  getColdLeadFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        isGetTradeInDataError: true,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: true,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInRequestNoKbb = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInSuccessNokbb = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInFailureNoKbb = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: true,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isTradeInDataLoading: isLoading,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInSuccess = (state = INITIAL_STATE, { data, evaluatedData }) => {
    return {
        ...state,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        evaluatedTradeInData: evaluatedData,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: true
    };
};

export const customerOptedTrade = (state = INITIAL_STATE, {tradeinOpted}) => ({...state, tradeinOpted});

export const fetchTradeInStatesSuccess = (state = INITIAL_STATE, {tradeInStates}) => ({...state, tradeInStates});
export const fetchTradeInStatesFailure = (state = INITIAL_STATE) => ({...state, tradeInStates: state.tradeInStates});
export const saveCheckoutSourcePathname = (state = INITIAL_STATE, { data }) => ({...state, checkoutSourcePathname: data});

export const fetchTradeInCarMakeModel = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarDataLoading: true,
        isUpdating: true
    };
};

export const fetchTradeInCarMakeModelSuccess = (state = INITIAL_STATE, { tradeInCarDetails }) => {
    return {
        ...state,
        tradeInCarDetails,
        isCarDataLoading: false,
        isUpdating: false
    };
};

export const fetchTradeInCarMakeModelFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        tradeInCarDetails: [],
        isCarDataLoading: false,
        makeModelFlow: {
            ...state.makeModelFlow,
            rejectionReasonInfo: {
                show: true,
                type: error
            }
        },
        isUpdating: false
    };
};

export const fetchTradeInCarVariant = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarVariantLoading: true
    };
};

export const fetchTradeInCarVariantSuccess = (state = INITIAL_STATE, {tradeInCarVariants}) => {
    return {
        ...state,
        tradeInCarVariants,
        isCarVariantLoading: false
    };
};

export const fetchTradeInCarVariantFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        tradeInCarVariants: [],
        isCarVariantLoading: false,
        makeModelFlow: {
            ...state.makeModelFlow,
            rejectionReasonInfo: {
                show: true,
                type: error
            }
        }
    };
};

// export const saveTradeInRegoVin = (state = INITIAL_STATE, { data }) => ({...state, carTradeInRegoVin: data});

export const saveTradeInCarMakeModelId = (state = INITIAL_STATE, { data }) => ({...state, carTradeInCarMakeModelId: data});

export const resetTradeInDetails = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInData: TRADE_IN_INITIAL_STATE
    };
};

export const getPaymentHistoryInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isPaymentHistoryFetched: data
    };
};

export const getPaymentHistorySuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        paymentHistoryData: data,
        isPaymentHistoryFetched: true
    };
};

export const getPaymentHistoryFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPaymentHistoryFetched: true,
        paymentHistoryError: error
    };
};

export const savetradeInLocalState = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        tradeInData: {...state.tradeInData, ...data}
    };
};

export const getDeliverySuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        deliveryAddress: {isDeliveryAddressFetching: false, ...data}

    };
};

export const getDeliveryInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isDeliveryAddressFetching: true
    };
};
export const getDeliveryFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        deliveryAddress: {
            isDeliveryAddressFetching: false,
            deliveryAddressError: error
        }
    };
};

export const setUserUpdating = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: false
    };
};

export const setModalHandling = (state = INITIAL_STATE, {modalType}) => {
    return {
        ...state,
        rejectModal: {...state.rejectModal, isRejectModalVisible: !state.rejectModal.isRejectModalVisible, modalType}
    };
};

export const resetModalHandling = (state = INITIAL_STATE) => {
    return {
        ...state,
        rejectModal: {...state.rejectModal, isRejectModalVisible: false, modalType: ""}
    };
};

export const getTradeInVehicleRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeVehicleLoading: true,
        isGetTradeVehicleLoading: true,
        isGetTradeVehicleError: null,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const getTradeInVehicleSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isTradeVehicleLoading: false,
        isGetTradeVehicleLoading: false,
        tradeVehicle: data,
        isGetTradeVehicleError: null,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const getTradeInVehicleFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeVehicleLoading: false,
        isGetTradeVehicleLoading: false,
        isGetTradeVehicleError: true,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const saveSelectedCity = (state = INITIAL_STATE, { data }) => ({...state, selectedCityCarSell: data});

export const getSeoContentRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetchingConfig: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const getSeoContentRequestSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isFetchingConfig: false,
        seoData: data.seoMeta || {},
        shazamCampaignEnabled: data.shazamCampaignEnabled,
        seoSectionsData: data.seoSectionsData || {},
        cancellationReasons: data.valuationCancelReasons || [],
        inspectionCancellationReasons: data.appointmentCancelReasons || [],
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        testimonials: data.testimonialRatings || {},
        faqSection: data.faqSection,
        theBestPlaceToSell: data.theBestPlaceToSell,
        theBestPriceForCar: data.theBestPriceForCar
    };
};

export const getSeoContentRequestFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetchingConfig: false,
        isGetTradeInDataError: true,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

const setMakeFlowMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            maxStep
        }
    };
};

const setMakeFlowCurrentStep = (state = INITIAL_STATE, {currentStep}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            currentStep
        }
    };
};

const setMakeModelYear = (state = INITIAL_STATE, {makeModelYear}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            makeModelYear
        }
    };
};

const setMakeFlowHardRejection = (state = INITIAL_STATE, {rejectionReasonInfo}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            rejectionReasonInfo
        }
    };
};

const setMmvyUserInputs = (state = INITIAL_STATE, {mmvyUserInputs}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            mmvyUserInputs
        }
    };
};

const openGuestLogin = (state = INITIAL_STATE, {guestPopupType, isMobileMandatory}) => {
    return {
        ...state,
        isGuestLoginVisible: true,
        guestPopupType,
        isMobileMandatory
    };
};

const closeGuestLogin = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGuestLoginVisible: false,
        guestPopupType: null,
        isMobileMandatory: null
    };
};

const setInputRegoNumber = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        inputRegoNumber: data
    };
};

const setRedirectionData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        redirectionData: {
            ...data
        }
    };
};

const fetchCmsConfigInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetchingCmsConfig: true
    };
};

const fetchCmsConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        cmsConfig: data,
        isFetchingCmsConfig: false
    };
};

const fetchCmsConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        cmsConfigError: error,
        isFetchingCmsConfig: false
    };
};
export const HANDLERS = {
    [Types.POPULATE_CHECKOUT_PERSONAL_DATA]: populateCheckoutPersonalData,
    [Types.SET_DELIVERY_MODE]: setDeliveryMode,
    [Types.UPDATE_DELIVERY_MODE_SUCCESS]: updateDeliveryModeSuccess,
    [Types.UPDATE_DELIVERY_MODE_FAILURE]: updateDeliveryModeFailure,
    [Types.SET_MAX_STEP]: setMaxStep,
    [Types.SET_USER_PERSONAL_DETAILS]: setUserPersonalDetails,
    [Types.SOFT_RESET_CHECKOUT]: softResetCheckout,

    [Types.SET_RECALCULATE_TRIGGERED]: setRecalculateTriggered,
    [Types.FETCH_EGC_PRICE_SUCCESS]: fetchEgcPriceSuccess,
    [Types.FETCH_EGC_PRICE_FAILURE]: fetchEgcPriceFailure,

    [Types.GET_TRADE_IN_REQUEST]: getTradeInDetailsRequest,
    [Types.GET_TRADE_IN_SUCCESS]: getTradeInDetailsSuccess,
    [Types.GET_TRADE_IN_FAILURE]: getTradeInDetailsFailure,

    [Types.GET_ORDER_REQUEST]: getOrderDetailsRequest,
    [Types.GET_ORDER_SUCCESS]: getOrderDetailsSuccess,
    [Types.GET_ORDER_FAILURE]: getOrderDetailsFailure,

    [Types.GET_COLD_LEAD_REQUEST]: getColdLeadRequest,
    [Types.GET_COLD_LEAD_SUCCESS]: getColdLeadSuccess,
    [Types.GET_COLD_LEAD_FAILURE]: getColdLeadFailure,

    [Types.CREATE_TRADE_IN_REQUEST]: createTradeInRequest,
    [Types.CREATE_TRADE_IN_SUCCESS]: createTradeInSuccess,
    [Types.CREATE_TRADE_IN_FAILURE]: createTradeInFailure,

    [Types.UPDATE_TRADE_IN_REQUEST]: updateTradeInRequest,
    [Types.UPDATE_TRADE_IN_SUCCESS]: updateTradeInSuccess,
    [Types.UPDATE_TRADE_IN_FAILURE]: updateTradeInFailure,
    [Types.SET_TRADE_OPTION]: customerOptedTrade,
    [Types.GET_TRADE_IN_STATE_SUCCESS]: fetchTradeInStatesSuccess,
    [Types.GET_TRADE_IN_STATE_FAILURE]: fetchTradeInStatesFailure,
    [Types.SAVE_CHECKOUT_SOURCE_PATHNAME]: saveCheckoutSourcePathname,
    [Types.GET_PAYMENT_HISTORY_SUCCESS]: getPaymentHistorySuccess,
    [Types.GET_PAYMENT_HISTORY_FAILURE]: getPaymentHistoryFailure,
    [Types.GET_PAYMENT_HISTORY_INIT]: getPaymentHistoryInit,
    [Types.SAVE_TRADE_IN_LOCALSTATE]: savetradeInLocalState,
    [Types.GET_DELIVERY_INFO_INIT]: getDeliveryInit,
    [Types.GET_DELIVERY_INFO_SUCCESS]: getDeliverySuccess,
    [Types.GET_DELIVERY_INFO_FAILURE]: getDeliveryFailure,
    [Types.SET_USER_UPDATING]: setUserUpdating,
    [Types.HANDLE_REJECT_MODAL]: setModalHandling,
    [Types.CLOSE_REJECT_MODAL]: resetModalHandling,
    [Types.GET_TRADE_IN_VEHICLE_REQUEST]: getTradeInVehicleRequest,
    [Types.GET_TRADE_IN_VEHICLE_SUCCESS]: getTradeInVehicleSuccess,
    [Types.GET_TRADE_IN_VEHICLE_FAILURE]: getTradeInVehicleFailure,

    [Types.SAVE_SELECTED_CITY]: saveSelectedCity,

    [Types.FETCH_SEO_CONTENT]: getSeoContentRequest,
    [Types.FETCH_SEO_CONTENT_SUCCESS]: getSeoContentRequestSuccess,
    [Types.FETCH_SEO_CONTENT_FAILURE]: getSeoContentRequestFailure,
    // [Types.SAVE_CAR_REGNO_VIN]: saveTradeInRegoVin,

    [Types.SAVE_TRADE_IN_ID]: saveTradeInCarMakeModelId,

    [Types. RESET_CAR_TRADE_IN_DETAILS]: resetTradeInDetails,

    [Types.GET_TRADE_IN_CAR_DETAILS]: fetchTradeInCarMakeModel,
    [Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS]: fetchTradeInCarMakeModelSuccess,
    [Types.GET_TRADE_IN_CAR_DETAILS_FAILURE]: fetchTradeInCarMakeModelFailure,

    [Types.GET_TRADE_IN_CAR_VARIANT]: fetchTradeInCarVariant,
    [Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS]: fetchTradeInCarVariantSuccess,
    [Types.GET_TRADE_IN_CAR_VARIANT_FAILURE]: fetchTradeInCarVariantFailure,

    [Types.UPDATE_CAR_VARIANT]: createTradeInRequestNoKbb,
    [Types.UPDATE_CAR_VARIANT_SUCCESS]: createTradeInSuccessNokbb,
    [Types.UPDATE_CAR_VARIANT_FAILURE]: createTradeInFailureNoKbb,

    [Types.SET__MAKE_MODEL_MAX_STEP]: setMakeFlowMaxStep,
    [Types.SET__MAKE_MODEL_CURRENT_STEP]: setMakeFlowCurrentStep,
    [Types.SET__MAKE_MODEL_HARD_REJECTION]: setMakeFlowHardRejection,
    [Types.SET_MAKE_MODEL_YEAR]: setMakeModelYear,
    [Types.SET_MMVY_USER_INPUTS]: setMmvyUserInputs,

    [Types.CLOSE_GUEST_LOGIN]: closeGuestLogin,
    [Types.REGO_NUMBER_UPDATE]: setInputRegoNumber,
    [Types.OPEN_GUEST_LOGIN]: openGuestLogin,

    [Types.SET_REDIRECTION_DATA]: setRedirectionData,

    [Types.FETCH_CMS_CONFIG_INIT]: fetchCmsConfigInit,
    [Types.FETCH_CMS_CONFIG_SUCCESS]: fetchCmsConfigSuccess,
    [Types.FETCH_CMS_CONFIG_FAILURE]: fetchCmsConfigFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
