import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckoutHeader from "./component";
import { checkoutCallbackRequest } from "../checkout-info/actions";

const mapStateToProps = ({
    carDetails: { content, config, eligibleForExtendedWarranty },
    user: {isAffiliate, isUserZeroDpVariant, mobile},
    checkout: {egcData, order, checkoutStepsData,  tradeInData, deliveryMode,
        callbackDetails: {
            isExpired,
            requested
        } },
    deliveryInfo

}) => ({
    content,
    isUserZeroDpVariant,
    isAffiliate,
    egcData,
    deliveryInfo,
    order,
    checkoutStepsData,
    tradeInData,
    config,
    eligibleForExtendedWarranty,
    deliveryMode,
    mobile,
    checkoutCallbackRequestExpired: isExpired,
    callbackRequest: requested
});

const mapDispatchToProps = (dispatch) => bindActionCreators({

    checkoutCallbackRequestConnect: checkoutCallbackRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader);
