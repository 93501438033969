
import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    currentStep: {},
    maxStep: {},
    bookingSlots: [],
    hubDetails: {},
    isFetchingSlots: false,
    selectedSlotData: {
        date: "",
        key: ""
    },
    orderDetails: {},
    isCancellingSlot: false,
    slotCancellingError: false,
    isSlotCancelled: false,
    isSlotRescheduledClicked: false,
    isTestDriveVariant: false,
    email: "",
    testDriveGetError: false,
    testDriveSetError: false,
    isTestDriveVariantFetching: false
};

const setMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {...state, maxStep};
};

const setCurrentStep = (state = INITIAL_STATE, {currentStep}) => {
    return {...state, currentStep};
};

const setSelectedSlot = (state = INITIAL_STATE, {selectedSlotData}) => {
    return {...state, selectedSlotData};
};

const  fetchSlotsRequest = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isFetchingSlots: isLoading,
        bookingSlots: []
    };
};

const  fetchSlotsSuccess = (state = INITIAL_STATE, {slotsResponse}) => {
    return {
        ...state,
        isFetchingSlots: false,
        bookingSlots: slotsResponse?.slotsData || [],
        hubDetails: slotsResponse?.hubData
    };
};

const  fetchSlotsError = (state = INITIAL_STATE, {isLoading = false}) => {
    return {
        ...state,
        isFetchingSlots: isLoading,
        bookingSlots: []
    };
};

const setOrderData = (state = INITIAL_STATE, {orderDetails}) => {
    return {
        ...state,
        orderDetails
    };
};

const  getOrderError = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        orderDetails: {},
        error
    };
};

const  cancelSlotInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCancellingSlot: true
    };
};

const  cancelSlotSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCancellingSlot: false,
        isSlotCancelled: true
    };
};

const  cancelSlotFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        isCancellingSlot: false,
        error
    };
};

const resetCancelReschedulePopupState = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSlotCancelled: false,
        isSlotRescheduledClicked: false
    };
};

const slotReschedule = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSlotRescheduledClicked: true
    };
};

const setTestDriveVariantSuccess = (state = INITIAL_STATE, data) => {
    return {
        ...state,
        email: data.email,
        isTestDriveVariantSetting: false,
        isTestDriveVariant: data.testDriveVariant
    };
};
const setTestDriveVariantFailure = (state = INITIAL_STATE, error) => {
    return {
        ...state,
        isTestDriveVariantSetting: false,
        testDriveSetError: error
    };
};

const getTestDriveVariantInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveVariantFetching: true
    };
};
const getTestDriveVariantSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        email: data.email,
        isTestDriveVariantFetching: false,
        isTestDriveVariant: data.testDriveVariant
    };
};
const getTestDriveVariantFailure = (state = INITIAL_STATE, error) => {
    return {
        ...state,
        isTestDriveVariantFetching: false,
        testDriveGetError: error
    };
};

export const HANDLERS = {
    [Types.SET_MAX_STEP]: setMaxStep,
    [Types.SET_CURRENT_STEP]: setCurrentStep,
    [Types.FETCH_SLOTS_REQUEST]: fetchSlotsRequest,
    [Types.FETCH_SLOTS_SUCCESS]: fetchSlotsSuccess,
    [Types.FETCH_SLOTS_FAILURE]: fetchSlotsError,
    [Types.SET_SELECTED_SLOT]: setSelectedSlot,
    [Types.GET_ORDER_DETAIL_SUCCESS]: setOrderData,
    [Types.GET_ORDER_DETAIL_FAILURE]: getOrderError,
    [Types.BOOK_SLOT_SUCCESS]: setOrderData,
    [Types.CANCEL_SLOT_INIT]: cancelSlotInit,
    [Types.CANCEL_SLOT_SUCCESS]: cancelSlotSuccess,
    [Types.CANCEL_SLOT_FAILURE]: cancelSlotFailure,
    [Types.RESET_CANCEL_RESCHEDULE_POPUP_STATE]: resetCancelReschedulePopupState,
    [Types.SET_TEST_DRIVE_VARIANT_SUCCESS]: setTestDriveVariantSuccess,
    [Types.SET_TEST_DRIVE_VARIANT_FAILURE]: setTestDriveVariantFailure,
    [Types.GET_TEST_DRIVE_VARIANT_INIT]: getTestDriveVariantInit,
    [Types.GET_TEST_DRIVE_VARIANT_SUCCESS]: getTestDriveVariantSuccess,
    [Types.GET_TEST_DRIVE_VARIANT_FAILURE]: getTestDriveVariantFailure,
    [Types.UPDATE_SLOT_RESCHEDULE]: slotReschedule
};

export default createReducer(INITIAL_STATE, HANDLERS);
