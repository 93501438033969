/* eslint-disable complexity */
/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import { DEFAULT_AU_PINCODE } from "../../../constants/app-constants";
import getFormattedDeliveryInfo from "../../../utils/helpers/format-delivery-date";
import Types from "./types";

export const INITIAL_STATE = {
    deliveryData: {},
    deliveryInfoError: null,
    deliveryInfoFetched: false,
    appliedDeliveryPincode: DEFAULT_AU_PINCODE,
    deliveryDate: null,
    pickUpStateData: [],
    pickUpStateLoading: false,
    pickUpStateError: null,
    shippingPrice: 0,
    pickUpDate: null
};

export const getDeliveryInfoSuccess = (state = INITIAL_STATE, { data, pincode, shippingPrice = 0}) => {
    const {availabilityDetail, similarVehicleResult} = data || {};
    const { rangeDateForDisplay, deliveryDateRange} = getFormattedDeliveryInfo(availabilityDetail);

    const { homeDelivery = [], selfPickUp = [] } = availabilityDetail || {};

    const {shipping: homeShipping = []} = homeDelivery.length > 0 ? homeDelivery[0] : [];
    const {shipping: pickupShipping = []} = selfPickUp.length > 0 ? selfPickUp[0] : [];

    const isHomeDeliveryInterstateFeesApplicable = homeShipping[0]?.interstateFees || false;
    const isPickupInterstateFeesApplicable = pickupShipping[0]?.interstateFees || false;
    const pickupCharges = pickupShipping[0]?.shippingPrice || 0;

    return {
        ...state,
        deliveryData: availabilityDetail,
        appliedDeliveryPincode: pincode,
        deliveryDate: (availabilityDetail.homeDelivery && availabilityDetail.homeDelivery[0] && availabilityDetail.homeDelivery[0].availability) || null,
        shippingPrice,
        pickUpDate: availabilityDetail.selfPickUp && availabilityDetail.selfPickUp[0] && availabilityDetail.selfPickUp[0].availabilityDate || null,
        deliveryInfoFetched: true,
        deliveryInfoError: null,
        rangeDateForDisplay,
        deliveryDateRange,
        similarVehicleResult,
        isHomeDeliveryInterstateFeesApplicable,
        isPickupInterstateFeesApplicable,
        pickupCharges

    };
};

export const getDeliveryInfoFailure = (state = INITIAL_STATE, { error, pincode }) => {
    return {
        ...state,
        deliveryData: {},
        deliveryInfoError: error,
        deliveryInfoFetched: true,
        appliedDeliveryPincode: pincode,
        deliveryDate: null
    };
};

export const setDeliveryInfoFetch = (state, {deliveryInfoFetched}) => {
    return {
        ...state,
        deliveryInfoFetched
    };
};

export const getVehiclePickUpCitiesInit = (state, {pickUpStateLoading}) => {
    return {
        ...state,
        pickUpStateLoading
    };
};
export const getVehiclePickUpCitiesSuccess = (state, {data}) => {
    return {
        ...state,
        pickUpStateData: data
    };
};
export const getVehiclePickUpCitiesFailure = (state, {data}) => {
    return {
        ...state,
        pickUpStateError: data
    };
};
export const resetShippingPrice = (state, {data = {}}) => {
    return {
        ...state,
        shippingPrice: data.price
    };
};

export const HANDLERS = {
    [Types.GET_DELIVERY_INFO_SUCCESS]: getDeliveryInfoSuccess,
    [Types.GET_DELIVERY_INFO_FAILURE]: getDeliveryInfoFailure,
    [Types.DELIVERY_INFO_FETCHING]: setDeliveryInfoFetch,
    [Types.GET_PICKUP_CITIES_INIT]: getVehiclePickUpCitiesInit,
    [Types.GET_PICKUP_CITIES_SUCCESS]: getVehiclePickUpCitiesSuccess,
    [Types.GET_PICKUP_CITIES_FAILURE]: getVehiclePickUpCitiesFailure,
    [Types.RESET_SHIPPING_PRICE]: resetShippingPrice
};

export default createReducer(INITIAL_STATE, HANDLERS);
