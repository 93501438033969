export const AMPLITUDE_EVENT_NAMES = {
    "HAMBURGER_BOTTOM_MENU_SELL_CLICKED": "hamburger bottom menu sell clicked"
};
export const SIDE_MENU_SELL_GA_EVENT_NAMES = {
    cta_clicked: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "clicked",
        eventLabel: "top_menu_sell"
    }
};
