import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    transition: true,
    nudgeText: "Need Help?",
    pathname: "",
    showHelpSupportModal: false,
    hideWidgetIcon: false,
    showAnimation: false,
    nudgeDetails: {
        heading: "",
        bodyText: "",
        imagePath: "",
        category: ""
    },
    nudgeNotificationCount: 0,
    showNudge: false,
    categoryNudgeCounts: {
        buyer: 0,
        finance: 0,
        generic: 0,
        sell: 0
    },
    launchNudge: false,
    isModalContentSeen: false,
    nudgeTimerId: null,
    accordionState: {
        buyer: true,
        finance: false,
        sell: false
    },
    nudgeCountSynced: false,
    firedNudgeIds: []
};

export const setNudgeDetails = (state = INITIAL_STATE, { details = {} }) => {
    return {
        ...state,
        ...details
    };
};

export const showHelpSupportModal = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        showHelpSupportModal: data
    };
};

export const updateSupportWidgetConfig = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        ...data,
        launchNudge: !(state.categoryNudgeCounts?.buyer && state.categoryNudgeCounts?.finance && state.categoryNudgeCounts?.generic) || !(data.categoryNudgeCounts?.buyer && data.categoryNudgeCounts?.finance && data.categoryNudgeCounts?.generic)
    };
};

export const setModalContentSeen = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isModalContentSeen: data
    };
};

export const updateNudgeCounts = (state = INITIAL_STATE, { category = {} }) => {
    const updatedCategoryNudgeCounts = {
        ...state.categoryNudgeCounts,
        [category]: state.categoryNudgeCounts[category] + 1
    };
    return {
        ...state,
        categoryNudgeCounts: updatedCategoryNudgeCounts
    };
};

export const updateNotificationCounts = (state = INITIAL_STATE) => {
    return {
        ...state,
        nudgeNotificationCount: state.nudgeNotificationCount + 1
    };
};

export const setNudgeTimerId = (state = INITIAL_STATE, { timerId = {} }) => {
    return {
        ...state,
        nudgeTimerId: timerId
    };
};

export const setAccordionState = (state = INITIAL_STATE, { category = {}, state: accordionState = {} }) => {
    const updatedAccordionState = Object.keys(state.accordionState).reduce((acc, key) => {
        acc[key] = key === category ? accordionState : false;
        return acc;
    }, {});

    return {
        ...state,
        accordionState: updatedAccordionState
    };
};

export const updateNudgeCountSyncStatus = (state = INITIAL_STATE, { status = {} }) => {
    return {
        ...state,
        nudgeCountSynced: status
    };
};

export const HANDLERS = {
    [Types.SET_NUDGE_DETAILS]: setNudgeDetails,
    [Types.SHOW_HELP_SUPPORT_MODAL]: showHelpSupportModal,
    [Types.UPDATE_SUPPORT_WIDGET_CONFIG]: updateSupportWidgetConfig,
    [Types.SET_MODAL_CONTENT_SEEN]: setModalContentSeen,
    [Types.UPDATE_NUDGE_COUNTS]: updateNudgeCounts,
    [Types.UPDATE_NOTIFICATION_COUNTS]: updateNotificationCounts,
    [Types.SET_NUDGE_TIMER_ID]: setNudgeTimerId,
    [Types.SET_ACCORDION_STATE]: setAccordionState,
    [Types.UPDATE_NUDGE_COUNT_SYNC_STATUS]: updateNudgeCountSyncStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
