import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_CAR_LOAN_CALCULATOR_META_DATA
    SET_CAR_LOAN_CALCULATOR_LOADING
    SET_CAR_LOAN_CALCULATOR_ERROR
    SET_CAR_LOAN_INTEREST_RATES
    SET_EMAIL_ESTIMATE_FLOW
    SET_EMAIL_ESTIMATE_SENT_SUCCESS
    SET_ESTIMATE_TRIGGER_ENDPOINT
    `,
    {
        prefix: "car-loan-calculator-clp/"
    }
);
