export default {
    HOME: ["/", "/finance-your-car"],
    CarDetail: "/buy-used-:carMakeModel-car-:lid(\\d+)",
    CarDetail_gallery: "/buy-used-:carMakeModel-car-:lid(\\d+)/gallery",
    checkout: "/buy-used-:carMakeModel-car-:lid(\\d+)/checkout/:stepid",
    checkout_testdrive: "/buy-used-:carMakeModel-car-:lid(\\d+)/test-drive/checkout/:stepid",
    buy_used_listing: "/buy-used-:filterQuery",
    used_cars_listing: "/used-cars-in-:filterQuery",
    au_used_cars: "/australia-used-cars",
    warranty: "/warranty",
    cars24cover: "/cars24cover",
    wishlist: "/wishlist",
    my_booking: "/my-bookings",
    sell_orders: "/my-sell-orders",
    booking_tasks: "/my-bookings-:carId/:stepid",
    tradein: "/trade-in",
    booking_tasks_testdrive: "/my-bookings-:carId/test-drive/summary",
    contact_us: "/contact-us",
    cars24_finance: "/cars24-finance",
    unsubscribe: "/unsubscribe",
    three_hundred_inspection: "/300-point-inspection",
    cars24way: "/thecars24way",
    terms_of_use: "/terms-of-use",
    terms_of_purchase: "/terms-of-purchase",
    terms_of_sale: "/terms-of-sale",
    cancellation_request: "/Cancellation-request",
    privacy_policy: "/privacy-policy",
    about_us: "/about-us",
    career: "/career",
    black_friday: "/black-friday",
    apple_callback: "/oauth/apple/callback",
    sitemap: "/sitemap",
    external: "/external",
    sell_your_car: "/sell-your-car",
    sell_your_car_tradein: "/sell-your-car/:stepid",
    financing: "/financing",
    financing_steps: "/financing/:stepid",
    my_account: "/my-account",
    my_account_steps: "/my-account/:stepId",
    licence_verification: "/licence-verification",
    get_pre_approval: "/financing/get-pre-approval",
    roadworthy_certificate_default: "/roadworthy-certificate-melbourne",
    NOT_FOUND: "*"
};
