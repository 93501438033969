import React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarAlreadyReservedModal from "./component";
import { verifyReservedStatus } from "../checkout-info/actions";

const mapStateToProps = ({
    checkout: {
        carReservedModal,
        order: {
            orderId
        }
    }
}) => ({
    carReservedModal,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    verifyReservedStatusConnect: verifyReservedStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarAlreadyReservedModal));
