/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Pill from "../checkout-pill";
import styles from "./styles.css";
import { withRouter } from "react-router-dom";
import { NUMBER } from "../../../constants/app-constants.js";
import { checkoutRoutes} from "../../../constants/checkout-routes";
import { getAbExpirementVariant, showPostWarrantyVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import withEditCheckout from "../../au.mobile/checkout-edit-flow-hoc";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import FinancePills from "../finance-pills/component";
import Tick from "./images/tick.svg";
import { cloneDeep, unset, update }  from "lodash";
import { BOOL_STATE } from "../../../constants/checkout-constants";
import useShowTradeInCheckoutStep from "../../../hooks/use-show-trade-in-checkout-step.js";
import { getIsNewServicingVariant } from "../../../utils/statsig-ab-utils/get-experiment-data.js";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants.js";

const PillNavigation = ({
    match: {
        params: { stepid: currentRoute }
    },
    checkoutEditFormData,
    financeData,
    history,
    content,
    checkoutEditMode = false,
    checkoutEditCompletedStages = [],
    maxStep,
    disablePill = {},
    onCustomPillClick = () => {},
    eligibleForExtendedWarranty,
    showWarrantyPageShift,
    warrantyPlanInfo = {},
    servicingConfig,
    msiteBi2ph
}) => {
    const { loanType } = checkoutEditMode ? checkoutEditFormData || {} : financeData || {};
    const { isPurposeBusiness } = loanType || {};
    const { experiment: servicingExperiment } = servicingConfig || {};
    const newServicingExperiment = getIsNewServicingVariant(servicingExperiment);
    let checkoutRoutesWithoutEmployment = cloneDeep(checkoutRoutes);
    if (isPurposeBusiness === BOOL_STATE.YES) {
        unset(checkoutRoutesWithoutEmployment, "employmentDetails");
        update(checkoutRoutesWithoutEmployment, "loanDetails.defaultNext", () => "personal-details");
        update(checkoutRoutesWithoutEmployment, "personalDetails.defaultBack", () => "loan-details");
    } else {
        checkoutRoutesWithoutEmployment = cloneDeep(checkoutRoutes);
    }
    const {showTradeInCheckoutStep} = useShowTradeInCheckoutStep();
    const { extendedWarranty = {} } = warrantyPlanInfo || {};
    const plans = extendedWarranty && extendedWarranty.plans || [];
    const isBi2phVariant = getAbExpirementVariant(msiteBi2ph, EXPERIMENT_TYPE.VARIANT_B);

    //pills data
    const updateCheckoutRoutesArray = Object.values(checkoutRoutesWithoutEmployment);
    updateCheckoutRoutesArray.forEach((route) => {
        if (!showTradeInCheckoutStep && route.route === checkoutRoutes.tradeInIntro.route) {
            route.hideCurrentPill = true;
        }
    });
    // console.log("showType",  currentRoute, updateCheckoutRoutesArray); //

    const isTradeInOpted = financeData?.optedForTradeIn === BOOL_STATE.YES;
    if (!isTradeInOpted) {
        updateCheckoutRoutesArray.forEach((route) => {
            if (route.route === "finance-first-trade-in-intro") {
                route.hideCurrentPill = true;
            }
        });
    }

    const showType = updateCheckoutRoutesArray.find((pillData) => pillData.route === currentRoute).type;

    if (isBi2phVariant) {
        updateCheckoutRoutesArray.forEach((route) => {
            if (route.route === "personal-details" || route.route === "basic-details") {
                route.hideCurrentPill = true;
            }
        });
    }

    const scrollingContainer = useRef();
    const activePill = useRef();

    useEffect(() => {
        const activePillOffsetLeft = activePill.current ? activePill.current.offsetLeft : 0;
        const offset = activePillOffsetLeft ? activePillOffsetLeft - NUMBER.FOURTY : 0;
        scrollingContainer.current.scrollTo({ left: offset, top: 0 });
    }, []);

    const showCarCoverPill = () => {
        const showWarrantyPill =  eligibleForExtendedWarranty && (plans || []).length > 0 && showPostWarrantyVariant(showWarrantyPageShift);
        if (newServicingExperiment) {
            return true;
        }
        return showWarrantyPill;
    };

    const filteredPills = updateCheckoutRoutesArray.filter((data) => data.type === showType && !data.hideCurrentPill && (checkoutRoutes.carCover.key === data.key ? showCarCoverPill() : true));

    const maxStepIndex =
        filteredPills
            .findIndex((pillData) => pillData.route === maxStep) || 0;

    const currentIndex =
        filteredPills
            .findIndex((pillData) => pillData.route === currentRoute) || 0;

    return (
        <React.Fragment>
            <div styleName="styles.scrollingContainer" ref={scrollingContainer}>
                <div styleName="styles.pillsContainer">
                    {filteredPills
                        .map((data, index) => {
                            const active = disablePill && disablePill[data.key] ? false : index <= maxStepIndex;
                            const current = currentRoute === data.route || (currentRoute.includes(data.useRoute) && data.route.includes(data.useRoute));
                            const greenTick = active && (index < maxStepIndex);
                            // console.log({greenTick, active, index, maxStepIndex}, data.name);
                            if (checkoutEditMode) {
                                return (
                                    <PillEditMode
                                        content={content}
                                        checkoutEditCompletedStages={checkoutEditCompletedStages}
                                        key={index}
                                        history={history}
                                        pillData={data}
                                        index={index + 1}
                                        active={active}
                                        showGreenTick={greenTick}
                                        current={currentRoute === data.route}
                                        activeRef={activePill}
                                        showNumber={data.showPillNumber}
                                        editMode={checkoutEditMode}
                                        onCustomPillClick={onCustomPillClick} />
                                );
                            }

                            return (
                                <Pill
                                    key={index}
                                    pillData={data}
                                    // active={index <= maxStepIndex}
                                    active={active}
                                    current={current}
                                    onCustomPillClick={onCustomPillClick}
                                    activeRef={activePill}
                                    showGreenTick={greenTick}
                                    index={index + 1}
                                    showNumber={data.showPillNumber}
                                    backActive={active && index > currentIndex}
                                />
                            );
                        })}
                </div>
            </div>
        </React.Fragment>
    );
};

const PillEditMode = withEditCheckout((props) => {
    const { index, content, current, history = {}, pillData = {}, onEditStageComplete = () => {}, onCustomPillClick = () => {}, checkoutEditCompletedStages = [] } = props || {};
    const { name, route } = pillData || {};
    const pageStepNo = index;

    const onClick = (e) => {
        const { relativeURL } = getCheckoutPageURL(content, route);
        onCustomPillClick(e, pillData);
        history.push(relativeURL);
    };

    const onCompletedPillClick = (e) => {
        const updatedPills = checkoutEditCompletedStages.slice(0, checkoutEditCompletedStages.indexOf(pageStepNo));
        onEditStageComplete(updatedPills);
        onClick(e);
    };

    if (current) {
        return (
            <FinancePills color="blue" text={name} image={pageStepNo} onClick={onClick} />
        );
    } else if (checkoutEditCompletedStages.indexOf(pageStepNo) !== -1) { /* step is already complete */
        return (
            <FinancePills color="green" text={name} image={Tick} isImage={true} onClick={onCompletedPillClick}  />
        );
    } else {
        return (
            <FinancePills text={name} image={pageStepNo} />
        );
    }
});

PillNavigation.propTypes = {
    checkoutEditFormData: PropTypes.object,
    financeData: PropTypes.object,
    history: PropTypes.object,
    content: PropTypes.object,
    match: PropTypes.object,
    maxStep: PropTypes.string,
    checkoutEditMode: PropTypes.bool,
    completedStages: PropTypes.array,
    checkoutEditCompletedStages: PropTypes.array,
    onCustomPillClick: PropTypes.func,
    disablePill: PropTypes.object,
    eligibleForExtendedWarranty: PropTypes.bool,
    showWarrantyPageShift: PropTypes.object,
    warrantyPlanInfo: PropTypes.object,
    servicingConfig: PropTypes.object,
    msiteBi2ph: PropTypes.object
};

export default withRouter(PillNavigation);
