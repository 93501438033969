/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import CheckoutPillNavigation from "../checkout-pill-navigation";
import arrow from "./images/arrow.svg";
import call from "./images/call.svg";
import { HELPLINE_NUMBER_AU, NUMBER } from "../../../constants/app-constants";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { getCarImage } from "../../../utils/helpers/split-payment";
import CheckoutTopNav from "../checkout-top-nav";
import { imageTypes } from "../../../utils/helpers/cdn-image-url";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import backImage from "./images/back.svg";
import CallIcon from "./images/call-back-icon.png";
import animationData from "./images/call-back.json";
import callBackRecievedIcon from "./images/check.svg";
import withCallbackRequest from "../with-callback-request";
import usePricing from "../../../hooks/use-pricing";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});

const defaultLottieOptions = {
    loop: true,
    animationData
};

const CallBackIcon = withCallbackRequest(({hideCallbackHeader, handleCallbackRequest, showCallIcon, callbackRequest}) => {
    return (
        <React.Fragment>
            {!hideCallbackHeader && <div styleName={"styles.bottomLeft"} onClick={handleCallbackRequest}>
                {callbackRequest ?
                    <p styleName={"styles.callbackRequested"}><img src={callBackRecievedIcon} />Callback requested</p>
                    :
                    <React.Fragment>{showCallIcon ? <Lottie options={defaultLottieOptions} height={24} width={24} speed={1} style={{marginRight: "2px"}}/> : <img src={CallIcon} /> }
                        <p styleName={"styles.getaCallBack"} >Get a callback</p></React.Fragment> }

            </div>}

        </React.Fragment>
    );
});

CallBackIcon.propTypes = {
    hideCallbackHeader: PropTypes.bool,
    handleCallbackRequest: PropTypes.func,
    callbackRequest: PropTypes.bool,
    showCallIcon: PropTypes.bool,
    showRequestAlreadyReceivedModal: PropTypes.bool,
    mobile: PropTypes.string,
    showRequestAlreadyReceivedModalText: PropTypes.bool
};

// eslint-disable-next-line max-statements
const CheckoutHeader = ({
    onBackClick,
    onCallClick,
    showExtraInfo = true,
    content,
    isAffiliate,
    deliveryInfo,
    showEgcPrice = false,
    showPill = true,
    hideBack = false,
    showBackWithInfo = false,
    // currentDeliveryStep,
    currentStep = {},
    uiType = "new",
    config,
    isDrivaScreen = false,
    iconType = "",
    onCustomPillClick = () => {},
    heading = "Apply for finance",
    onClickLeftIcon,
    eligibleForExtendedWarranty,
    deliveryMode: selectedDeliveryMode,
    completedPercentage,
    origin = "",
    financeFirst,
    backCustomText,
    mobile,
    hideCallbackHeader,
    isTDflow,
    callbackRequest
}) => {
    const history = useHistory();
    let disabledPill = {};
    const [showCallIcon, setShowCallIcon] = useState(false);
    const { year, make, model } = content;
    const { deliveryData = {}} = deliveryInfo;

    const { dapOrTradePrice, egc, headerTotalDap, isTradeinHigh } = usePricing();

    // const { motorVehicleDuty = 0, transferFee = 0} = egcData[0] || {};
    // const isCarAvailable = motorVehicleDuty + transferFee === 0;

    // const deliveryOrPickupAvailable = deliveryMode !== DELIVERY_TYPES.PickUp ? !deliveryDate : !pickUpDate;
    const showEgcLabel = (!selectedDeliveryMode || showEgcPrice);
    const priceObj = showEgcLabel
        ? {value: egc.value, label: "Excl. Govt. Charges" } : {value: headerTotalDap.value, label: "Drive-away price"};
    // const priceObj = (((!deliveryDate || deliveryInfoError) || showEgcPrice) && isCarAvailable)
    //     ? {value: egc.label, label: "Excl. Govt. Charges" } : {value: totalDap.label, label: "Drive-away price"};

    const {isFromOverView} = history.location && history.location.state || {};
    const imageType = imageTypes.auCarHeaderIcon;
    const { imageUrl } = getCarImage({config, content, imageType});

    //disable Paymentoptions
    if ((isTradeinHigh || (!isTradeinHigh && dapOrTradePrice < NUMBER.FIFTEEN_HUNDRED))) {
        disabledPill = {...disabledPill, [checkoutRoutes.paymentOptions.key]: true };
    }

    //disable cars24 Cover
    disabledPill = {...disabledPill,
        [checkoutRoutes.carCover.key]: !eligibleForExtendedWarranty,
        [checkoutRoutes.tradeInIntro.key]: !deliveryData.tradeInActive

    };

    useEffect(() => {
        window.setTimeout(() => {
            setShowCallIcon(true);
        }, NUMBER.FIVE_THOUSAND);
        return () => {
            setShowCallIcon(false);
        };
    }, []);

    const legacyUI = () => {
        return (<React.Fragment>
            { hideBack ? null :
                (
                    <div styleName="styles.header">
                        <div styleName="styles.backButton" onClick={onBackClick}>
                            <img src={arrow} />
                            <span>Order Summary</span>
                        </div>
                        <a href={`tel:${HELPLINE_NUMBER_AU.value}`} onClick={onCallClick}>
                            <img src={call} />
                        </a>
                    </div>
                )
            }
            {showExtraInfo ? (
                <React.Fragment>
                    <div styleName="styles.carInfo">
                        {showBackWithInfo && !isAffiliate &&
                        <div onClick={onBackClick}>
                            <img src={arrow} />
                        </div>
                        }
                        <span>{`${year || ""} ${make || ""} ${model || ""}`}</span>
                        <span>
                            <span> {makePriceLabelRound(Math.abs(priceObj.value))} </span>
                            <span>{isTradeinHigh ? "Payable to you" : priceObj.label}</span>
                        </span>
                    </div>
                    {showPill && <CheckoutPillNavigation onCustomPillClick={onCustomPillClick}  disabledPill={disabledPill} /> }
                </React.Fragment>
            ) : (
                null
            )}
        </React.Fragment>
        );
    };

    const currentUI = () => {
        return (
            <React.Fragment>
                {isDrivaScreen ?
                    <CheckoutTopNav
                        heading={heading}
                        imageCta={makePriceLabelRound(priceObj.value)}
                        imageSrc={imageUrl}
                        onClickLeftIcon={onClickLeftIcon}
                        isDrivaScreen={isDrivaScreen}
                        type={iconType}
                        onCustomPillClick={onCustomPillClick}
                        completedPercentage={completedPercentage}
                    /> :

                    <div styleName="styles.carInfo">
                        <div styleName="styles.header">
                            <div styleName="styles.backButton">
                                { (!isAffiliate || (isAffiliate && currentStep.key !== "basicDetails")) &&
                                <img src={arrow} onClick={isFromOverView ? history.goBack :  onBackClick}/> }
                                <span>
                                    {origin === "variantB" ? "Order summary" : `${year || ""} ${make || ""} ${model || ""}`}
                                </span>
                            </div>
                            {/* <p styleName={"styles.carPrice"}>
                                <span>
                                    <span>{makePriceLabelRound(Math.abs(priceObj.value))}</span>
                                    <span>{isTradeinHigh ? "Payable to you" : priceObj.label}</span>
                                </span>
                            </p> */}
                        </div>
                    </div>
                }

                {/* {showPercentageBar && !postOrderBookingConfirmed(orderStatus)  && <CheckoutProgressBar percentage={checkoutStepsData.successPercentage}/>} */}
                {showPill && <CheckoutPillNavigation onCustomPillClick={onCustomPillClick} disablePill={disabledPill} /> }
            </React.Fragment>
        );
    };
    if (financeFirst || isTDflow) {
        return (<React.Fragment>
            <div styleName={"styles.headerWrapper"}>
                <div styleName={"styles.headerLeft"} onClick={onBackClick}>
                    <img src={backImage} alt="Add on page back image" />
                    <p styleName={"styles.heading"} >{backCustomText || "Back"}</p>
                </div>
                <CallBackIcon
                    hideCallbackHeader={!backCustomText || hideCallbackHeader}
                    callbackRequest={callbackRequest}
                    showCallIcon={showCallIcon}
                    mobile={mobile}
                />
            </div>
            {showPill && <div styleName={"styles.pill-wrapper"}><CheckoutPillNavigation onCustomPillClick={onCustomPillClick} disablePill={disabledPill} /></div> }
        </React.Fragment>
        );
    }

    return uiType === "legacy" ? legacyUI() : currentUI();
};

CheckoutHeader.propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onCallClick: PropTypes.func,
    onCustomPillClick: PropTypes.func,
    showExtraInfo: PropTypes.bool,
    content: PropTypes.object,
    egcData: PropTypes.object,
    showEgcPrice: PropTypes.bool,
    deliveryInfo: PropTypes.object,
    showPill: PropTypes.bool,
    hideBack: PropTypes.bool,
    isDrivaScreen: PropTypes.bool,
    showPercentageBar: PropTypes.bool,
    iconType: PropTypes.string,
    heading: PropTypes.string,
    showBackWithInfo: PropTypes.bool,
    currentStep: PropTypes.object,
    order: PropTypes.object,
    config: PropTypes.object,
    currentDeliveryStep: PropTypes.number,
    uiType: PropTypes.string,
    checkoutStepsData: PropTypes.object,
    splitAmountDetails: PropTypes.object,
    isUserZeroDpVariant: PropTypes.bool,
    eligibleForExtendedWarranty: PropTypes.bool,
    financeFirst: PropTypes.bool,
    backCustomText: PropTypes.string,
    checkoutCallbackRequestConnect: PropTypes.func,
    checkoutCallbackRequestExpired: PropTypes.bool,
    mobile: PropTypes.string,
    hideCallbackHeader: PropTypes.bool,
    isAffiliate: PropTypes.bool,
    tradeInData: PropTypes.object,
    onClickLeftIcon: PropTypes.func,
    deliveryMode: PropTypes.string,
    completedPercentage: PropTypes.number,
    promoCode: PropTypes.object,
    origin: PropTypes.string,
    isTDflow: PropTypes.bool,
    callbackRequest: PropTypes.bool
};

export default withRouter(CheckoutHeader);
