import React from "react";
import { connect } from "react-redux";
import { reloadCarList } from "../car-listing/actions";
import { bindActionCreators } from "redux";
import MobileRoot from "./component";

const mapStateToProps = ({
    user: {
        isLoginPopupVisible
    },
    carListing: {
        isSSR
    }
}) => ({
    isLoginPopupVisible,
    isSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileRoot);
