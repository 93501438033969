/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    loanApplication: {},
    isGetLoanApplicationError: false,
    isGetLoanApplicationLoading: true,
    isPatchLoanApplicationError: false,
    isPatchLoanApplicationLoading: false,
    isPostLoanApplicationError: false,
    isPostLoanApplicationLoading: false,
    applicationFormConfigData: {},
    documentUploadConfigData: {},
    isPreSignedUploadedImageDataLoading: false,
    isPreSignedUploadedImageDataError: false,
    preSignedUploadedImageData: {},
    timeoutDetails: {},
    timeoutDetailsLoading: false,
    timeoutDetailsError: null,
    metadata: {
        loading: false,
        error: null,
        data: {}
    }
};

export const getFinancePostPaymentApplicationInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isGetLoanApplicationLoading: isLoading,
        isGetLoanApplicationError: false
    };
};

export const getFinancePostPaymentApplicationSuccess = (state = INITIAL_STATE, { data = {}, configData = {} }) => {
    return {
        ...state,
        loanApplication: {...state.loanApplication, ...data},
        timeoutDetails: data.timeoutDetails,
        ...configData,
        isGetLoanApplicationLoading: false,
        isGetLoanApplicationError: false
    };
};

export const getFinancePostPaymentApplicationFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isGetLoanApplicationLoading: false,
        isGetLoanApplicationError: error
    };
};

export const patchFinancePostPaymentApplicationInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isPatchLoanApplicationLoading: isLoading,
        isPatchLoanApplicationError: false
    };
};

export const patchFinancePostPaymentApplicationSuccess = (state = INITIAL_STATE, { data = {}, configData = {} }) => {
    return {
        ...state,
        loanApplication: {...state.loanApplication, ...data},
        timeoutDetails: data.timeoutDetails,
        ...configData,
        isPatchLoanApplicationLoading: false,
        isPatchLoanApplicationError: false
    };
};

export const patchFinancePostPaymentApplicationFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPatchLoanApplicationLoading: false,
        isPatchLoanApplicationError: error
    };
};

export const postFinancePostPaymentApplicationInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isPostLoanApplicationLoading: isLoading,
        isPostLoanApplicationError: false
    };
};

export const postFinancePostPaymentApplicationSuccess = (state = INITIAL_STATE, { data = {}, configData = {} }) => {
    return {
        ...state,
        loanApplication: {...state.loanApplication, ...data},
        timeoutDetails: data.timeoutDetails,
        ...configData,
        isPostLoanApplicationLoading: false,
        isPostLoanApplicationError: false
    };
};

export const postFinancePostPaymentApplicationFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPostLoanApplicationLoading: false,
        isPostLoanApplicationError: error
    };
};

export const getPreSignedFinanceUploadedImageInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isPreSignedUploadedImageDataLoading: isLoading,
        isPreSignedUploadedImageDataError: false
    };
};

export const getPreSignedFinanceUploadedImageSuccess = (state = INITIAL_STATE, { data = {}, filePath }) => {
    return {
        ...state,
        preSignedUploadedImageData: {
            ...state.preSignedUploadedImageData,
            [filePath]: data
        },
        isPreSignedUploadedImageDataLoading: false,
        isPreSignedUploadedImageDataError: false
    };
};

export const getPreSignedFinanceUploadedImageFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPreSignedUploadedImageDataLoading: false,
        isPreSignedUploadedImageDataError: error
    };
};

export const updateZeroDpExpiryTimeInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        timeoutDetailsLoading: isLoading,
        timeoutDetailsError: false
    };
};

export const updateZeroDpExpiryTimeSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        ...data,
        timeoutDetailsLoading: false,
        timeoutDetailsError: false
    };
};

export const updateZeroDpExpiryTimeFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        timeoutDetailsLoading: false,
        timeoutDetailsError: error
    };
};

export const autoUpdateZeroDpExpiryTimeInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        timeoutDetailsLoading: isLoading,
        timeoutDetailsError: false
    };
};

export const autoUpdateZeroDpExpiryTimeSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        ...data,
        timeoutDetailsLoading: false,
        timeoutDetailsError: false
    };
};

export const autoUpdateZeroDpExpiryTimeFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        timeoutDetailsLoading: false,
        timeoutDetailsError: error
    };
};

const getStageTwoMetadataRequest = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        metadata: {
            loading: isLoading,
            error: null
        }
    };
};

const getStageTwoMetadataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        metadata: {
            loading: false,
            error: null,
            data
        }
    };
};

const getStageTwoMetadataFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        metadata: {
            loading: false,
            error
        }
    };
};

export const HANDLERS = {
    [Types.GET_FINANCE_POST_PAYMENT_APPLICATION_INIT]: getFinancePostPaymentApplicationInit,
    [Types.GET_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS]: getFinancePostPaymentApplicationSuccess,
    [Types.GET_FINANCE_POST_PAYMENT_APPLICATION_FAILURE]: getFinancePostPaymentApplicationFailure,

    [Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_INIT]: patchFinancePostPaymentApplicationInit,
    [Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS]: patchFinancePostPaymentApplicationSuccess,
    [Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_FAILURE]: patchFinancePostPaymentApplicationFailure,

    [Types.POST_FINANCE_POST_PAYMENT_APPLICATION_INIT]: postFinancePostPaymentApplicationInit,
    [Types.POST_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS]: postFinancePostPaymentApplicationSuccess,
    [Types.POST_FINANCE_POST_PAYMENT_APPLICATION_FAILURE]: postFinancePostPaymentApplicationFailure,

    [Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_INIT]: getPreSignedFinanceUploadedImageInit,
    [Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_SUCCESS]: getPreSignedFinanceUploadedImageSuccess,
    [Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_FAILURE]: getPreSignedFinanceUploadedImageFailure,

    [Types.UPDATE_ZERO_EXPIRY_TIME_INIT]: updateZeroDpExpiryTimeInit,
    [Types.UPDATE_ZERO_EXPIRY_TIME_SUCCESS]: updateZeroDpExpiryTimeSuccess,
    [Types.UPDATE_ZERO_EXPIRY_TIME_FAILURE]: updateZeroDpExpiryTimeFailure,

    [Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_INIT]: autoUpdateZeroDpExpiryTimeInit,
    [Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_SUCCESS]: autoUpdateZeroDpExpiryTimeSuccess,
    [Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_FAILURE]: autoUpdateZeroDpExpiryTimeFailure,

    [Types.GET_STAGE_TWO_METADATA_REQUEST]: getStageTwoMetadataRequest,
    [Types.GET_STAGE_TWO_METADATA_SUCCESS]: getStageTwoMetadataSuccess,
    [Types.GET_STAGE_TWO_METADATA_FAILURE]: getStageTwoMetadataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
