import React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimilarCarPage from "./component";
import { fetchCarList, resetCarList, reloadCarList } from "../car-listing/actions";
import { fetchFilters, updateFilterOptionData } from "../filters/actions";
import { clearSimilarCarList, fetchSimilarCars } from "../car-details/actions";

const mapStateToProps = ({
    user: {
        isUserZeroDpVariant
    },
    carListing: {
        content: listContent,
        totalCars,
        page,
        isLoading,
        isSSR,
        shouldReloadList
    },
    carDetails: {
        content: carContent,
        similarCars,
        isSimilarCarsFetching,
        totalSimilarVehicles,
        page: similarVehiclePage,
        config
    },
    filters: {
        allFilters
    }
}) => ({
    listContent,
    totalCars,
    page,
    isLoading,
    config,
    isSSR,
    carContent,
    allFilters,
    shouldReloadList,
    similarCars,
    isSimilarCarsFetching,
    totalSimilarVehicles,
    similarVehiclePage,
    isUserZeroDpVariant
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarListConnect: fetchCarList,
    resetCarListConnect: resetCarList,
    reloadCarListConnect: reloadCarList,
    fetchFiltersConnect: fetchFilters,
    fetchSimilarCarsConnect: fetchSimilarCars,
    clearSimilarCarListConnect: clearSimilarCarList,
    updateFilterOptionDataConnect: updateFilterOptionData
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimilarCarPage));

