/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable new-cap */
import styles from "./styles.css";
// import "@adyen/adyen-web/dist/adyen.css";
import React, {useState} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import CheckoutHeader from "../checkout-header";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { makeOdometerLabelAU } from "../../../utils/helpers/make-odometer-label";
import { getLogoURL } from "../../../constants/url-constants";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { trackMobileCustomEventsAU } from "../../../tracking";
import AdyenComponent from "./adyen-component";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { useEffect } from "react";
import isWebView from "../../../utils/helpers/is-web-view";
import PageLoader from "../../shared/page-loader";
import { paymentRedirectUrls } from "../../../utils/helpers/payment-redirect";

// eslint-disable-next-line max-statements
const AdyenPay = ({
    paymentConfig,
    content,
    imageConfig,
    currentDeliveryStep = {},
    routeData,
    isDrivaScreen = false,
    showPill = false,
    showPercentageBar = false,
    showNavigation = false,
    showHeader = true,
    getOrderDetailConnect,
    fetchCarDetailsConnect,
    secureToken,
    verifyReservedStatusConnect
}) => {
    const history = useHistory();
    const { location } = history;
    const queryParams = parseLocationSearchParams(location.search);
    const { appointmentId, amount: urlAmount = 0 } = queryParams;
    const { amount = 0 } = paymentConfig || {};
    const { mainImage, year, make, model, odometerReading, transmissionType, variant } = content;
    const carFullName = `${year || ""} ${make || ""} ${model || ""}`;
    const hasMainImage = mainImage && mainImage.path && imageConfig && imageConfig.imageHost ? true : false;
    const carImageUrl = hasMainImage
        ? fastlyUrl(imageConfig.imageHost, mainImage.path, imageTypes.auCarCheckoutThumbnail)
        : null;

    const [isLoading, setIsLoading] = useState(false);
    const [redirectUrls, setRedirectUrls] = useState(paymentConfig);

    useEffect(() => {
        const getDetails = async() => {
            try {
                const {detail} = await fetchCarDetailsConnect(appointmentId);
                const order = await getOrderDetailConnect(appointmentId);
                const redirectURL = paymentRedirectUrls(detail, paymentConfig, {appointmentId, orderId: order.orderId});
                setIsLoading(false);
                setRedirectUrls(redirectURL);
            } catch (error) {
                window.console.log("error=>", error);
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        if (secureToken) {
            getDetails();
        }
        if (amount === 0 && urlAmount === 0) {
            history.goBack();
        }

    }, [amount, appointmentId, fetchCarDetailsConnect, getOrderDetailConnect, history, paymentConfig, secureToken, urlAmount]);

    const handleBackClick = () => {
        history.goBack();
    };

    const defaultCallClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_HEADER_CALL, {
            eventLabel: `options ${routeData.gtmPageName}`
        });
    };

    if (isLoading) {
        return <PageLoader/>;
    }

    return (
        <React.Fragment>
            {showHeader && !isWebView() && <CheckoutHeader
                currentDeliveryStep={currentDeliveryStep}
                currentStep={routeData}
                onBackClick={handleBackClick}
                showExtraInfo={showNavigation}
                onCallClick={defaultCallClick}
                showPill={showPill}
                isDrivaScreen={isDrivaScreen}
                showPercentageBar={showPercentageBar}
            />}
            <div styleName={"styles.wrapper"}>
                {showHeader &&
                <React.Fragment>
                    <div styleName={"styles.carDetails"}>
                        <div styleName={"styles.carImages"}>
                            <img src={carImageUrl} alt="car image" /></div>
                        <div>
                            <h3 styleName={"styles.carNames"}>{carFullName}</h3>
                            <ul styleName={"styles.carBriefs"}>
                                <li >
                                    {variant}
                                </li>
                                <li>{transmissionType}</li>
                                <li >{makeOdometerLabelAU(odometerReading)}</li>
                            </ul>
                        </div>
                    </div>
                    <img styleName={"styles.logoImage"} src={getLogoURL()} alt="cars24 logo" height={40} width={100} />
                    <div>
                        <p styleName="styles.bookingAmount">AUD {amount ? amount : urlAmount}</p>
                    </div>
                    <AdyenComponent  paymentConfig={redirectUrls} verifyReservedStatusConnect={verifyReservedStatusConnect}/>
                </React.Fragment>
                }
            </div>

        </React.Fragment>
    );
};

AdyenPay.propTypes = {
    paymentConfig: PropTypes.object,
    content: PropTypes.object,
    imageConfig: PropTypes.object,
    currentDeliveryStep: PropTypes.object,
    routeData: PropTypes.object,
    isDrivaScreen: PropTypes.bool,
    showPill: PropTypes.bool,
    showPercentageBar: PropTypes.bool,
    showNavigation: PropTypes.bool,
    order: PropTypes.object,
    showHeader: PropTypes.bool,
    getOrderDetailConnect: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func,
    secureToken: PropTypes.string,
    sessionData: PropTypes.string,
    verifyReservedStatusConnect: PropTypes.func
};

export default AdyenPay;

